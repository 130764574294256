import React, { createElement } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "../../Auth";

const PrivateRoute = (props: RouteProps) => {
    const { checkAuth } = useAuth();
    const { render, children, component, ...rest } = props;

    return <Route
        {...rest}
        render={(renderProps) => {
            if (checkAuth()) {
                if (render) {
                    return render(renderProps);
                }

                if (component) {
                    return createElement(component);
                }

                if (children) {
                    return children;
                }
            }
            else {
                return <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: renderProps.location }
                    }}
                />
            }
        }}
    />
}

export default PrivateRoute;