import { Button, Col, Form, PageHeader, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../Auth';
import {
  useUsersApi,
  IUser
} from '../../services/reportGateway/api/usersApi';
import {
  useRolesApi,
  IRolesListItem
} from '../../services/reportGateway/api/rolesApi';
import {
  convertFormError,
  Error,
  fromReportGatewayError
} from '../../Types/Error';
import { CForm } from '../CForm';
import { CInput } from '../CInput';
import { CSelect } from '../CSelect';
import { Wrapper, WrapperState } from '../Wrapper';
import { ErrorsList } from '../ErrorsList';
import { emptyGuid } from '../../Types/EmptyGuid';

interface SubmitValues {
  RoleId: string;
  UserName: string;
  Password?: string;
  ConfirmPassword?: string;
}

const initialValues: SubmitValues = {
  RoleId: emptyGuid,
  UserName: '',
};

const UserCard = () => {
  const unmounted = useRef(false);
  const usersApi = useUsersApi();
  const rolesApi = useRolesApi();
  const [isLoading, setIsLoading] = useState(false);
  const [initErrors, setInitErrors] = useState<Error[]>([]);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [roles, setRoles] = useState<IRolesListItem[]>([]);
  const [editableUser, setEditableUser] = useState<IUser | null>(null);
  const { currentUser } = useAuth();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const [initial, setInitial] = useState(initialValues);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    async function fetchData(userId: string | null) {
      setInitErrors([]);
      setIsLoading(true);
      try {
        const receivedRoles = await rolesApi.getRoles();
        if (!unmounted.current) {
          setRoles([{ id: emptyGuid, name: '-' }, ...receivedRoles]);
        }
        if (userId) {
          const receivedUser = await usersApi.getUser(userId);
          if (!unmounted.current) {
            setEditableUser(receivedUser);
            setInitial({
              RoleId: receivedUser.roleId,
              UserName: receivedUser.userName,
            });
          }
        }
      } catch (error: any) {
        if (!unmounted.current) {
          setInitErrors(fromReportGatewayError(error));
        }
      } finally {
        if (!unmounted.current) {
          setIsLoading(false);
        }
      }
    }

    if (currentUser?.canEditUsers()) {
      fetchData(params?.id);
    }
  }, [currentUser, params]);

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    }
    else if (!currentUser.canEditUsers()) {
      setWrapperState('accessDenied');
    } else if (isLoading) {
      setWrapperState('loading');
    } else if (initErrors && initErrors.length > 0) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, isLoading, initErrors]);

  async function createUser(values: SubmitValues) {
    try {
      if (values.Password && values.ConfirmPassword) {
        await usersApi.createUser(
          values.UserName,
          values.Password,
          values.ConfirmPassword,
          values.RoleId
        );

        history.push('/users');
      }
    } catch (error: any) {
      if (!unmounted.current) {
        const errors = fromReportGatewayError(error);
        return convertFormError(errors);
      }
    } finally {
    }
  }

  async function editUser(userId: string, values: SubmitValues) {
    try {
      await usersApi.editUser(userId, values.UserName, values.RoleId);
      history.push('/users');  
    } catch (error: any) {
      if (!unmounted.current) {
        const errors = fromReportGatewayError(error);
        return convertFormError(errors);
      }
    }
  }

  async function onSubmit(values: SubmitValues) {
    if (editableUser) {
      const errors = await editUser(
        editableUser.id,
        values);
      return errors;
    } else {
      if (values.Password && values.ConfirmPassword) {
        const errors = createUser(values);
        return errors;
      }
    }
  }
  const onLockUser = () => history.push(`/users/${params.id}/locking`);

  return (
    <Wrapper state={wrapperState} errors={initErrors}>
      <PageHeader
        title={
          editableUser ? `Редактирование пользователя ${editableUser.userName}` : 'Создание пользователя'
        }
        extra={
          editableUser && <Button disabled={!currentUser?.canEditUsers()} onClick={onLockUser}>
            Блокировать пользователя
          </Button>
        }
        onBack={() => history.push('/users')}
      />
      <div className="container left">
        <CForm<SubmitValues>
          onSubmit={onSubmit}
          initialValues={initial}
          autoComplete="off"
          validate={(values) => {
            const errors: Partial<SubmitValues> = {};
            if (!values.UserName) {
              errors.UserName = 'Заполните поле';
            }
            if (!editableUser) {
              if (!values.ConfirmPassword) {
                errors.ConfirmPassword = 'Заполните поле';
              }
              if (!values.Password) {
                errors.Password = 'Заполните поле';
              }
            }
            return errors;
          }}
        >
          {({ values, submitting, submitErrors }) => (
            <Row className="u-mt-4">
              <Col span={24}>
                <CInput name="UserName" label="Логин" />
                {!editableUser && (
                  <>
                    <CInput
                      name="Password"
                      label="Пароль"
                      type="password"
                      autoComplete="off"
                    />
                    <CInput
                      name="ConfirmPassword"
                      label="Подтверждение пароля"
                      type="password"
                      autoComplete="off"
                    />
                  </>
                )}

                <CSelect
                  name="RoleId"
                  label="Выберите роль"
                  options={roles.map((r) => ({ label: r.name, value: r.id }))}
                />

                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                  >
                    {editableUser ? 'Редактировать' : 'Создать'}
                  </Button>
                </Form.Item>
                { submitErrors && <ErrorsList errors={submitErrors} /> }
              </Col>
            </Row>
          )}
        </CForm>
      </div>
    </Wrapper>
  );
};

export default UserCard;
