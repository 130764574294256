import { HttpError } from "../httpClient";
import { ReportGatewayError, ReportGatewayErrorValue } from "../../Types/ReportGatewayError";

interface ReportGatewayHttpErrorValue {
    message: string;
}

interface ReportGatewayHttpError {
    values: ReportGatewayHttpErrorValue[];
}

interface ReportGatewayValidationHttpErrorValue {
    fieldName: string;
    message: string;
}

interface ReportGatewayValidationHttpError {
    values: ReportGatewayValidationHttpErrorValue[];
}

const reportGatewayErrorsParser = {
    parse(httpError: HttpError): ReportGatewayError {
        if (httpError.statusCode === 400) {
            const validationError = httpError.data as ReportGatewayValidationHttpError;
            if (validationError?.values?.length > 0) {
                return {
                    statusCode: httpError.statusCode,
                    values: validationError.values.map<ReportGatewayErrorValue>(value => ({
                        fieldName: value.fieldName,
                        message: value.message
                    }))
                };
            }
            else {
                return {
                    statusCode: httpError.statusCode,
                    values: []
                };
            }
        }
        else {
            const error = httpError.data as ReportGatewayHttpError
            if (error?.values?.length > 0) {
                return {
                    statusCode: httpError.statusCode ? httpError.statusCode : null,
                    values: error.values.map<ReportGatewayErrorValue>(value => ({
                        fieldName: null,
                        message: value.message
                    }))
                };
            }
            else {
                return {
                    statusCode: httpError.statusCode ? httpError.statusCode : null,
                    values: []
                };
            }
        }
    }
}

export default reportGatewayErrorsParser;