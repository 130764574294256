import { Alert, Button, Col, Form, Modal, PageHeader, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../Auth';
import {
  useUsersApi,
  IUserLocking,
} from '../../services/reportGateway/api/usersApi';
import {
  convertFormError,
  Error,
  fromReportGatewayError,
} from '../../Types/Error';
import { CDatePicker } from '../CDatePicker';
import { CForm } from '../CForm';
import { ErrorsList } from '../ErrorsList';
import { Wrapper, WrapperState } from '../Wrapper';

const { confirm } = Modal;

interface SubmitValues {
  LockoutEnd: string;
}

function UserLocking() {
  const unmounted = useRef(false);
  const usersApi = useUsersApi();
  const [isLoading, setIsLoading] = useState(false);
  const [initErrors, setInitErrors] = useState<Error[]>([]);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [currentUserLocking, setCurrentUserLocking] = useState<IUserLocking>({
    lockoutEnd: null,
    userName: ''
  });
  const { currentUser } = useAuth();
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const [initial, setInitial] = useState<SubmitValues>({
    LockoutEnd: '',
  });

  useEffect(() => {
    return function cleanup() {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    async function fetchData(userId: string) {
      setInitErrors([]);
      setIsLoading(true);
      try {
        const receivedUserLocking = await usersApi.getUserLocking(
          userId
        );

        if (!unmounted.current) {
          setCurrentUserLocking(receivedUserLocking);
          setInitial({
            LockoutEnd: receivedUserLocking.lockoutEnd
              ? receivedUserLocking.lockoutEnd.toString()
              : '',
          });
        }
      } catch (error: any) {
        if (!unmounted.current) {
          setInitErrors(fromReportGatewayError(error));
        }
      } finally {
        if (!unmounted.current) {
          setIsLoading(false);
        }
      }
    }

    if (currentUser?.canEditUsers()) {
      fetchData(params.id);
    }
  }, [currentUser, params]);

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    }
    else if (!currentUser?.canEditUsers()) {
      setWrapperState('accessDenied');
    } else if (isLoading) {
      setWrapperState('loading');
    } else if (initErrors && initErrors.length > 0) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, isLoading, initErrors]);

  async function setUserLocking(userId: string, lockoutEnd: moment.Moment | null) {
    try {
      await usersApi.setUserLocking(userId, lockoutEnd);
      history.push('/users');
    } catch (error: any) {
      if (!unmounted.current) {
        const errors = fromReportGatewayError(error);
        return convertFormError(errors);
      }
    }
  }

  async function onSubmit(values: SubmitValues) {
    if (currentUserLocking.lockoutEnd) {
      const errors = await setUserLocking(params.id, null);
      return errors;
    } else if (values.LockoutEnd) {
      const lockoutEndDate = moment(new Date(values.LockoutEnd));
      // confirm({
      //   title: `Заблокировать пользователя до ${lockoutEndDate.toUTC()} (UTC)?`,
      //   onOk: () => setUserLocking(params.id, lockoutEndDate),
      //   okText: 'Да',
      //   cancelText: 'Нет',
      //   width: 420,
      // });
      if (window.confirm(`Заблокировать пользователя до ${lockoutEndDate.format('DD.MM.yyyy HH:mm:ss')}?`)) {
        const errors = await setUserLocking(params.id, lockoutEndDate)
        return errors;
      }
    }
  }

  const onEditUser = () => history.push(`/users/${params.id}`);

  return (
    <Wrapper state={wrapperState} errors={initErrors}>
      <PageHeader
        title={
          currentUserLocking.lockoutEnd
            ? `Разблокировка пользователя ${currentUserLocking.userName}`
            : `Блокировка пользователя ${currentUserLocking.userName}`
        }
        extra={<Button onClick={onEditUser}>Редактировать пользователя</Button>}
        onBack={() => history.push('/users')}
      />
      <div className="container left">
        <CForm<SubmitValues>
          onSubmit={onSubmit}
          initialValues={initial}
          autoComplete="off"
        >
          {({ values, submitting, submitErrors }) => (
            <Row className="u-mt-4">
              <Col span={24}>
                {currentUserLocking.lockoutEnd ? (
                  <Alert
                    message={
                      <span>
                        Дата завершения блокировки:{' '}
                        {currentUserLocking.lockoutEnd.format('DD.MM.yyyy HH:mm:ss')}
                      </span>
                    }
                    type="info"
                    className="u-mb-4"
                  />
                ) : (
                  <CDatePicker
                    name="LockoutEnd"
                    label="Дата завершения блокировки"
                    showTime
                  />
                )}

                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                  >
                    {currentUserLocking.lockoutEnd
                      ? 'Разблокировать'
                      : 'Заблокировать'}
                  </Button>
                </Form.Item>
              </Col>
              { submitErrors && <ErrorsList errors={submitErrors}/> }
            </Row>
          )}
        </CForm>
      </div>
    </Wrapper>
  );
}

export default UserLocking;