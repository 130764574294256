import { Button, Col, Row, Table, TablePaginationConfig, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import { IPagedList } from '../../../Types/PagedList';
import { useAuth } from '../../../Auth';
import { defaultPage, defaultPageSize, useCertificatesTableConfig } from './CertificatesTableConfig';
import { useQuery } from 'react-query';
import { getColumns } from './CertificatesTableColumns';
import ICertificatesListItem from '../../../Types/backend/certificates/CertificatesListItem';
import usePermissionsApi from '../../../services/reportGateway/api/permissionsApi';
import useCertificatesApi from '../../../services/reportGateway/api/certificatesApi';
import { Wrapper } from '../../../components/Wrapper';
import { fromReportGatewayError } from '../../../Types/Error';

interface ICertificatesTableItem extends ICertificatesListItem {
  key: string;
}

export function CertificatesTable() {
  const { currentUser } = useAuth();
  const canView = useMemo(() => currentUser?.canViewPermissions() ?? false, [currentUser])

  const api = useCertificatesApi();
  const { requestData, setPaging } = useCertificatesTableConfig();
  const { filter, paging } = requestData;

  const { refetch, data, error, isLoading, isFetching } = useQuery(
    ['certificates', 'list', canView, filter, paging],
    fetchData,
    { enabled: canView }
  )

  async function fetchData(): Promise<IPagedList<ICertificatesTableItem> | undefined> {
    if (!canView) return undefined;

    const response = await api.list(requestData.paging.page, requestData.paging.pageSize);

    return {
      ...response,
      list: response.list.map(value => ({
        ...value,
        key: `certificates_${value.id}`
      }))
    }
  }

  const initErrors = useMemo(() => error ? fromReportGatewayError(error as any) : [], [error])

  function handleOnChange(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  function handleOnRefresh() {
    refetch();
  }

  return (
    <>
      <Row gutter={8} style={{ marginTop: '1rem' }}>
        <Col flex={1} />
        <Col>
          <Tooltip title='Обновить'>
            <Button
              shape='circle'
              icon={<ReloadOutlined />}
              loading={isLoading || isFetching}
              onClick={handleOnRefresh}
            />
          </Tooltip>
        </Col>
      </Row>
      <Wrapper
        state={error ? 'hasErrors' : null}
        errors={initErrors}
      >
      <Table
        style={{ marginTop: '1rem' }}
        scroll={{ x: 900 }}
        loading={isLoading}
        onChange={handleOnChange}
        columns={getColumns()}
        dataSource={data?.list}

        pagination={{
          current: requestData.paging.page,
          position: ['bottomRight'],
          pageSize: requestData.paging.pageSize,
          total: data?.totalRowCount,
          showSizeChanger: false
        }}
      />
      </Wrapper>
    </>
  )
}