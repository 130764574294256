import { IProductProviderHierarchyReferencesItem } from '../../../Types/backend/products/ProductHierarchyReferencesItem';
import { useMakeReportGatewayRequest } from '../makeReportGatewayRequest';

interface IProductResponse {
  id: string;
  name: string;
  providerName: string;
}

export interface IProductSelectViewModel {
  id: string;
  name: string;
}

interface IProductProviderHierarchyReferencesItemResponse {
  id: string;
  name: string;
  products: IProductHierarchyReferencesItemResponse[];
}

interface IProductHierarchyReferencesItemResponse {
  id: string;
  providerId: string;
  name: string;
}

interface IProductsApi {
  getProducts(): Promise<IProductSelectViewModel[]>;
  getProduct(productId: string): Promise<IProductSelectViewModel>;
  hierarchyReferences(): Promise<IProductProviderHierarchyReferencesItem[]>;
}

export const useProductsApi = (): IProductsApi => {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  const getProducts = (): Promise<IProductSelectViewModel[]> => {
    return makeReportGatewayRequest<IProductResponse[], IProductSelectViewModel[]>(
      'api/references/products',
      "get",
      response => {
        return response
          .sort((a, b) => a.providerName.localeCompare(b.providerName)).map<IProductSelectViewModel>(value => {
            return {
              id: value.id,
              name: `${value.name} ${value.providerName}`
            }
          });
      },
      undefined
    );
  }

  const getProduct = (productId: string) => {
    return makeReportGatewayRequest<IProductResponse, IProductSelectViewModel>(
      `/api/references/products/${productId}`,
      'get',
      response => ({ id: response.id, name: `${response.name} ${response.providerName}` }),
      undefined
    );
  }

  function hierarchyReferences(): Promise<IProductProviderHierarchyReferencesItemResponse[]> {
    return makeReportGatewayRequest<IProductProviderHierarchyReferencesItemResponse[], IProductProviderHierarchyReferencesItem[]>(
      '/api/references/hierarchy',
      'get',
      response => response,
      null,
      'json'
    );
  }

  return {
    getProducts,
    getProduct,
    hierarchyReferences
  }
}