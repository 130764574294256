import { Modal, Tree, TreeDataNode, notification } from "antd";
import React, { Key, useState } from "react";
import usePermissionsApi from "../../../services/reportGateway/api/permissionsApi";
import { useQuery } from "react-query";

const ClientKey = 'client';
const ProviderKey = 'provider';
const PermissionKey = 'permission';
interface IPermissionsExportProps {
  open: boolean;
  onOk(): void;
  onCancel(): void;
}

export function PermissionsExport(props: IPermissionsExportProps) {
  const { open, onOk, onCancel } = props;

  const permissionsApi = usePermissionsApi();

  const [selected, setSelected] = useState('');
  const [checked, setChecked] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const { refetch, data: permissions, error, isLoading, isFetching } = useQuery(
    ['permissions', 'export'],
    fetchData,
    { retry: 3 }
  )

  async function fetchData(): Promise<TreeDataNode[]> {
    const response = await permissionsApi.hierarchyReferences();

    return response.map(client => ({
      key: `${ClientKey}.${client.id}`,
      title: client.name,
      children: client.children.map<TreeDataNode>(provider => (
        {
          key: `${ProviderKey}.${provider.id}.${client.id}`,
          title: provider.name,
          children: provider.children.map<TreeDataNode>(value => {
            const title = `${value.productName ? `${value.productName} - ` : ''}${value.credentialsDescription}${value.endDate ? ` (до: ${value.endDate.local().format('DD.MM.YYYY HH:mm')})` : ''}`;

            return {
              key: `${PermissionKey}.${value.id}`,
              title: title
            }
          })
        }
      ))
    }))
  }

  function handleOnSelect(keys: Key[]) {
    setSelected(keys.length > 0 ? keys[0].toString() : '');
  }

  function handleOnCheck(keys: any, i: any) {
    setChecked(keys);
  }

  function reset() {
    setSelected('');
    setChecked([]);
  }

  async function handleOnOk() {
    setIsProcessing(true);

    let successful = true;

    try {
      const checkedPermissions = checked
        .filter(value => value.split('.')[0] === PermissionKey)
        .map(value => value.split('.')[1]);
      const response = await permissionsApi.export(checkedPermissions);
      const data = new Blob([response], { type: 'text/plain' });;
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Permissions_export.sql'
      a.click();
    } catch (error) {
      console.error(error);
      successful = false;
    } finally {
      setIsProcessing(false);
    }

    if (successful) {
      onOk();
      reset();
    } else {
      notification.error({
        message: 'Не удалось выполнить экспорт разрешений'
      })
    }
  }

  function handleOnCancel() {
    onCancel();
    reset();
  }

  return (
    <Modal
      title='Экспорт разрешений'
      width={900}
      open={open}
      closable={false}
      maskClosable={false}
      onOk={handleOnOk}
      okText='Экспорт'
      okButtonProps={{ loading: isProcessing, disabled: checked.length === 0 }}
      onCancel={onCancel}
      cancelText='Отмена'
      cancelButtonProps={{ disabled: isProcessing }}
    >
      <Tree
        style={{ maxHeight: '450px', overflow: 'auto' }}
        checkable
        checkedKeys={checked}
        onCheck={handleOnCheck}
        selectable
        selectedKeys={[selected]}
        onSelect={handleOnSelect}
        treeData={permissions}
      />
    </Modal>
  )
}