import { Badge, Button, Card, Col, Collapse, Divider, List, Modal, PageHeader, Row, Space, Timeline, Typography, Tag, Popover, Descriptions, Alert } from 'antd';
import { DownloadOutlined, QuestionCircleOutlined, ScissorOutlined } from '@ant-design/icons';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Moment } from 'moment';
import { useAuth } from '../../Auth';
import {
  useRequestsApi,
  IRequestViewModel
} from '../../services/reportGateway/api/requestsApi';
import {
  useReportsApi
} from '../../services/reportGateway/api/reportsApi';
import {
  Error,
  fromReportGatewayError
} from '../../Types/Error';
import { Wrapper, WrapperState } from '../Wrapper';
import { dateTimeFormat } from '../../Types/DateTimeFormat';

interface StatusTimeline {
  label: string;
  value: string;
}

export const RequestCard = () => {
  const unmounted = useRef(false);
  const requestsApi = useRequestsApi();
  const reportsApi = useReportsApi();
  const [isLoading, setIsLoading] = useState(false);
  const [initErrors, setInitErrors] = useState<Error[]>([]);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [request, setRequest] = useState<IRequestViewModel | null>(null);
  const [title, setTitle] = useState<string>(" ");
  const { currentUser } = useAuth();
  const history = useHistory();
  const [statusTimeline, setStatusTimeline] = useState<StatusTimeline[]>([]);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);
  async function fetchData(requestId: string) {
    setInitErrors([]);
    setIsLoading(true);
    try {
      const recivedRequest = await requestsApi.getRequest(requestId);

      if (!unmounted.current) {
        setRequest(recivedRequest);
        setTitle(recivedRequest?.productName + " " + recivedRequest?.providerName + " для " + recivedRequest?.clientName)

        const timeline = recivedRequest.activities.map<StatusTimeline>(value => ({
          label: value.createdTimestampFormatted,
          value: value.state
        }));

        setStatusTimeline(timeline);
      }
    } catch (error: any) {
      if (!unmounted.current) {
        setInitErrors(fromReportGatewayError(error));
      }
    } finally {
      if (!unmounted.current) {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (currentUser?.canViewRequests()) {
      fetchData(params?.id);
    }
  }, [currentUser, params]);

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    }
    else if (!currentUser.canViewRequests()) {
      setWrapperState('accessDenied');
    } else if (isLoading) {
      setWrapperState('loading');
    } else if (initErrors && initErrors.length > 0) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, isLoading, initErrors]);

  const showRequestBodyModal = (event: SyntheticEvent, providerRequestBody: string | undefined) => {
    Modal.info({
      title: 'Запрос к поставщику',
      content: providerRequestBody,
      width: 600
    });
  };
  const showRequestRgBodyModal = () => {
    Modal.info({
      title: 'Запрос к RG.API',
      content: request?.incomingRequest.requestBody,
      width: 600
    });
  };
  // useState for loading spinner
  const downloadReport = async () => {
    if (request == null) return;
    var fileContent = await reportsApi.getReport(request.id);
    if (fileContent == null) {
      Modal.error({
        title: '',
        content: 'Файл отчета отсутствует или не найден',
      });
    }
    else {
      var data = null;
      if (request.hasPdfReport) {
        data = new Blob([fileContent], { type: 'application/pdf' })
      }
      else {
        data = new Blob([JSON.stringify(fileContent, null, 2)], { type: 'application/json' });
      }
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      var extension = request.hasPdfReport ? '.pdf' : '.json'
      a.download = 'Report content ' + request.id + extension;
      a.click();
    }
  };
  const downloadProviderResponse = async () => {
    if (request == null) return;
    var fileContent = await requestsApi.getProviderResponse(request.id);
    if (fileContent == null) {
      Modal.error({
        title: '',
        content: 'Ответ поставщика отсутствует',
      });
    }
    else {
      var data = new Blob([fileContent], { type: 'text/xml' });
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Provider response ' + request.id + '.txt';
      a.click();
    }
  };

  const downloadApiInteractionRequest = async (id: string) => {
    var fileContent = await requestsApi.getApiInteractionRequest(id);
    if (!fileContent) {
      Modal.error({
        title: '',
        content: 'Запрос к поставщику отсутствует',
      });
    }
    else {
      var data = new Blob([fileContent], { type: 'text/xml' });
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Api interaction request ${id}.txt`;
      a.click();
    }
  };

  const downloadApiInteractionResponse = async (id: string) => {
    var fileContent = await requestsApi.getApiInteractionResponse(id);
    if (!fileContent) {
      Modal.error({
        title: '',
        content: 'Ответ поставщика отсутствует',
      });
    }
    else {
      var data = new Blob([fileContent], { type: 'text/xml' });
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Api interaction response ${id}.txt`;
      a.click();
    }
  };

  function renderCleanInfo(dateTime: Moment) {
    const info = <span>{`Данные очищены ${dateTime.format(dateTimeFormat)}`}</span>
    return (
      <>
        <Tag icon={<ScissorOutlined />}>Очищено</Tag>
        <Popover content={info} trigger='hover'>
          <QuestionCircleOutlined />
        </Popover>
      </>
    )
  }

  return (
    <Wrapper state={wrapperState} errors={initErrors}>
      <PageHeader title={title} onBack={() => history.push('/requests')} />
      <div className="container left">
        <Card>
          <Divider orientation="left">Общие сведения</Divider>
          <Row gutter={16}>
            <Col span={12} >
              <p>Создан: {request?.createdTimestampFormatted}</p>
              <p>Субъект: {request?.subject}</p>
              <p>Биллинг: {request?.providerPayType ?? "информация отсутствует"}</p>
            </Col>
            <Col span={12} >
              <p><Badge status={request?.state === "Ошибка" ? "error" : request?.state === "Завершен" ? "success" : "processing"} text={request?.state} /> </p>
              <p hidden={request?.reportType == null && request?.reportFileId == null}>
                {request?.reportType != null ? request?.reportType : '-'}
                <Button type="link" shape="round" icon={<DownloadOutlined />} disabled={request?.reportFileId == null} onClick={downloadReport}>
                  Скачать
                </Button>
              </p>
              <p hidden={request?.reportType != null || request?.reportFileId != null}>
                <i>отчет отсутствует</i>
              </p>
            </Col>
          </Row>
          <Divider orientation="left">Cтатусы и события</Divider>
          <Row gutter={16}>
            <Col span={12} >
              <Timeline mode="left">
                {statusTimeline.map((value, index) => <Timeline.Item
                  key={`activity_${index}`}
                  color="gray"
                  label={value.label}
                >
                  {value.value}
                </Timeline.Item>)}
              </Timeline>
            </Col>
            <Col span={12} >
              <List
                size="small"
                dataSource={request?.events}
                locale={{ emptyText: 'События отсутствуют' }}
                renderItem={item =>
                  <List.Item>
                    <Badge
                      status={item?.level === 3 ? "error" : item?.level === 2 ? "warning" : "default"}
                      text={item.eventType} />
                    <br />EventLogId: {item.eventLogId ? <Typography.Text copyable>{item.eventLogId}</Typography.Text> : 'отсуствует'}
                    <br />{item.details}
                    <br />{item.createdTimestampFormatted}
                    <br />{item.eventDetails}
                  </List.Item>}
              />
            </Col>
          </Row>
          <Divider orientation="left">Обмен с поставщиком</Divider>
          <List
            size="small"
            dataSource={request?.providerRequests}
            locale={{ emptyText: 'Обмена с поставщиком не было' }}
            renderItem={item =>
              <List.Item key={item.id}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Row gutter={8}>
                      {item.cleanDateTime ? (
                        <Col>{renderCleanInfo(item.cleanDateTime)}</Col>
                      ) : (
                        <>
                          <Col>
                            <Button onClick={e => showRequestBodyModal(e, item.providerRequestBody)} hidden={item.providerRequestBody == null}>
                              Просмотреть запрос
                            </Button>
                          </Col>
                          <Col>
                            <Button icon={<DownloadOutlined />} hidden={item.hasProviderResponseBody !== true} onClick={downloadProviderResponse}>
                              Скачать ответ
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>

                  <Col span={24}>
                    {item.credentials ? (
                      <Descriptions bordered column={1} size='small'>
                        <Descriptions.Item label='Юр. лицо'>{item.credentials.organizationName}</Descriptions.Item>
                        <Descriptions.Item label='Срок действия'>
                          {`с ${item.credentials?.startDate?.format('DD.MM.YYYY HH:mm:ss:SSS Z') ?? '-'} по ${item.credentials?.endDate?.format('DD.MM.YYYY HH:mm:ss:SSS Z') ?? '-'}`}
                        </Descriptions.Item>
                        {item.credentials.comment && (
                          <Descriptions.Item label='Комментарий'>{item.credentials.comment}</Descriptions.Item>
                        )}
                        {item.credentials.signCertificateThumbprint && (
                          <Descriptions.Item label='Подписание'>
                            {item.credentials.signCertificateThumbprint}
                          </Descriptions.Item>
                        )}
                        {item.credentials.encryptCertificateThumbprint && (
                          <Descriptions.Item label='Шифрование'>
                            {item.credentials.encryptCertificateThumbprint}
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label='Детали'>{item.credentials.value}</Descriptions.Item>
                      </Descriptions>
                    ) : (
                      <Alert type='warning' message='Реквизиты к поставщику не были использованы' />
                    )}
                  </Col>

                  <Divider orientation="left">Детализация взаимодействия с поставщиком</Divider>
                  <List
                    size="small"
                    dataSource={item.apiInteractionEvents}
                    locale={{ emptyText: 'Запросы к поставщику отсутствуют' }}
                    renderItem={item =>
                      <List.Item key={item.id}>
                        <Row gutter={16}>
                          <Col span={12}>
                            {
                              item.cleanDateTime
                                ? renderCleanInfo(item.cleanDateTime)
                                : (
                                  <Space>
                                    <Button icon={<DownloadOutlined />} onClick={() => downloadApiInteractionRequest(item.id)}>
                                      Скачать запрос
                                    </Button>
                                    <Button icon={<DownloadOutlined />} onClick={() => downloadApiInteractionResponse(item.id)}>
                                      Скачать ответ
                                    </Button>
                                  </Space>
                                )
                            }
                          </Col>
                          <Col span={12}>
                            <p>Запрос отправлен в: {item.requestTimestampFormated}</p>
                            <p>Ответ получен в: {item.responseTimestampFormated}</p>
                            <p>URL: {item.url}</p>
                            <p>Код ответа: {item.responseCode}</p>
                            {item.errorResponse && <p>Сообщение ошибки: {item.errorResponse}</p>}
                          </Col>
                        </Row>
                      </List.Item>}
                  />
                </Row>
              </List.Item>}
          />
          <Divider orientation="left">Обмен с RG.API</Divider>
          <Space direction='vertical'>
            <div>URL: {request?.incomingRequest.requestUrl}</div>
            <div>Дата-время запроса: {request?.incomingRequest?.requestDateTime?.format(dateTimeFormat)}</div>
            <div>
              {
                request?.incomingRequest?.cleanDateTime
                  ? renderCleanInfo(request?.incomingRequest?.cleanDateTime)
                  : <Button onClick={showRequestRgBodyModal}> Просмотреть запрос</Button>
              }
            </div>
          </Space>
        </Card>
      </div>
    </Wrapper >
  );
};