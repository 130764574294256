import React, { useCallback, useMemo, useState } from 'react';
import { Button, PageHeader, notification } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { CertificatesTable } from './CertificatesTable';
import { CertificateAddition } from '../../../components/Certificates';
import { useAuth } from '../../../Auth';

export function CertificatesList() {
  const { currentUser } = useAuth();
  const canEdit = useMemo(() => currentUser?.canEditPermissions() ?? false, [currentUser])

  const [addition, setAddition] = useState(false);

  const handleOnOpenAddition = useCallback(() => {
    setAddition(true);
  }, [setAddition])

  const handleOnCancelAddition = useCallback(() => {
    setAddition(false);
  }, [setAddition])

  const handleOnSuccessfulAddition = useCallback(() => {
    setAddition(false);
  }, [setAddition])

  return (
    <>
      <PageHeader
      title='Сертификаты'
      extra={(
        <Button
          disabled={!canEdit || addition}
          ghost
          type='primary'
          icon={<PlusOutlined />}
          onClick={handleOnOpenAddition}
        >
          Добавить
        </Button>
      )}
      />
      <CertificatesTable />
      <CertificateAddition
        open={addition}
        onSuccess={handleOnSuccessfulAddition}
        onCancel={handleOnCancelAddition}
      />
    </>
  )
}