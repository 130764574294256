import { Divider, Form, Input, Modal, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { ProviderSelect } from "../../../components/ProviderSelect";
import useEndpointsApi from "../../../services/reportGateway/api/endpointApi";
import { CertificateSelect } from "../../../components/Certificates";
import { EndpointPurposeSelect } from "../Select/EndpointPurposeSelect";

const { useForm } = Form;

const CertificateId = 'CertificateId';

interface IBackendForm {
  Description: string;
  ProviderId: string;
  Purpose: number;
  Url: string;
  HostHeader: string;
  [CertificateId]: string;
}

interface IEndpointAdditionProps {
  providerId?: string;
  open: boolean;
  onOk(): void;
  onCancel(): void;
}

export function EndpointAddition(props: IEndpointAdditionProps) {
  const { providerId, open, onOk, onCancel } = props;

  const endpointsApi = useEndpointsApi();

  const [form] = useForm<IBackendForm>();

  const [isProcessing, setIsProcessing] = useState(false);
  const [certificateId, setCertificateId] = useState('');

  useEffect(() => {
    form.setFieldValue(CertificateId, certificateId);
  }, [certificateId])

  function handleOnCancel() {
    onCancel();
    form.resetFields();
  }

  async function handleOnFinish(values: IBackendForm) {
    const { ProviderId, Description, Purpose, Url, HostHeader, CertificateId } = values;
    setIsProcessing(true);

    let successful = true;

    try {
      await endpointsApi.create(Description, Purpose, Url,
        HostHeader ? HostHeader : null, CertificateId ? CertificateId : null);
    } catch (error) {
      console.error(error);
      successful = false;
    } finally {
      setIsProcessing(false);
    }

    if (successful) {
      onOk();
      form.resetFields();
    } else {
      notification.error({
        message: 'Не удалось добавить эндпоинт'
      })
    }
  }

  return (
    <Modal
      title='Добавление эндпоинта'
      width={600}
      open={open}
      closable={false}
      maskClosable={false}
      onOk={form.submit}
      okButtonProps={{ loading: isProcessing }}
      okText='Добавить'
      onCancel={handleOnCancel}
      cancelButtonProps={{ disabled: isProcessing }}
      cancelText='Отмена'
    >
      <Form
        form={form}
        initialValues={{
          ProviderId: providerId ?? '',
          Purpose: 3,
          [CertificateId]: '',
        }}
        onFinish={handleOnFinish}
        labelCol={{ span: 6 }}
      >
        <Form.Item
          name='Description'
          label='Описание'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          hidden={providerId !== undefined && providerId !== ''}
          name='ProviderId'
          label='Поставщик'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <ProviderSelect />
        </Form.Item>
        <Form.Item
          name='Purpose'
          label='Назначение'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <EndpointPurposeSelect />
        </Form.Item>
        <Form.Item
          name='Url'
          label='Url'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <Input type='url' />
        </Form.Item>
        <Form.Item name='HostHeader' label='Host'>
          <Input />
        </Form.Item>

        <Divider orientation='left'>Сертификат</Divider>
        <Form.Item name={CertificateId}>
          <CertificateSelect providerId={providerId} value={certificateId} onChange={setCertificateId} />
        </Form.Item>
      </Form>
    </Modal>
  )
}