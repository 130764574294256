import { Select, SelectProps } from "antd";
import React from "react";
import { useClientsApi } from "../../../services/reportGateway/api/clientsApi";
import { useQuery } from "react-query";

export function ClientsSelect(props: SelectProps) {
  const clientsApi = useClientsApi();

  const { data: clients, isLoading, isFetching } = useQuery(
    ['clients', 'select'],
    fetchClients,
    { retry: 3 }
  )

  async function fetchClients(): Promise<{ label: string, value: string }[]> {
    const response = await clientsApi.getClients();

    return response.map(value => ({
      label: value.name,
      value: value.id
    }))
  }

  return (
    <Select
      {...props}
      allowClear
      loading={isLoading || isFetching}
      options={clients}
    />
  )
}