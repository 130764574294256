import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import {FormOutlined} from '@ant-design/icons';
import {
  IRequestListItem
} from '../../services/reportGateway/api/requestsApi';

type TColumnProps = ColumnProps<IRequestListItem>;

export const requestListColumns = (redirectOnViewRequest: (id: string) => void,): TColumnProps[] => {
  return [
    {
      title: 'Продукт',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Поставщик',
      dataIndex: 'providerName',
      key: 'providerName',
    },
    {
      title: 'Клиент',
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdTimestampFormatted',
      key: 'createdTimestamp',
    },
    {
      title: 'Статус',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Субъект',
      dataIndex: 'subject',
      key: 'subject',
      width: '35%',
    },
    {
      title: 'Действия',
      key: 'action',
      width: 220,
      render: (text, record) => (
        <Button
          onClick={() => redirectOnViewRequest(record.id)}
          block
          type="primary"
          icon={<FormOutlined />}
        >
              Просмотреть
        </Button>
      ),
    }
  ];
};
