import React from "react";
import { Badge, Button, Col, Row, Tooltip, Tag, Descriptions, Popover, Typography, Space } from "antd";
import { LockOutlined, FormOutlined, LockFilled, InfoCircleOutlined, InfoCircleFilled, InfoOutlined } from '@ant-design/icons';
import { ColumnProps } from "antd/lib/table";
import IPermissionsListItem from "../../../Types/backend/permissions/PermissionsListItem";
import { BadgeProps } from "antd/lib/badge";
import moment from "moment";
import { TagProps } from "antd/lib/tag";

const { CheckableTag } = Tag;
const { Text } = Typography;

const dateFormat = 'DD.MM.YYYY HH:mm Z';

type ActiveStatus = 'active' | 'inactive';

const activeBadgeMap: Record<ActiveStatus, BadgeProps> = {
  active: { text: 'Активно', status: 'processing' },
  inactive: { text: 'Не активно', status: 'error' }
}

type EndpointsStatus = 'none' | 'some';

const endpointsTagMap: Record<EndpointsStatus, TagProps> = {
  none: { color: 'error' },
  some: { color: 'default' }
}

interface ICredentialsInfo {
  credentialsId: string;
  permissionId: string;
}

interface IEndpointInfo {
  endpointId: string;
  permissionId: string;
}

interface IParams {
  onShowCredentialsInfo(item: IPermissionsListItem): void;
  credentialsInfo: ICredentialsInfo | null;
  onShowEndpointInfo(permission: IPermissionsListItem, endpointId: string, endpointPurpose: string): void;
  endpointInfo: IEndpointInfo | null;
  onShowPermissionLimitation(permission: IPermissionsListItem): void;
  permissionLimitationId: string | null;
}

export function getColumns(params: IParams): ColumnProps<IPermissionsListItem>[] {
  const { onShowCredentialsInfo, credentialsInfo, onShowEndpointInfo, endpointInfo,
    onShowPermissionLimitation, permissionLimitationId } = params;
  const now = moment().utc();
  return [
    {
      key: 'index',
      title: '#',
      width: 70,
      align: 'center',

      render(value, record, index) {
        return index + 1;
      },
    },
    {
      key: 'clientName',
      title: 'Клиент',
      render(value, record, index) {
        return record.clientDisabled ? (
          <Badge status='error' text={record.clientName} />
        ) : (
          <Text>{record.clientName}</Text>
        )
      },
    },
    {
      key: 'object',
      title: 'Поставщик / Продукт',
      render(value, record, index) {
        const provider = record.providerDisabled ? (
          <Badge status='error' text={record.providerName} />
        ) : (
          <Text>{record.providerName}</Text>
        )

        const product = record.productId === null ? (
          null
        ) : (
          record.productDisabled ? (
            <Badge status='error' text={record.productName} />
          ) : (
            <Text>{record.productName}</Text>
          )
        )

        return (
          product === null ? (
            provider
          ) : (
            <Row gutter={8}>
              <Col>{provider}</Col>
              <Col>{'/'}</Col>
              <Col>{product}</Col>
            </Row>
          )
        )
      },
    },
    {
      key: 'credentials',
      title: 'Учетные данные',
      render(value, record, index) {
        const credentials = record.credentialsDisabled ? (
          <Badge status='error' text={record.credentialsComment} />
        ) : (
          <Text>{record.credentialsComment}</Text>
        )

        return (
          <Row gutter={8}>
            <Col>{credentials}</Col>
            <Col>
              {credentialsInfo?.permissionId === record.id && credentialsInfo.credentialsId === record.credentialsId ? (
                <InfoCircleFilled />
              ) : (
                <InfoCircleOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => onShowCredentialsInfo(record)}
                />
              )}
            </Col>
          </Row>
        )
      },
    },
    {
      key: 'status',
      title: 'Статус',
      render(value, record, index) {
        const status: ActiveStatus = record.endDate === null || record.endDate > now
          ? 'active'
          : 'inactive';

        const endDate = record.endDate ? record.endDate.local().format(dateFormat) : '∞';

        const content = (
          <Descriptions column={1} size='small' style={{ maxWidth: '400px' }}>
            <Descriptions.Item label='С'>
              {record.startDate.local().format(dateFormat)}
            </Descriptions.Item>
            <Descriptions.Item label='По'>{endDate}</Descriptions.Item>
          </Descriptions>
        )

        const product = record.productName
          ? `${record.providerName} / ${record.productName}`
          : record.providerName

        return (
          <Row gutter={8}>
            <Col><Badge {...activeBadgeMap[status]} /></Col>
            <Col>
              <Popover
                trigger='click' placement='left' content={content}
                title={`Период действия для ${record.clientName} - ${product}`}
              >
                <InfoCircleOutlined style={{ cursor: 'pointer' }} />
              </Popover>
            </Col>
          </Row>
        )
      },
    },
    {
      key: 'endpoints',
      title: 'Эндпоинты',
      render(value, record, index) {
        if (record.endpoints.length === 0) return (
          <Tag color='error'>Нет привязанных эндпоинтов</Tag>
        )

        return (
          <Row gutter={[8, 8]}>
            {record.endpoints.map(value => (
              <Col key={`endpoint_${value.id}`}>
                <Tag
                  color={value.disabled ? 'error' : 'default'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => onShowEndpointInfo(record, value.id, value.purpose)}
                  icon={endpointInfo?.permissionId === record.id && endpointInfo?.endpointId === value.id ? (
                    <InfoCircleOutlined />
                  ) : (
                    undefined
                  )}
                >
                  {value.purpose}
                </Tag>
              </Col>
            ))}
          </Row>
        )
      },
    },
    {
      key: 'actions',
      render(value, record, index) {
        return (
          <Row gutter={8}>
            <Col>
              <Tooltip title='Ограничить'>
                <Button
                  shape='circle'
                  icon={permissionLimitationId === record.id ? <LockFilled /> : <LockOutlined />}
                  onClick={() => onShowPermissionLimitation(record)}
                />
              </Tooltip>
            </Col>
          </Row>
        )
      },
    }
  ]
}