import { useMakeReportGatewayRequest } from '../makeReportGatewayRequest';

interface IClientListItemResponse {
  id: string;
  name: string;
}

export interface IClientListItem {
  id: string;
  name: string;
}

interface IClientsApi {
  getClients(): Promise<IClientListItem[]>;
}

export const useClientsApi = (): IClientsApi => {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  const getClients = (): Promise<IClientListItem[]> => {
    return makeReportGatewayRequest<IClientListItemResponse[], IClientListItem[]>(
      'api/references/clients',
      'get',
      response => response.map<IClientListItem>(value => ({ ...value })),
      undefined
    );
  }

  return {
    getClients
  }
}