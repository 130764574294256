import { Form, Select } from 'antd';
import React from 'react';
import { useField } from 'react-final-form';
import { CSelectProps, RawSelectProps } from './types';

function RawSelect({
  label,
  input,
  meta,
  hint,
  className,
  handleChange,
  autoComplete,
  placeholder,
  disabled,
  size = 'large',
  ...rest
}: RawSelectProps) {
  return (
    <Form.Item
      label={label}
      validateStatus={
        meta.touched &&
        (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit))
          ? 'error'
          : ''
      }
      help={
        (meta.touched && meta.error) ||
        (meta.submitError && !meta.dirtySinceLastSubmit)
          ? meta.error || meta.submitError
          : '' || hint
      }
      className={className}
    >
      <Select
        {...rest}
        {...input}
        value={input.value === '' ? undefined : input.value}
        onChange={(val) => input.onChange(val)}
        placeholder={placeholder}
        disabled={disabled}
        size={size}
      />
    </Form.Item>
  );
}

export function CSelect({ name, ...rest }: CSelectProps) {
  const renderProps = useField<string>(name);
  return <RawSelect {...renderProps} {...rest} />;
}
