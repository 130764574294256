import { TeamOutlined, UserOutlined, ExportOutlined, BookOutlined, ShoppingOutlined, MonitorOutlined } from '@ant-design/icons';
import { Avatar, Layout, Menu, notification, Button, Popover, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../Auth';
import { fromReportGatewayError } from '../../Types/Error';
import './ContentWithMenu.scss';

const { Header, Content, Sider } = Layout;

interface IContentWithMenuProps {
  children: React.ReactNode;
}

export function ContentWithMenu({ children }: IContentWithMenuProps) {
  const unmounted = useRef(false);
  const { currentUser, signout } = useAuth();
  const location = useLocation();
  const selectedKey = `/${location.pathname.split('/')[1]}`;
  const history = useHistory();
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  async function logout() {
    setIsLogoutLoading(true);

    try {
      await signout();
    } catch (error: any) {
      if (!unmounted.current) {
        const convertedError = fromReportGatewayError(error);
        convertedError.forEach((error) => {
          notification.error({
            message: error.message,
            placement: 'bottomRight',
          });
        });
      }
    } finally {
      if (!unmounted.current) {
        setIsLogoutLoading(false);
      }
    }
  }

  function renderUserListMenuItem() {
    const usersMenuItem = currentUser?.canViewUsers() &&
      <Menu.Item key='/users' icon={<TeamOutlined />}>
        <Link to='/users'>Пользователи</Link>
      </Menu.Item>;
    const productRequestPackage = currentUser?.canViewProductRequestPackages() &&
      <Menu.Item key='/productRequestPackages' icon={<ShoppingOutlined />}>
        <Link to='/productRequestPackages'>Пакетные запросы продуктов</Link>
      </Menu.Item>;
    const requestsMenuItem = currentUser?.canViewRequests() &&
      <Menu.Item key='/requests' icon={<BookOutlined />}>
        <Link to='/requests'>Запросы</Link>
      </Menu.Item>;

    const permissionsMenuItem = currentUser?.canViewPermissions() &&
      <Menu.Item key='permissions' icon={<BookOutlined />}>
        <Link to='/permissions'>Разрешения</Link>
      </Menu.Item>;

    const certificatesMenuItem = currentUser?.canViewPermissions() &&
      <Menu.Item key='certificates' icon={<BookOutlined />}>
        <Link to='/certificates'>Сертификаты</Link>
      </Menu.Item>;

    const credentialsMenuItem = currentUser?.canViewPermissions() &&
      <Menu.Item key='credentials' icon={<BookOutlined />}>
        <Link to='/credentials'>Учетные данные</Link>
      </Menu.Item>;

    return [
      requestsMenuItem, productRequestPackage, usersMenuItem, permissionsMenuItem,
      certificatesMenuItem, credentialsMenuItem
    ];
  }

  function renderUserPopover() {
    return <Space direction='vertical'>
      <Button size='small' icon={<ExportOutlined />} onClick={logout} loading={isLogoutLoading}>
        Выход
      </Button>
    </Space>
  }

  return (
    <Layout className='layout'>
      <Sider className='layout__sider' width='300'>
        <div className='layout__logo'>
          <Link to='/'>Admin</Link>
        </div>
        <Menu theme='dark' mode='inline' selectedKeys={[selectedKey]}>
          {renderUserListMenuItem()}
        </Menu>
      </Sider>
      <Layout className='layout__with-sider'>
        <Header className='layout__header header'>
          <Menu mode='horizontal' className='header__leave'>
            <Menu.SubMenu
              key='SubMenu'
              title={
                <Popover placement='bottom' content={renderUserPopover()} trigger='click'>
                  <div className='header__icon-wrapper'>
                    <div className='u-mr-1 header__user'>
                      {currentUser?.userName}
                    </div>
                    <Avatar size={32} icon={<UserOutlined />} />
                  </div>
                </Popover>
              }
            >
              {/* <Menu.Item key='exit' onClick={logout} disabled={isLogoutLoading}>
                Выйти
              </Menu.Item> */}
            </Menu.SubMenu>
          </Menu>
        </Header>
        <Content className='layout__content'>{children}</Content>
      </Layout>
    </Layout>
  );
}
