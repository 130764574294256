import { DatePicker, Form } from 'antd';
import React from 'react';
import { useField } from 'react-final-form';
import { CDatePickerProps, RawDatePickerProps } from './types';
import moment from 'moment';

import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

const defaultFormat = 'DD.MM.yyyy HH:mm:ss';

export function RawDatePicker({
  input,
  meta,
  className,
  disabled,
  hint,
  label,
  showTime,
  format,
}: RawDatePickerProps) {
  return (
    <Form.Item
      label={label}
      validateStatus={
        meta.touched &&
        (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit))
          ? 'error'
          : ''
      }
      help={
        (meta.touched && meta.error) ||
        (meta.submitError && !meta.dirtySinceLastSubmit)
          ? meta.error || meta.submitError
          : '' || hint
      }
      className={className}
    >
      <DatePicker
        disabled={disabled}
        locale={locale}
        showTime={showTime}
        format={format || defaultFormat}
        value={input.value ? moment(new Date(input.value)) : null}
        onChange={(moment) => {
          input.onChange(moment?.toString() || '');
        }}
      />
    </Form.Item>
  );
}

export function CDatePicker({ name, ...rest }: CDatePickerProps) {
  const renderProps = useField<string>(name, {
    parse: rest.parse ? rest.parse : (v) => v,
  });
  return <RawDatePicker {...renderProps} {...rest} />;
}
