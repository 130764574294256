import { Alert, Divider, Form, Input, Modal, DatePicker, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Moment } from "moment";
import usePermissionsApi from "../../../services/reportGateway/api/permissionsApi";
import { ProductsHierarchySelect } from "../../../components/Products";
import { CredentialsSelect } from "../../../components/Credentials";
import { ClientsSelect } from "../../../components/Clients";
import { EndpointsSelect } from "../../Endpoints";

const { useForm } = Form;
const { RangePicker } = DatePicker;

interface IBackendForm {
  ClientId: string;
  Object: string;
  Period: Moment[];
  CredentialsId: string;
  Endpoints: string[];
}

const EmptyProviderAlert = (
  <Alert
    type='info'
    message='Необходимо выбрать продукт или поставщика'
  />
)

interface IPermissionAdditionNextProps {
  open: boolean;
  onOk(): void;
  onCancel(): void;
}

export function PermissionAddition(props: IPermissionAdditionNextProps) {
  const { open, onCancel, onOk } = props;

  const [form] = useForm<IBackendForm>();

  const permissionsApi = usePermissionsApi();

  const [isProcessing, setIsProcessing] = useState(false);
  const [object, setObject] = useState('');
  const [providerId, setProviderId] = useState('');
  const [credentialsId, setCredentialsId] = useState('');
  const [endpoints, setEndpoints] = useState<string[]>([]);

  useEffect(() => {
    const parts = object?.split('.') ?? [];
    setProviderId(parts.length > 0 ? parts[0] : '');
  }, [object])

  useEffect(() => {
    setCredentialsId('');
    setEndpoints([]);
  }, [providerId])

  useEffect(() => {
    console.log(credentialsId);
    form.setFieldValue('CredentialsId', credentialsId);
  }, [credentialsId])

  useEffect(() => {
    form.setFieldValue('Endpoints', endpoints);
  }, [endpoints])

  async function handleOnFinish(values: IBackendForm) {
    let successful = true;
    const { ClientId, Object, Period, CredentialsId, Endpoints } = values;

    setIsProcessing(true);

    try {
      const parts = Object.split('.');
      const providerId = parts.length > 0 ? parts[0] : null;
      const productId = parts.length > 1 ? parts[1] : null;

      await permissionsApi.create(ClientId, productId ? null : providerId, productId,
        CredentialsId, Period[0], Period.length > 1 ? Period[1] : null, Endpoints);
    } catch (error) {
      console.error(error);
      successful = false;
    } finally {
      setIsProcessing(false);
    }

    if (successful) {
      onOk();
      form.resetFields();
    } else {
      notification.error({
        message: 'Не удалось добавить разрешение'
      })
    }
  }

  return (
    <Modal
      title='Добавление разрешения'
      closable={false}
      maskClosable={false}
      width={800}
      open={open}
      onOk={form.submit}
      okButtonProps={{ loading: isProcessing }}
      okText='Добавить'
      onCancel={onCancel}
      cancelButtonProps={{ disabled: isProcessing }}
      cancelText='Отмена'
    >
      <Form
        form={form}
        onFinish={handleOnFinish}
        labelCol={{ span: 6 }}
        initialValues={{
          ClientId: '',
          Object: '',
          Period: [],
          CredentialsId: '',
        }}
      >
        <Form.Item
          name='ClientId'
          label='Клиент'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <ClientsSelect />
        </Form.Item>

        <Form.Item
          name='Object'
          label='Поставщик / Продукт'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <ProductsHierarchySelect
            value={object}
            onChange={setObject}
          />
        </Form.Item>

        <Form.Item
          name='Period'
          label='Период'
          rules={[
            { required: true, message: 'Необходимо указать дату начала' },
          ]}
        >
          <RangePicker
            showTime
            allowEmpty={[false, true]}
          />
        </Form.Item>

        <Divider orientation='left'>Учетные данные</Divider>
        <Form.Item
          name='CredentialsId'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}

        >
          {providerId ? (
            <CredentialsSelect
              providerId={providerId}
              value={credentialsId}
              onChange={setCredentialsId}
            />
          ) : (
            EmptyProviderAlert
          )}
        </Form.Item>

        <Divider orientation='left'>Эндпоинты</Divider>
        <Form.Item name='Endpoints' >
          {providerId ? (
            <EndpointsSelect
              providerId={providerId}
              values={endpoints}
              onChange={setEndpoints}
            />
          ) : (
            EmptyProviderAlert
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}