import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './style/App.scss';
import { useAuth } from './Auth';
import { Routes } from './components/Routes';
import { Wrapper, WrapperState } from './components/Wrapper';
import { Error, fromReportGatewayError } from './Types/Error';

const queryClient = new QueryClient();

const App = () => {
  const unmounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initErrors, setInitErrors] = useState<Error[]>([]);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const { refresh } = useAuth();

  useEffect(() => {
    return function cleanup() {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        await refresh();
      } catch (error: any) {
        if (!unmounted.current) {
          setInitErrors(fromReportGatewayError(error));
        }
      } finally {
        if (!unmounted.current) {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [refresh]);

  useEffect(() => {
    if (isLoading) {
      setWrapperState('loading');
    } else if (initErrors.length > 0) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [isLoading, initErrors]);

  // <Wrapper state={wrapperState} errors={initErrors}> - Надо правильно подумтаь, как лучше реализовать. Мб через хук и дергать хук
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Wrapper state={wrapperState} errors={initErrors} noLoader>
          <Routes />
        </Wrapper>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
