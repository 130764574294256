import httpClient, { HttpMethod, ContentType } from "../httpClient";
import reportGatewayErrorsParser from "./reportGatewayErrorsParser";
import { ReportGatewayError } from "../../Types/ReportGatewayError";
import { useAuth } from '../../Auth';

export const makeReportGatewayRequest = async <TResponse, TResult>(
    url: string,
    method: HttpMethod,
    transformResponse: (response: TResponse) => TResult,
    requestData: any,
    handleError: (error: ReportGatewayError) => void,
    contentType?: ContentType
): Promise<TResult> => {
    try {
        const result = await httpClient.makeRequest(url, method, transformResponse, requestData, contentType ?? "json");
        return result;
    }
    catch (httpError: any) {
        const error = reportGatewayErrorsParser.parse(httpError);
        handleError(error);
        throw error;
    }
}

interface IMakeReportGatewayRequest {
    makeReportGatewayRequest<TResponse, TResult>(
        url: string,
        method: HttpMethod,
        transformResponse: (response: TResponse) => TResult,
        requestData: any,
        contentType?: ContentType
    ): Promise<TResult>;
}

export const useMakeReportGatewayRequest = (): IMakeReportGatewayRequest => {
    const { reset } = useAuth();

    const makeRequest = async <TResponse, TResult>(
        url: string,
        method: HttpMethod,
        transformResponse: (response: TResponse) => TResult,
        requestData: any,
        contentType?: ContentType
    ): Promise<TResult> =>
        makeReportGatewayRequest(
            url,
            method,
            transformResponse,
            requestData,
            handleError,
            contentType
        );

    const handleError = (error: ReportGatewayError): void => {
        if (error.statusCode === 401) {
            reset();
        }
    }

    return {
        makeReportGatewayRequest: makeRequest
    }
}