import { Button, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { CredentialsTable } from './CredentialsTable';
import { useAuth } from '../../../Auth';
import { CredentialsAddition } from '../../../components/Credentials';

export function CredentialsList() {
  const { currentUser } = useAuth();
  const canEdit = useMemo(() => currentUser?.canEditPermissions() ?? false, [currentUser])

  const [addition, setAddition] = useState(false);

  const handleOnOpenAddition = useCallback(() => {
    setAddition(true);
  }, [setAddition])

  const handleOnCancelAddition = useCallback(() => {
    setAddition(false);
  }, [setAddition])

  const handleOnSuccessfulAddition = useCallback(() => {
    setAddition(false);
  }, [setAddition])

  return (
    <>
      <PageHeader
        title='Учетные данные'
        extra={(
          <Button
            disabled={!canEdit || addition}
            ghost
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleOnOpenAddition}
          >
            Добавить
          </Button>
        )}
      />
      <CredentialsTable />
      <CredentialsAddition
        open={addition}
        onCancel={handleOnCancelAddition}
        onOk={handleOnSuccessfulAddition}
      />
    </>
  )
}