import { Alert, Avatar, Button, Col, Empty, List, Modal, Row, Tag, Tooltip, Typography, notification } from "antd";
import { EllipsisOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useEndpointsApi from "../../../services/reportGateway/api/endpointApi";
import IEndpointReferencesItem from "../../../Types/backend/endpoints/EndpointReferencesItem";
import { EndpointSelectList } from "./EndpointSelectList";

const { Text } = Typography;

interface IEndpointsSelectProps {
  providerId: string;
  values: string[];
  onChange?(values: string[]): void;
}

export function EndpointsSelect(props: IEndpointsSelectProps) {
  const { providerId, onChange } = props;

  const endpointsApi = useEndpointsApi();

  const [selected, setSelected] = useState<string[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<IEndpointReferencesItem[]>([]);

  const { refetch, data: endpoints, error, isLoading, isFetching } = useQuery(
    ['endpoints', 'select', providerId],
    fetchData,
    { retry: 3 }
  )

  function fetchData() {
    return endpointsApi.references(providerId);
  }

  useEffect(() => {
    if (onChange) onChange(values);
  }, [values])

  useEffect(() => {
    setItems(endpoints?.filter(p => values.includes(p.id)) ?? []);
  }, [values, endpoints])

  function handleOnOpen() {
    setOpen(true);
  }

  function handleOnCancel() {
    setOpen(false);
  }

  function handleOnOk() {
    setValues(selected);
    setOpen(false);
  }

  function handleOnRefetch(): Promise<any> {
    return refetch();
  }

  return (
    <>
      <Row gutter={8} align={values.length > 0 ? 'top' : 'middle'} justify='center'>
        <Col span={21}>
          {values.length > 0 ? (
            <List
              size='small'
              dataSource={items}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={`${item.name}`}
                    description={`Url: ${item.url}; Host: ${item.hostHeader ? item.hostHeader : '-'}`}
                  />
                  <Tag>{item.purpose}</Tag>
                </List.Item>
              )}
            />
          ) : (
            <Alert message='Эндпоинты не выбраны' />
          )}
        </Col>
        <Col span={3}>
          <Tooltip title='Выбрать'>
            <Button
              disabled={providerId === ''}
              icon={<EllipsisOutlined />}
              onClick={handleOnOpen}
            />
          </Tooltip>
        </Col>
      </Row>
      <Modal
        width={700}
        title='Выбор эндпоинтов'
        open={open}
        closable={false}
        maskClosable={false}
        onOk={handleOnOk}
        okText={`Выбрать (${selected.length})`}
        onCancel={handleOnCancel}
        cancelText='Отмена'
      >
        {endpoints === undefined ? (
          <Empty />
        ) : (
          <EndpointSelectList
            providerId={providerId}
            dataSource={endpoints}
            values={values}
            onChange={setSelected}
            refetch={handleOnRefetch}
          />
        )}
      </Modal>
    </>
  )
}