import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { LockOutlined, UnlockOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons';
import { IAuthUser } from '../../Auth';
import {
  IUsersListItem
} from '../../services/reportGateway/api/usersApi';
import {
  IRolesListItem
} from '../../services/reportGateway/api/rolesApi';
import { UserListUserNameFilter } from './UserListUserNameFilter';
import { UserListRoleFilter } from './UserListRoleFilter';

type TColumnProps = ColumnProps<IUsersListItem>;

const FilterIcon = (props: any) => <SearchOutlined style={{ color: props.filtered ? '#1890ff' : undefined }} />;

export const userListColumns = (
  currentUser: IAuthUser,
  redirectOnEditUser: (id: string) => void,
  redirectOnLockUser: (id: string) => void,
  roleFilterConfig: { roles: IRolesListItem[] }
): TColumnProps[] => [
    {
      title: '№',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
      width: 50,
    },
    {
      title: 'Имя пользователя',
      dataIndex: 'userName',
      key: 'userName',
      width: '35%',
      filterDropdown: (props: any) => <UserListUserNameFilter
        {...props}
      />,
      filterIcon: (props: any) => <FilterIcon {...props} />,
      sorter: {
        multiple: 1
      }
    },
    {
      title: 'Роль',
      dataIndex: 'roleName',
      key: 'roleName',
      filterDropdown: (props: any) => <UserListRoleFilter
        {...props}
        roles={roleFilterConfig.roles}
      />,
      filterIcon: (props: any) => <FilterIcon {...props} />,
      sorter: {
        multiple: 2
      }
    },
    {
      title: 'Блокировка',
      dataIndex: 'isLockedOut',
      key: 'isLockedOut',
      render: (text, record) => (record.isLockedOut ? 'Есть' : 'Нет'),
    },

    {
      title: 'Действия',
      key: 'action',
      width: 220,
      render: (text, record) => (
        <Button
          onClick={() => redirectOnEditUser(record.id)}
          disabled={!currentUser?.canEditUsers()}
          block
          type="primary"
          icon={<FormOutlined />}
        >
          Редактировать
        </Button>
      ),
    },
    {
      width: 220,
      render: (text, record) => (
        <Button
          onClick={() => redirectOnLockUser(record.id)}
          disabled={!currentUser?.canEditUsers()}
          block
          icon={record.isLockedOut ? <UnlockOutlined /> : <LockOutlined />}
        >
          {record.isLockedOut ? 'Разблокировать' : 'Заблокировать'}
        </Button>
      ),
    },
  ];
