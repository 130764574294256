import React, { useEffect, useState } from 'react';
import { Space, Alert } from 'antd';
import { Errors, noField } from '../../Types/Error';

interface ErrorsListProps {
  errors: Errors
}

export const ErrorsList = (props: ErrorsListProps) => {
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
      if (props.errors && props.errors[noField]) {
          setErrors(props.errors[noField]);
      }
      else {
          setErrors([]);
      }
  }, [ props.errors ])

  function renderAlerts() {
      return <Space direction="vertical">
          {
              errors.map((value, index) => <Alert key={`errorAlert_${index}`} type="error" message={value} showIcon />)
          }
      </Space>
  }

  return errors.length > 0 ? renderAlerts() : null;
};