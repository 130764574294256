import { Select, SelectProps } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useProvidersApi from "../../services/reportGateway/api/providersApi";
import IProviderReferencesItem from "../../Types/backend/providers/ProviderReferencesItem";
import { DefaultOptionType } from "antd/lib/select";

interface IProviderSelectProps {
  value?: string;
  onChange?(value: string): void;
}

export function ProviderSelect(props: IProviderSelectProps) {
  const { onChange } = props;

  const providersApi = useProvidersApi();

  const [value, setValue] = useState(props.value ?? '');

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value])

  const { data: providers, error, isLoading, isFetching } = useQuery(
    ['providers', 'references'],
    fetchData,
    { retry: 3 }
  )

  async function fetchData(): Promise<DefaultOptionType[]> {
    const response = await providersApi.references();

    return response.map<DefaultOptionType>(value => ({
      label: value.name,
      value: value.id
    }))
  }

  return (
    <Select
      allowClear
      options={providers}
      loading={isLoading || isFetching}
      value={value}
      onChange={setValue}
    />
  )
}