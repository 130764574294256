import { Button, Row, Col, Radio, Space, Empty, RadioChangeEvent, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useCredentialsApi from "../../../services/reportGateway/api/credentialsApi";
import ICredentialsReferencesItem from "../../../Types/backend/credentials/CredentialsReferencesItem";
import { CredentialsInfo } from "../Info/CredentialsInfo";
import { CredentialsAddition } from "../Addition/CredentialsAddition";

interface ICredentialsSelectListProps {
  value: string;
  providerId: string;
  onChange?(value: string): void;
}

export function CredentialsSelectList(props: ICredentialsSelectListProps) {
  const { providerId, onChange } = props;

  const credentialsApi = useCredentialsApi();

  const [value, setValue] = useState<string>(props.value);
  const [additionOpen, setAdditionOpen] = useState(false);

  useEffect(() => {
    console.log(`select list - ${value}`);
    if (onChange) onChange(value);
  }, [value])

  const { refetch, data: credentials, error, isLoading, isFetching } = useQuery(
    ['credentials', 'select', providerId],
    fetchData,
    { enabled: providerId !== '', retry: 3 }
  )

  function fetchData(): Promise<ICredentialsReferencesItem[]> {
    return credentialsApi.references(providerId);
  }

  function handleOnChange(e: RadioChangeEvent) {
    setValue(e.target.value);
  }

  function handleOnAdditioOpen() {
    setAdditionOpen(true);
  }

  function handleOnAdditionCancel() {
    setAdditionOpen(false);
  }

  function handleOnAdditionOk() {
    setAdditionOpen(false);
    refetch();
    notification.success({
      message: 'Учетные данные добавлены'
    });
  }

  return (
    <>
      <Row gutter={8}>
        <Col flex={1} />
        <Col>
          <Button
            onClick={handleOnAdditioOpen}
          >
            Добавить
          </Button>
        </Col>
      </Row>
      <Radio.Group
        style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem', maxHeight: '200px', overflow: 'auto' }}
        value={value}
        onChange={handleOnChange}
      >
        <Space direction='vertical'>
          {credentials?.map(value => (
            <Radio key={value.id} value={value.id}>
              {value.name}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      {value ? (
        <CredentialsInfo
          id={value}
          hideId
        />
      ) : (
        <Empty />
      )}
      <CredentialsAddition
        providerId={providerId}
        open={additionOpen}
        onOk={handleOnAdditionOk}
        onCancel={handleOnAdditionCancel}
      />
    </>
  )
}