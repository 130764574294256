import { Alert, Descriptions, Empty, Skeleton, Typography } from "antd";
import React, { useContext, useMemo } from "react";
import { CertificateCardContext } from "./CertificateCard";
import { useQuery } from "react-query";
import useCertificatesApi from "../../../services/reportGateway/api/certificatesApi";
import { useAuth } from "../../../Auth";
import { Wrapper } from "../../../components/Wrapper";
import { fromReportGatewayError } from "../../../Types/Error";
import moment from "moment";

const { Text } = Typography;

export function CertificateCardDescriptions() {
  const { id, updated } = useContext(CertificateCardContext)

  const { currentUser } = useAuth();
  const canView = useMemo(() => currentUser?.canViewPermissions() ?? false, [currentUser])

  const api = useCertificatesApi();

  const { data, error, isLoading } = useQuery(
    ['certificates', 'card', 'main', id, canView, updated],
    () => api.cardMain(id),
    { retry: 3, enabled: canView }
  )

  const initErrors = useMemo(() => error ? fromReportGatewayError(error as any) : [], [error])

  const isExpired = useMemo(() => {
    return data === undefined ? false : data.endDate <= moment()
  }, [data])

  return isLoading ? (
    <Skeleton
      active
      paragraph={{
      }}
    />
  ) : (
    <Wrapper
      state={error ? 'hasErrors' : null}
      errors={initErrors}
    >
      {data === undefined ? <Empty /> : (
        <>
          {isExpired ? (
            <Alert
              type='error'
              message='Срок действия сертификата истек'
            />
          ) : null}
          <Descriptions
            style={{ paddingTop: isExpired ? '1rem' : undefined}}
            bordered
            column={1}
          >
            <Descriptions.Item label='Описание'>
              {data.description}
            </Descriptions.Item>
            <Descriptions.Item label='Организация'>
              {data.organizationName ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label='Отпечаток'>
              <Text copyable>{data.thumbprint}</Text>
            </Descriptions.Item>
            <Descriptions.Item label='Серийный номер'>
              {data.serialNumber === null ? '-' : (
                <Text copyable>{data.serialNumber}</Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item label='Издатель'>
              {data.issuer ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label='Владелец'>
              {data.subject ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label='Период'>
              {`${data.startDate.format('DD.MM.YYYY')} - ${data.endDate.format('DD.MM.YYYY')}`}
            </Descriptions.Item>

          </Descriptions>
        </>
      )}
    </Wrapper>
  )
}