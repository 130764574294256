export default function certificateFieldParser(value: string, ...params: string[]) {
  const result: string[] = [];
  const arr = value.split(', ') ?? [];

  for (const p of params) {
    const index = arr.findIndex(v => v.indexOf(p) >= 0);
    if (index >= 0) result.push(arr[index].replace(`${p}=`, ''));
  }

  return result.length > 0 ? result.join(', ') : '-'
}