import { PageHeader, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../Auth';
import {
  useRequestsApi,
  IRequestListItem,
  IRequestStateViewModel
} from '../../services/reportGateway/api/requestsApi';
import {
  useProductsApi,
  IProductSelectViewModel
} from '../../services/reportGateway/api/productsApi';
import {
  useClientsApi,
  IClientListItem
} from '../../services/reportGateway/api/clientsApi';
import { FilterRow } from './FilterRow';
import { Error, fromReportGatewayError } from '../../Types/Error';
import { Wrapper, WrapperState } from '../Wrapper';

import { requestListColumns } from './requestListColumns';
import {
  useRequestListConfig,
  FilterValues,
  defaultPage,
  defaultPageSize
} from './RequestListConfig';

interface IRequestTableItem extends IRequestListItem {
  key: string;
}

export const RequestList = () => {
  const unmounted = useRef(false);
  const requestsApi = useRequestsApi();
  const productsApi = useProductsApi();
  const clientsApi = useClientsApi();
  const {requestData, setFilter, setPaging} = useRequestListConfig();
  const [isReferencesLoading, setIsReferencesLoading] = useState(false);
  const [isRequestsLoading, setIsRequestsLoading] = useState(false);
  const [initErrors, setInitErrors] = useState<Error[]>([]);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [requests, setRequests] = useState<IRequestTableItem[]>([]);
  const {currentUser} = useAuth();
  const history = useHistory();
  const [allProducts, setAllProducts] = useState<IProductSelectViewModel[]>([]);
  const [allClients, setAllClients] = useState<IClientListItem[]>([]);
  const [allRequestStates, setAllRequestStates] = useState<IRequestStateViewModel[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  async function getReferences() {
    setInitErrors([]);
    setIsReferencesLoading(true);
    try {
      const receivedClients = await clientsApi.getClients();
      const receivedProducts = await productsApi.getProducts();
      const receivedRequestStates = await requestsApi.getRequestStates();
  
      if (!unmounted.current) {
        setAllClients(receivedClients);
        setAllProducts(receivedProducts);
        setAllRequestStates(receivedRequestStates);  
      }
    }
    catch (error: any) {
      if (!unmounted.current) {
        setInitErrors(fromReportGatewayError(error));
      }
    }
    finally {
      if (!unmounted.current) {
        setIsReferencesLoading(false);
      }
    }
  }

  async function getReportRequests() {
    try {
      setInitErrors([]);
      setIsRequestsLoading(true);

      const receivedRequests = await requestsApi.getRequests(
        requestData.paging.page,
        requestData.paging.pageSize,
        requestData.filter.products,
        requestData.filter.client,   
        requestData.filter.firstName,
        requestData.filter.lastName,
        requestData.filter.middleName,
        requestData.filter.passportNumber,
        requestData.filter.passportSeries,
        requestData.filter.vin,
        requestData.filter.companyName,
        requestData.filter.ogrn,
        requestData.filter.inn,
        requestData.filter.subjectFilterType,
        requestData.filter.dateFrom,
        requestData.filter.dateTo,
        requestData.filter.states);

      if (!unmounted.current) { // Откуда везде какие-то условия с рефами. Их быть не должно почти нигде
        const convertedRequests: IRequestTableItem[] = receivedRequests.list.map(
          (r) => ({
            ...r,
            key: r.id,
          })
        );
        setTotalRows(receivedRequests.totalRowCount);
        setRequests(convertedRequests);
      }
    } catch (error: any) {
      if (!unmounted.current) {
        setInitErrors(fromReportGatewayError(error));
      }
    } finally {
      if (!unmounted.current) {
        setIsRequestsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (currentUser?.canViewRequests()) {
      getReferences();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.canViewRequests()) {
      getReportRequests();
    }
  }, [currentUser, requestData]);

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    } else if (!currentUser.canViewRequests()) {
      setWrapperState('accessDenied');
    } else if (initErrors && initErrors.length > 0) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, initErrors]);

  function redirectOnViewRequest(id: string) {
    history.push(`/requests/${id}`);
  }

  function onFilterSearch(values: FilterValues) {
    setFilter(values);
  }

  function handleOnChange(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  const renderTable = () => {
    if (!currentUser) {
      return null;
    }
    return (
      <Table
        className="u-mt-4"
        columns={requestListColumns(redirectOnViewRequest)}
        loading={isRequestsLoading || isReferencesLoading}
        dataSource={requests}
        scroll={{ x: 900 }}
        onChange={handleOnChange}
        pagination={{
          current: requestData.paging.page,
          position: ['bottomCenter'],
          pageSize: requestData.paging.pageSize,
          total: totalRows,
          showSizeChanger: false
        }}
      />
    );
  };

  return (
    <Wrapper state={wrapperState} errors={initErrors}>
      <PageHeader title="Запросы отчетов" />
      <FilterRow
        clientsOptions={allClients}
        productsOptions={allProducts}
        statesOptions={allRequestStates}
        onSearchRequest={onFilterSearch}
        loading={isRequestsLoading || isReferencesLoading}
      />
      {renderTable()}
    </Wrapper>
  );
};