import { Select } from "antd";
import { DefaultOptionType, SelectProps } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useEndpointsApi from "../../../services/reportGateway/api/endpointApi";

export function EndpointPurposeSelect(props: SelectProps) {
  const endpointsApi = useEndpointsApi();

  const { data: purposes, error, isLoading, isFetching } = useQuery(
    ['endpoints', 'purposes', 'references'],
    fetchData,
    { retry: 3 }
  )

  async function fetchData(): Promise<DefaultOptionType[]> {
    const response = await endpointsApi.purposeReferences();
    
    return response.map<DefaultOptionType>(value => ({
      label: value.name,
      value: value.value
    }))
  }

  return (
    <Select
      {...props}
      allowClear
      options={purposes}
      loading={isLoading || isFetching}
    />
  )
}