import { Descriptions, Empty, Badge, Space, Typography, BadgeProps } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useAuth } from "../../../Auth";
import useCredentialsApi from "../../../services/reportGateway/api/credentialsApi";
import { Wrapper, WrapperState } from "../../../components/Wrapper";
import ICredentialsInfo from "../../../Types/backend/credentials/CredentialsInfo";
import { fromReportGatewayError, Error } from "../../../Types/Error";
import { ObjectVisualizer } from "../../ObjectVisualizer";

const { Text, Paragraph } = Typography;

interface IUserPermissions {
  canView: boolean;
}

interface ICredentialsInfoProps {
  id: string;
  hideId?: boolean;
  hideDescription?: boolean;
  hideStatus?: boolean;
}

type Status = 'active' | 'inactive';

const map: Record<Status, BadgeProps> = {
  active: { text: 'Активны', status: 'processing' },
  inactive: { text: 'Не активны', status: 'error' }
}

export function CredentialsInfo(props: ICredentialsInfoProps) {
  const { id, hideId, hideDescription, hideStatus } = props;

  const { currentUser } = useAuth();
  const canView = useMemo(() => {
    return currentUser?.canViewPermissions() ?? false;
  }, [currentUser])

  const api = useCredentialsApi();

  const { refetch, data: credentialsInfo, error, isLoading, isFetching } = useQuery(
    ['credentials', 'info', id, canView],
    fetchData,
    { enabled: id !== null && canView }
  )

  async function fetchData(): Promise<ICredentialsInfo | undefined> {
    if (id === null || !canView) return undefined;
    return await api.info(id);
  }

  const initErrors = useMemo(() => error ? fromReportGatewayError(error as any) : [], [error])

  const wrapperState = useMemo<WrapperState | null>(() => {
    if (!canView) return 'accessDenied';
    else if (isLoading) return 'loading';
    else if (initErrors && initErrors.length > 0) return 'hasErrors';
    else return null;
  }, [canView, isLoading, initErrors])

  function handleOnRefresh() {
    refetch();
  }

  function renderStatus() {
    if (credentialsInfo === undefined) return '-';

    const status: Status = credentialsInfo.disabled ? 'inactive' : 'active';

    return (
      <Badge {...map[status]} />
    )
  }

  return (
    <Wrapper state={wrapperState} errors={initErrors}>
      {credentialsInfo === undefined ? (
        <Empty />
      ) : (
        <Space direction='vertical' style={{ width: '100%' }}>
          <Descriptions bordered column={1} size='small'>
            {!hideId && (
              <Descriptions.Item label='Id'>
                <Text copyable>{id}</Text>
              </Descriptions.Item>
            )}
            {!hideDescription && (
              <Descriptions.Item label='Описание'>
                {credentialsInfo.description}
              </Descriptions.Item>
            )}
            {!hideStatus && (
              <Descriptions.Item label='Статус'>
                {renderStatus()}
              </Descriptions.Item>
            )}
            <Descriptions.Item label='Организация'>
              {credentialsInfo.organizationName}
            </Descriptions.Item>
            {credentialsInfo.signCertificateId && (
              <Descriptions.Item label='Подписание'>
                <Link to={`/certificates/${credentialsInfo.signCertificateId}`}>
                  <Text copyable>
                    {credentialsInfo.signCertificateThumbprint}
                  </Text>
                </Link>
              </Descriptions.Item>
            )}
            {credentialsInfo.encryptCertificateId && (
              <Descriptions.Item label='Шифрование'>
                <Link to={`/certificates/${credentialsInfo.encryptCertificateId}`}>
                  <Text copyable>
                    {credentialsInfo.encryptCertificateThumbprint}
                  </Text>
                </Link>
              </Descriptions.Item>
            )}
          </Descriptions>
          <ObjectVisualizer
            objStr={credentialsInfo.value}
          />
        </Space>
      )}
    </Wrapper>
  )
}