import { Descriptions, Empty, Skeleton, Typography } from "antd";
import React from "react";
import useCertificatesApi from "../../../services/reportGateway/api/certificatesApi";
import ICertificateInfo from "../../../Types/backend/certificates/CertificateInfo";
import { useQuery } from "react-query";

const { Text } = Typography;

interface ICertificateInfoProps {
  id: string;
  hideId?: boolean;
  hideDescription?: boolean;
}

export function CertificateInfo(props: ICertificateInfoProps) {
  const { id, hideId, hideDescription } = props;

  const api = useCertificatesApi();

  const { refetch, data, error, isLoading, isFetching } = useQuery(
    ['certificates', id, 'info'],
    fetchData,
    { retry: 3 }
  )

  function fetchData(): Promise<ICertificateInfo> {
    return api.info(id);
  }

  return data !== undefined ? (
    <Descriptions
      size='small'
      column={1}
      bordered
    >
      {!hideId && (
        <Descriptions.Item label='Id'>
          <Text copyable>{id}</Text>
        </Descriptions.Item>
      )}
      {!hideDescription && (
        <Descriptions.Item label='Описание'>
          {data.description}
        </Descriptions.Item>
      )}
      <Descriptions.Item label='Организация'>
        {data.organizationName ?? '-'}
      </Descriptions.Item>
      <Descriptions.Item label='Отпечаток'>
        <Text copyable>{data.thumbprint}</Text>
      </Descriptions.Item>
      <Descriptions.Item label='Серийный номер'>
        <Text copyable={data.serialNumber !== null}>
          {data.serialNumber ?? '-'}
        </Text>
      </Descriptions.Item>
      <Descriptions.Item label='Издатель'>
        <Text>{data.issuer ?? '-'}</Text>
      </Descriptions.Item>
      <Descriptions.Item label='Владелец'>
        <Text>{data.subject ?? '-'}</Text>
      </Descriptions.Item>
      <Descriptions.Item label='Период'>
        {`${data.startDate.format('DD.MM.YYYY')} - ${data.endDate.format('DD.MM.YYYY')}`}
      </Descriptions.Item>
    </Descriptions>
  ) : isLoading ? (
    <Skeleton active />
  ) : (
    <Empty />
  )
}