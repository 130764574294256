import { useState } from 'react';

export interface FilterValues {
}

export const emptyFilter: FilterValues = {}

export const defaultPage = 1;
export const defaultPageSize = 20;

interface Paging {
  page: number;
  pageSize: number;
}

const defaultPaging: Paging = {
  page: defaultPage,
  pageSize: defaultPageSize
}

interface RequestData {
  filter: FilterValues;
  paging: Paging;
}

type Config = {
  isFilterOpen: boolean;
  openFilter: () => void;
  closeFilter: () => void;
  requestData: RequestData;
  setFilter: (value: FilterValues) => void;
  resetFilter: () => void;
  setPaging: (page: number, pageSize: number) => void;
}

export function useTableConfig(): Config {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState<FilterValues>(emptyFilter);
  const [paging, setPaging] = useState<Paging>(defaultPaging);

  return {
    isFilterOpen,
    openFilter: () => setIsFilterOpen(true),
    closeFilter: () => setIsFilterOpen(false),
    requestData: {
      filter: filter,
      paging: paging
    },
    setFilter: (value: FilterValues) => setFilter(value),
    resetFilter: () => setFilter(emptyFilter),
    setPaging: (page: number, pageSize: number) => setPaging({
      page: page,
      pageSize: pageSize
    })
  }
}