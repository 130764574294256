export const getCookie = (name: string): string => {
    const normalizedName = name.trim().toLowerCase();
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
        const pair = cookies[i].split('=');
        const key = decodeURIComponent(pair[0].trim().toLowerCase());
        if (key === normalizedName) {
            const value = pair.length > 1 ? pair[1] : '';
            return decodeURIComponent(value);
        }
    }

    return '';
}