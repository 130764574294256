import React from "react";
import { Col, Row } from "antd";
import { CertificateCardDescriptions } from "./CertificateCardDescriptions";
import { CertificateCardFilesList } from "./CertificateCardFilesList";


export function CertificateCardMain() {
  return (
    <Row>
      <Col span={10}>
        <CertificateCardDescriptions />
      </Col>
      <Col span={10} offset={2}>
        <CertificateCardFilesList />
      </Col>
    </Row>
  )
}