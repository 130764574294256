import create from 'zustand';

export type SubjectFilterType = 'FL' | 'JR' | 'cars';

export interface FilterValues {
  client: string | undefined;
  products: string[];
  states: number[];
  dateFrom: string;
  dateTo: string;
  subjectFilterType: SubjectFilterType;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  passportSeries?: string;
  passportNumber?: string;
  companyName?: string;
  inn?: string;
  ogrn?: string;
  vin?: string;
}

export const emptyFilter: FilterValues = {
  client: undefined,
  products: [],
  states: [],
  dateFrom: '',
  dateTo: '',
  subjectFilterType: 'FL',
  firstName: undefined,
  lastName: undefined,
  middleName: undefined,
  passportSeries: undefined,
  passportNumber: undefined,
  companyName: undefined,
  inn: undefined,
  ogrn: undefined,
  vin: undefined
}

export const defaultPage = 1;
export const defaultPageSize = 20;

interface Paging {
  page: number;
  pageSize: number;
}

const defaultPaging: Paging = {
  page: defaultPage,
  pageSize: defaultPageSize
}

interface RequestData {
  filter: FilterValues;
  paging: Paging;
}

type Config = {
  isFilterOpen: boolean;
  openFilter: () => void;
  closeFilter: () => void;
  requestData: RequestData;
  setFilter: (value: FilterValues) => void;
  resetFilter: () => void;
  setPaging: (page: number, pageSize: number) => void;
}

export const useRequestListConfig = create<Config>((set, get) => ({
  isFilterOpen: false,
  openFilter: () => {
    set({ isFilterOpen: true });
  },
  closeFilter: () => {
    set({ isFilterOpen: false });
  },
  requestData: {
    filter: emptyFilter,
    paging: defaultPaging
  },
  setFilter: (value: FilterValues) => {
    set({
      requestData: {
        filter: value,
        paging: defaultPaging
      }
    });
  },
  resetFilter: () => {
    set({
      requestData: {
        filter: emptyFilter,
        paging: defaultPaging
      }
    });
  },
  setPaging: (page: number, pageSize: number) => {
    const currentConfig = get();
    set({
      requestData: {
        ...currentConfig.requestData,
        paging: {
          page: page,
          pageSize: pageSize
        }
      }
    });
  }
}));