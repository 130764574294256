import { Button, Col, Row, Table, TablePaginationConfig, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import React, { Key, createContext, useCallback, useMemo, useState } from 'react';
import { IPagedList } from '../../../Types/PagedList';
import { useAuth } from '../../../Auth';
import { useQuery } from 'react-query';
import { Wrapper } from '../../../components/Wrapper';
import { fromReportGatewayError } from '../../../Types/Error';
import ICredentialsListItem from '../../../Types/backend/credentials/CredentialsListItem';
import { defaultPage, defaultPageSize, useCredentialsTableConfig } from './CredentialsTableConfig';
import useCredentialsApi from '../../../services/reportGateway/api/credentialsApi';
import { getColumns } from './CredentialsTableColumns';
import { CredentialsValue } from './CredentialsValue';
import moment, { Moment } from 'moment';
import { CredentialsLimitation } from '../Modals/CredentialsLimitation';

interface ICredentialsTableItem extends ICredentialsListItem {
  key: string;
}

interface ICredentialsTableContext {
  updated: Moment;
}

export const CredentialsTableContext = createContext<ICredentialsTableContext>({} as ICredentialsTableContext);

export function CredentialsTable() {
  const { currentUser } = useAuth();
  const canView = useMemo(() => currentUser?.canViewPermissions() ?? false, [currentUser])
  const canEdit = useMemo(() => currentUser?.canEditPermissions() ?? false, [currentUser])

  const [updated, setUpdated] = useState(moment());

  const api = useCredentialsApi();
  const { requestData, setPaging } = useCredentialsTableConfig();
  const { filter, paging } = requestData;

  const { refetch, data, error, isLoading, isFetching } = useQuery(
    ['credentials', 'list', canView, filter, paging, updated],
    fetchData,
    { enabled: canView }
  )

  async function fetchData(): Promise<IPagedList<ICredentialsTableItem> | undefined> {
    if (!canView) return undefined;

    const response = await api.list(requestData.paging.page, requestData.paging.pageSize);

    return {
      ...response,
      list: response.list.map(value => ({
        ...value,
        key: value.id
      }))
    }
  }

  const initErrors = useMemo(() => error ? fromReportGatewayError(error as any) : [], [error])

  function handleOnChange(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  function handleOnRefresh() {
    setUpdated(moment());
  }

  const [expandedKeys, setExpandedKeys] = useState<readonly Key[]>([]);

  const [limit, setLimit] = useState<ICredentialsListItem | null>(null);

  const columns = getColumns({
    canEdit: canEdit,
    limit: limit,
    onLimit: setLimit
  });

  const handleOnCancelLimit = useCallback(() => {
    setLimit(null);
  }, [setLimit])

  const handleOnSuccessfulLimit = useCallback(() => {
    setLimit(null);
    refetch();
  }, [setLimit])

  return (
    <>
      <CredentialsTableContext.Provider value={{ updated: updated }}>
        <Row gutter={8} style={{ marginTop: '1rem' }}>
          <Col flex={1} />
          <Col>
            <Tooltip title='Обновить'>
              <Button
                shape='circle'
                icon={<ReloadOutlined />}
                loading={isLoading || isFetching}
                onClick={handleOnRefresh}
              />
            </Tooltip>
          </Col>
        </Row>
        <Wrapper
          state={error ? 'hasErrors' : null}
          errors={initErrors}
        >
          <Table
            style={{ marginTop: '1rem' }}
            scroll={{ x: 900 }}
            loading={isLoading}
            onChange={handleOnChange}
            columns={columns}
            dataSource={data?.list}
            pagination={{
              current: requestData.paging.page,
              position: ['bottomRight'],
              pageSize: requestData.paging.pageSize,
              total: data?.totalRowCount,
              showSizeChanger: false
            }}
            expandable={{
              rowExpandable(record) {
                return true;
              },
              expandedRowRender(record) {
                return (
                  <CredentialsValue
                    id={record.id}
                    expanded={expandedKeys.indexOf(record.id) >= 0}
                  />
                )
              },
              expandedRowKeys: expandedKeys,
              onExpandedRowsChange: setExpandedKeys,
            }}
          />
        </Wrapper>

      </CredentialsTableContext.Provider>
      <CredentialsLimitation
        value={limit === null ? null : {
          id: limit.id,
          description: limit.description
        }}
        onCancel={handleOnCancelLimit}
        onSuccessful={handleOnSuccessfulLimit}
      />
    </>
  )
}