import React, { useContext, useMemo } from "react";
import useCredentialsApi from "../../../services/reportGateway/api/credentialsApi";
import { useQuery } from "react-query";
import { Wrapper, WrapperState } from "../../../components/Wrapper";
import { fromReportGatewayError } from "../../../Types/Error";
import { CredentialsTableContext } from "./CredentialsTable";
import { ObjectVisualizer } from "../../../components/ObjectVisualizer";
import { Col, Empty, Row } from "antd";

interface ICredentialsValueProps {
  id: string;
  expanded: boolean;
}

export function CredentialsValue(props: ICredentialsValueProps) {
  const { id, expanded } = props;

  const api = useCredentialsApi();

  const { updated } = useContext(CredentialsTableContext);

  const { data, error, isLoading } = useQuery(
    ['credentials', 'list', 'value', id, updated],
    fetchData,
    { enabled: expanded }
  )

  const initErrors = useMemo(() => error ? fromReportGatewayError(error as any) : [], [error])

  const wrapperState = useMemo<WrapperState | null>(() => {
    if (isLoading) return 'loading';
    else if (error) return 'hasErrors';
    else return null;
  }, [error, isLoading])

  function fetchData() {
    return api.info(id);
  }

  return (
    <Wrapper state={wrapperState} errors={initErrors}>
      <ObjectVisualizer objStr={data?.value ?? ''} />
    </Wrapper>
  )
}