import React from 'react';
import { Input, Space, Button} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';

export const UserListUserNameFilter = (props: FilterDropdownProps) => {
    const { selectedKeys, setSelectedKeys } = props;

    function handleOnFilter() {
        props.confirm();
    }

    function handleOnReset() {
        if (props.clearFilters) {
            props.clearFilters();
        }
    }

    return <div style={{ padding: 8 }}>
        <Input placeholder="Поиск по имени пользователя"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ marginBottom: 8 }}
        />
        <Space>
            <Button type="primary"
                onClick={handleOnFilter}
                size="small"
                icon={<SearchOutlined />}>
                Искать
          </Button>
            <Button onClick={handleOnReset} size="small">Сбросить</Button>
        </Space>
    </div>
};