import { Button, Col, Modal, Row, Typography, PageHeader, Empty } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CredentialsInfo } from "../../../components/Credentials";

const { Title } = Typography;

interface ICredentialsInfoProps {
  title: string;
  id: string | null;
  //item: IPermissionsListItem | null;
  onClose(): void;
}

export function PermissionCredentialsInfo(props: ICredentialsInfoProps) {
  const { title, id, onClose } = props;

  return (
    <Modal
      width={750}
      open={id !== null}
      title={title}
      closable={false}
      maskClosable={false}
      footer={(
        <Row>
          {id && (
            <Col>
              {/* <Link to={`/credentials/${id}`}>Перейти</Link> */}
            </Col>
          )}
          <Col flex={1} />
          <Col>
            <Button type='primary' onClick={onClose}>Закрыть</Button>
          </Col>
        </Row>
      )}
    >
      {id === null ? (
        <Empty />
      ) : (
        <CredentialsInfo id={id} />
      )}
    </Modal>
  )
}