import React, { useEffect, useState } from 'react';
import { Space, Button, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import {
    IRolesListItem
} from '../../services/reportGateway/api/rolesApi';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

interface UserListRoleFilterProps extends FilterDropdownProps {
    roles: IRolesListItem[]
}

export const UserListRoleFilter = (props: UserListRoleFilterProps) => {
    const [checkedValues, setCheckedValues] = useState<string[]>([]);
    const { selectedKeys, setSelectedKeys } = props;

    useEffect(() => {
        setCheckedValues(selectedKeys.map((value) => value.toString()));
    }, [selectedKeys])

    function handleOnFilter() {
        props.confirm();
    }

    function handleOnReset() {
        if (props.clearFilters) {
            props.clearFilters();
        }
    }

    function handleOnChange(checkedValue: CheckboxValueType[]) {
        setSelectedKeys(checkedValue.map(value => value.toString()))
    }

    return <div style={{ padding: 8 }}>
        <Checkbox.Group style={{ width: '100%', marginBottom: 8 }} value={checkedValues} onChange={handleOnChange}>
            <Space direction="vertical">
                {
                    props.roles.map((value, index) => <Checkbox
                        key={`role_${index}`}
                        value={value.id}>
                        {value.name}
                    </Checkbox>)
                }
            </Space>
        </Checkbox.Group>
        <Space>
            <Button type="primary"
                onClick={handleOnFilter}
                size="small"
                icon={<SearchOutlined />}>
                Искать
          </Button>
            <Button onClick={handleOnReset} size="small">Сбросить</Button>
        </Space>
    </div>
};