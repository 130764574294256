import moment, { Moment } from "moment";
import { IPagedList } from "../../../Types/PagedList";
import ICertificateInfo from "../../../Types/backend/certificates/CertificateInfo";
import ICertificateReferencesItem from "../../../Types/backend/certificates/CertificateReferencesItem";
import ICertificatesListItem from "../../../Types/backend/certificates/CertificatesListItem";
import { useMakeReportGatewayRequest } from "../makeReportGatewayRequest";
import { ICertificateCardCredentialsListItem, ICertificateCardEndpointsListItem, ICertificateCardMain, ICertificateCardTitle } from "../../../Types/backend/certificates/CertificateCard";

interface ICertificateReferencesItemResponse {
  id: string;
  name: string;
  thumbprint: string;
}

interface ICertificateInfoResponse {
  id: string;
  organizationId: string | null;
  organizationName: string | null;
  description: string;
  thumbprint: string;
  serialNumber: string | null;
  issuer: string | null;
  subject: string | null;
  startDate: Date;
  endDate: Date;
}

interface ICertificatesListItemResponse {
  id: string;
  organizationId: string | null;
  organizationName: string | null;
  description: string;
  thumbprint: string;
  serialNumber: string | null;
  subject: string | null;
  startDate: Date;
  endDate: Date;
}

interface ICertificateCardMainResponse {
  id: string;
  organizationId: string | null;
  organizationName: string | null;
  description: string;
  thumbprint: string;
  serialNumber: string | null;
  issuer: string | null;
  subject: string | null;
  startDate: Date;
  endDate: Date;
}

interface ICertificatesApi {
  list(page: number, pageSize: number): Promise<IPagedList<ICertificatesListItem>>;
  references(): Promise<ICertificateReferencesItem[]>;
  info(id: string): Promise<ICertificateInfo>;
  create(description: string, organizationId: string, thumbprint: string, serialNumber: string,
    issuer: string, subject: string, startDate: Moment, endDate: Moment): Promise<void>;
  cardTitle(id: string): Promise<ICertificateCardTitle>;
  cardMain(id: string): Promise<ICertificateCardMain>;
  cardEndpoints(id: string, page: number, pageSize: number): Promise<IPagedList<ICertificateCardEndpointsListItem>>;
  cardCredentials(id: string, page: number, pageSize: number): Promise<IPagedList<ICertificateCardCredentialsListItem>>;
}

export default function useCertificatesApi(): ICertificatesApi {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  function list(page: number, pageSize: number): Promise<IPagedList<ICertificatesListItem>> {
    const params = new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString()
    })

    return makeReportGatewayRequest<IPagedList<ICertificatesListItemResponse>, IPagedList<ICertificatesListItem>>(
      `/api/certificates?${params.toString()}`,
      'get',
      response => ({
        ...response,
        list: response.list.map(v => ({
          ...v,
          startDate: moment(v.startDate),
          endDate: moment(v.endDate)
        }))
      }),
      null,
      'json'
    )
  }

  function references(): Promise<ICertificateReferencesItem[]> {
    return makeReportGatewayRequest<ICertificateReferencesItemResponse[], ICertificateReferencesItem[]>(
      '/api/certificates/references',
      'get',
      response => response,
      null,
      'json'
    );
  }

  function info(id: string): Promise<ICertificateInfo> {
    return makeReportGatewayRequest<ICertificateInfoResponse, ICertificateInfo>(
      `/api/certificates/${id}/info`,
      'get',
      response => ({
        ...response,
        startDate: moment(response.startDate),
        endDate: moment(response.endDate)
      }),
      null,
      'json'
    )
  }

  function create(description: string, organizationId: string, thumbprint: string, serialNumber: string,
    issuer: string, subject: string, startDate: Moment, endDate: Moment): Promise<void> {
    return makeReportGatewayRequest<void, void>(
      '/api/certificates',
      'post',
      () => { },
      {
        description: description,
        organizationId: organizationId,
        thumbprint: thumbprint,
        serialNumber: serialNumber,
        issuer: issuer,
        subject: subject,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),    
      },
      'json'
    )
  }

  function cardTitle(id: string): Promise<ICertificateCardTitle> {
    return makeReportGatewayRequest<ICertificateCardTitle, ICertificateCardTitle>(
      `/api/certificates/${id}/title`,
      'get',
      response => response,
      null,
      'json'
    )
  }

  function cardMain(id: string): Promise<ICertificateCardMain> {
    return makeReportGatewayRequest<ICertificateCardMainResponse, ICertificateCardMain>(
      `/api/certificates/${id}/main`,
      'get',
      response => ({
        ...response,
        startDate: moment(response.startDate),
        endDate: moment(response.endDate)
      }),
      null,
      'json'
    )
  }

  function cardEndpoints(id: string, page: number, pageSize: number): Promise<IPagedList<ICertificateCardEndpointsListItem>> {
    const params = new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
    })
    return makeReportGatewayRequest<IPagedList<ICertificateCardEndpointsListItem>, IPagedList<ICertificateCardEndpointsListItem>>(
      `/api/certificates/${id}/endpoints?${params.toString()}`,
      'get',
      response => response,
      null,
      'json'
    )
  }

  function cardCredentials(id: string, page: number, pageSize: number): Promise<IPagedList<ICertificateCardCredentialsListItem>> {
    const params = new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
    })
    return makeReportGatewayRequest<IPagedList<ICertificateCardCredentialsListItem>, IPagedList<ICertificateCardCredentialsListItem>>(
      `/api/certificates/${id}/credentials?${params.toString()}`,
      'get',
      response => response,
      null,
      'json'
    )
  }

  return {
    list,
    references,
    info,
    create,
    cardTitle,
    cardMain,
    cardEndpoints,
    cardCredentials
  }
}