import { Alert, Button, Col, Modal, Row, Tooltip, notification } from "antd";
import { EllipsisOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { CertificateSelectList } from "./CertificateSelectList";
import { CertificateInfo } from "../Info/CertificateInfo";

interface ICertificateSelectProps {
  providerId?: string;
  value: string;
  onChange?(value: string): void;
}

export function CertificateSelect(props: ICertificateSelectProps) {
  const { providerId, onChange } = props;

  const [selected, setSelected] = useState('');
  const [value, setValue] = useState(props.value ?? '');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value])

  function handleOnOpen() {
    setOpen(true);
  }

  function handleOnClear() {
    setValue('');
  }

  function handleOnCancel() {
    setOpen(false);
  }

  function handleOnOk() {
    setValue(selected);
    setOpen(false);
  }

  return (
    <>
      <Row gutter={8} align={value ? 'top' : 'middle'}>
        <Col span={21}>
          {value ? (
            <CertificateInfo id={value} hideId />
          ) : (
            <Alert message='Сертификат не выбран' />
          )}
        </Col>
        <Col span={3}>
          <Button.Group>
            <Tooltip title='Выбрать'>
              <Button
                disabled={providerId === ''}
                icon={<EllipsisOutlined />}
                onClick={handleOnOpen}
              />
            </Tooltip>
            <Tooltip title='Очистить'>
              <Button
                disabled={value === ''}
                icon={<CloseOutlined />}
                onClick={handleOnClear}
              />
            </Tooltip>
          </Button.Group>
        </Col>
      </Row>
      <Modal
        width={700}
        title='Выбор сертификата'
        open={open}
        closable={false}
        maskClosable={false}
        onOk={handleOnOk}
        okText='Выбрать'
        onCancel={handleOnCancel}
        cancelText='Отмена'
      >
        <CertificateSelectList
          providerId={providerId}
          value={value}
          onChange={setSelected}
        />
      </Modal>
    </>
  )
}