import React from 'react';
import { Badge, BadgeProps, Button, Popover, Space, Tag, Tooltip } from 'antd';
import { InfoOutlined, LockOutlined, LockFilled } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import ICredentialsListItem from '../../../Types/backend/credentials/CredentialsListItem';
import moment from 'moment';
import { Link } from 'react-router-dom';

const disabledMap: Record<string, BadgeProps> = {
  [true.toString()]: { text: 'Неактивны', status: 'error' },
  [false.toString()]: { text: 'Активны', status: 'processing' }
}

interface IConfig {
  canEdit: boolean;
  limit: ICredentialsListItem | null;
  onLimit(record: ICredentialsListItem): void;
}

export function getColumns(config: IConfig): ColumnProps<ICredentialsListItem>[] {
  const { canEdit, limit, onLimit } = config;

  return [
    {
      title: '#',
      key: 'index',
      width: 70,
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Описание',
      key: 'description',
      render(value, record, index) {
        return record.description
      },
    },
    {
      title: 'Организация',
      key: 'organizationName',
      render(value, record, index) {
        return record.organizationName ?? '-'
      },
    },
    {
      title: 'Сертификаты',
      key: 'certificates',
      render(value, record, index) {
        const now = moment();

        return (
          <Space>
            {record.signCertificateId === null ? null : (
              <Popover
                showArrow
                trigger={['click']}
                content={(
                  <Space direction='vertical'>
                    {record.signCertificateThumbprint ?? '-'}
                    {(
                      <Link to={`/certificates/${record.signCertificateId}`}>
                        Перейти
                      </Link>
                    )}
                  </Space>
                )}
              >
                <Tag
                  style={{ cursor: 'pointer' }}
                  color={record.signCertificateEndDate !== null && record.signCertificateEndDate <= now
                    ? 'error'
                    : 'default'
                  }
                >
                  Подписание
                </Tag>
              </Popover>
            )}
            {record.encryptCertificateId === null ? null : (
              <Popover
                showArrow
                trigger={['click']}
                content={(
                  <Space direction='vertical'>
                    {record.encryptCertificateThumbprint ?? '-'}
                    {(
                      <Link to={`/certificates/${record.encryptCertificateId}`}>
                        Перейти
                      </Link>
                    )}
                  </Space>
                )}
              >
                <Tag
                  style={{ cursor: 'pointer' }}
                  color={record.encryptCertificateEndDate !== null && record.encryptCertificateEndDate <= now
                    ? 'error'
                    : 'default'
                  }
                >
                  Шифрование
                </Tag>
              </Popover>
            )}
          </Space>
        )
      },
    },
    {
      title: 'Статус',
      key: 'status',
      width: 170,
      render(value, record, index) {
        return (
          <Badge {...disabledMap[record.disabled.toString()]} />
        )
      },
    },
    {
      title: 'Ограничение',
      key: 'endDate',
      width: 200,
      render(value, record, index) {
        return record.endDate === null ? '-' : (
          <Space>
            {record.endDate.format('DD.MM.YYYY')}
            {record.reason === null ? null : (
              <Popover
                trigger={['click']}
                showArrow
                title={'Причина'}
                content={record.reason}
              >
                <Button
                  shape='circle'
                  size='small'
                  icon={<InfoOutlined />}
                />
              </Popover>
            )}
          </Space>
        )
      },
    },
    {
      key: 'actions',
      width: 120,
      align: 'center',
      render(value, record, index) {
        return (
          <Tooltip title='Ограничить'>
            <Button
              disabled={!canEdit || record.endDate !== null}
              shape='circle'
              icon={record.id === limit?.id ? <LockFilled /> : <LockOutlined />}
              onClick={() => onLimit(record)}
            />
          </Tooltip>
        )
      },
    }
  ]
}