import { PageHeader } from 'antd';
import React from 'react';

export function Dashboard() {
  return (
    <>
      <PageHeader title="Dashboard" />
    </>
  );
}
