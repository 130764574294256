import { Button, Col, Row, Table, Tooltip, notification } from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from 'react-query';
import { getColumns } from "./PermissionsTableColumns";
import IPermissionsListItem from "../../../Types/backend/permissions/PermissionsListItem";
import { IPagedList } from "../../../Types/PagedList";
import usePermissionsApi from "../../../services/reportGateway/api/permissionsApi";
import { useAuth } from "../../../Auth";
import { usePermissionsTableConfig, defaultPage, defaultPageSize, emptyFilter } from "./PermissionsTableConfig";
import { TablePaginationConfig } from "antd/lib/table";
import { PermissionCredentialsInfo } from "./PermissionCredentialsInfo";
import { PermissionEndpointInfo } from "./PermissionEndpointInfo";
import { PermissionLimitation } from "./PermissionLimitation";

interface IUserPermissions {
  canView: boolean;
  canEdit: boolean;
}

interface IPermissionsTableItem extends IPermissionsListItem {
  key: string;
}

interface IPermissionCredentialsInfo {
  permissionId: string;
  clientName: string;
  providerName: string;
  productName: string | null;
  credentialsId: string;
}

interface IPermissionEndpointInfo {
  permissionId: string;
  clientName: string;
  providerName: string;
  productName: string | null;
  endpointId: string;
  endpointPurpose: string;
}

export function PermissionsTable() {

  const { currentUser } = useAuth();
  const permissionsApi = usePermissionsApi();
  const { requestData, setFilter, setPaging } = usePermissionsTableConfig();

  const [credentialsInfo, setCredentialsInfo] = useState<IPermissionCredentialsInfo | null>(null);
  const [endpointInfo, setEndpointInfo] = useState<IPermissionEndpointInfo | null>(null);
  const [permissionLimitation, setPermissionLimitation] = useState<IPermissionsListItem | null>(null);

  const userPermissions = useMemo<IUserPermissions>(() => ({
    canView: currentUser?.canViewPermissions() ?? false,
    canEdit: currentUser?.canEditPermissions() ?? false
  }), [currentUser])

  const { refetch, data: permissions, error, isLoading, isFetching } = useQuery(
    ['permissions', 'list', userPermissions, requestData],
    fetchData,
    {}
  )

  async function fetchData(): Promise<IPagedList<IPermissionsTableItem> | undefined> {
    if (!userPermissions.canView) return undefined;

    const response = await permissionsApi.list(requestData.paging.page, requestData.paging.pageSize);

    return {
      ...response,
      list: response.list.map(value => ({
        ...value,
        key: `permissions_${value.id}`
      }))
    }
  }

  function handleOnChange(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  function handleOnRefresh() {
    refetch();
  }

  function handleOnShowCredentialsInfo(item: IPermissionsListItem) {
    setCredentialsInfo({ ...item, permissionId: item.id });
  }

  function handleOnHideCredentialsInfo() {
    setCredentialsInfo(null);
  }

  function handleOnShowEndpointInfo(permission: IPermissionsListItem, endpointId: string, endpointPurpose: string) {
    setEndpointInfo({
      ...permission,
      permissionId: permission.id,
      endpointId: endpointId,
      endpointPurpose: endpointPurpose
    });
  }

  function handleOnHideEndpointInfo() {
    setEndpointInfo(null);
  }

  function handleOnPermissionLimit() {
    notification.success({
      message: 'Разрешение ограничено'
    })
    refetch();
    setPermissionLimitation(null);
  }

  function handleOnShowPermissionLimitation(item: IPermissionsListItem) {
    setPermissionLimitation(item);
  }

  function handleOnHidePermissionLimitation() {
    setPermissionLimitation(null);
  }

  return (
    <>
      <Row gutter={8}>
        <Col flex={1} />
        <Col>
          <Tooltip title='Обновить'>
            <Button
              shape='circle'
              icon={<ReloadOutlined />}
              loading={isLoading || isFetching}
              onClick={handleOnRefresh}
            />
          </Tooltip>
        </Col>
      </Row>
      <Table
        style={{ marginTop: '1rem' }}
        scroll={{ x: 900 }}
        loading={isLoading}
        onChange={handleOnChange}
        columns={getColumns({
          onShowCredentialsInfo: handleOnShowCredentialsInfo,
          credentialsInfo: credentialsInfo === null ? null : credentialsInfo,
          onShowEndpointInfo: handleOnShowEndpointInfo,
          endpointInfo: endpointInfo === null ? null : endpointInfo,
          onShowPermissionLimitation: handleOnShowPermissionLimitation,
          permissionLimitationId: permissionLimitation?.id ?? null,
        })}
        dataSource={permissions?.list}
        pagination={{
          current: requestData.paging.page,
          position: ['bottomRight'],
          pageSize: requestData.paging.pageSize,
          total: permissions?.totalRowCount,
          showSizeChanger: false
        }}
      />
      <PermissionCredentialsInfo
        title={credentialsInfo === null ? (
          '-'
        ) : (
          `Учетные данные для запросов ${credentialsInfo.clientName} - ${credentialsInfo.productName ? (
            `${credentialsInfo.providerName} / ${credentialsInfo.productName}`
          ) : (
            credentialsInfo.providerName
          )}`
        )}
        id={credentialsInfo?.credentialsId ?? null}
        onClose={handleOnHideCredentialsInfo}
      />
      <PermissionEndpointInfo
        title={endpointInfo === null ? (
          '-'
        ) : (
          `Эндпоинт ${endpointInfo.endpointPurpose} для ${endpointInfo.clientName} - ${endpointInfo.productName ? (
            `${endpointInfo.providerName} / ${endpointInfo.productName}`
          ) : (
            endpointInfo.providerName
          )}`
        )}
        id={endpointInfo?.endpointId ?? null}
        onClose={handleOnHideEndpointInfo}
      />
      <PermissionLimitation
        title={permissionLimitation === null ? (
          '-'
        ) : (
          `Ограничение разрешения ${permissionLimitation.clientName} - ${permissionLimitation.productName ? (
            `${permissionLimitation.providerName} / ${permissionLimitation.productName}`
          ) : (
            permissionLimitation.providerName
          )}`
        )}
        id={permissionLimitation?.id ?? null}
        current={permissionLimitation?.endDate ?? null}
        onOK={handleOnPermissionLimit}
        onCancel={handleOnHidePermissionLimitation}
      />
    </>
  )
}