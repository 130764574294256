import React from "react";
import { ColumnProps } from "antd/lib/table";
import { ICertificateCardCredentialsListItem } from "../../../../Types/backend/certificates/CertificateCard";
import { Badge, BadgeProps, Space, Tag } from "antd";

const disabledMap: Record<string, BadgeProps> = {
  [true.toString()]: { text: 'Неактивны', status: 'error' },
  [false.toString()]: { text: 'Активны', status: 'processing' }
}

export function getColumns(): ColumnProps<ICertificateCardCredentialsListItem>[] {
  return [
    {
      title: '#',
      key: 'index',
      width: 70,
      render(value, record, index) {
        return index + 1;
      },
    },
    {
      title: 'Описание',
      key: 'description',
      render(value, record, index) {
        return record.description;
      },
    },
    {
      title: 'Использование',
      key: 'usage',
      render(value, record, index) {
        return (
          <Space>
            {record.forSigning ? <Tag>Подписание</Tag> : null}
            {record.forEncryption ? <Tag>Шифрование</Tag> : null}
          </Space>
        )
      },
    },
    {
      title: 'Статус',
      key: 'status',
      width: 170,
      render(value, record, index) {
        return (
          <Badge {...disabledMap[record.disabled.toString()]} />
        )
      },
    },
  ]
}