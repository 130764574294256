import { DatePicker, Empty, Form, Modal, notification } from "antd";
import FormItem from "antd/es/form/FormItem";
import { Moment } from "moment";
import React, { useState } from "react";
import usePermissionsApi from "../../../services/reportGateway/api/permissionsApi";

const { useForm } = Form;

interface IBackendForm {
  From: Moment;
}

interface IPermissionLimitationProps {
  title: string;
  id: string | null;
  current: Moment | null;
  onOK(): void;
  onCancel(): void;
}

export function PermissionLimitation(props: IPermissionLimitationProps) {
  const { title, id, current, onOK, onCancel } = props;

  const permissionsApi = usePermissionsApi();
  const [form] = useForm<IBackendForm>();

  const [isProcessing, setIsProcessing] = useState(false);

  function handleOnCancel() {
    onCancel();
    form.resetFields();
  }

  async function handleOnFinish(values: IBackendForm) {
    if (id === null) return;

    const { From } = values;
    setIsProcessing(true);

    let successful = true;

    try {
      await permissionsApi.limit(id, From.utc())
    } catch (error) {
      console.error(error);
      successful = false;
    } finally {
      setIsProcessing(false);
    }

    if (successful) {
      onOK();
      form.resetFields();
    } else {
      notification.error({
        message: 'Не удалось ограничить разрешение'
      })
    }
  }

  return (
    <Modal
      title={title}
      closable={false}
      maskClosable={false}
      width={600}
      open={id !== null}
      onOk={form.submit}
      okText='Ограничить'
      okButtonProps={{ loading: isProcessing, disabled: id === null }}
      onCancel={handleOnCancel}
      cancelText='Отмена'
      cancelButtonProps={{ disabled: isProcessing }}
    >
      {id === null ? (
        <Empty />
      ) : (
        <Form
          form={form}
          initialValues={{
            From: current !== null ? current : undefined
          }}
          onFinish={handleOnFinish}
        >
          <FormItem name='From' label='Ограничить с'>
            <DatePicker
              allowClear
              showTime
            />
          </FormItem>
        </Form>
      )}
    </Modal>
  )
}