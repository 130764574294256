import create from "zustand"
import { getCookie } from "./services/cookies";
import reportGatewayAccount, { ICurrentUser, IUserPermissions } from "./services/reportGateway/reportGatewayAccount";

export interface IAuthUser {
    userName: string;
    canViewUsers(): boolean;
    canEditUsers(): boolean;
    canViewRequests(): boolean;
    canViewProductRequestPackages(): boolean;
    canEditProductRequestPackages(): boolean;
    canViewPermissions(): boolean;
    canEditPermissions(): boolean;
}

class AuthUser implements IAuthUser {
    userName: string;
    private permissions: IUserPermissions;
    constructor(currentUser: ICurrentUser) {
        this.userName = currentUser.userName;
        this.permissions = currentUser.permissions;
    }

    canViewUsers() { return this.permissions.canViewUsers; };
    canEditUsers() { return this.permissions.canEditUsers; };
    canViewRequests() { return this.permissions.canViewRequests; };
    canViewProductRequestPackages() { return this.permissions.canViewProductRequestPackages; };
    canEditProductRequestPackages() { return this.permissions.canEditProductRequestPackages; };
    canViewPermissions() { return this.permissions.canViewPermissions; };
    canEditPermissions() { return this.permissions.canEditPermissions; };
}

type AuthState = {
    currentUser: IAuthUser | null;
    signin: (userName: string, password: string, callback: () => void) => Promise<void>;
    signout: () => Promise<void>;
    refresh: () => Promise<void>;
    reset: () => void;
    checkAuth: () => boolean;
}

export const useAuth = create<AuthState>((set, get) => ({
    currentUser: null,
    signin: async (userName, password, callback) => {
        try {
            const result = await reportGatewayAccount.login(userName, password);
            set({ currentUser: new AuthUser(result) });
            callback();
        }
        catch (error) {
            throw error;
        }
    },
    signout: async () => {
        try {
            await reportGatewayAccount.logout();
            set({ currentUser: null });
        }
        catch (error: any) {
            if (error.statusCode === 401) {
                set({ currentUser: null });
            }
            else {
                throw error;
            }
        }
    },
    refresh: async () => {
        if (!get().currentUser) {
            try {
                const result = await reportGatewayAccount.getCurrentUser();
                if (result) {
                    set({ currentUser: new AuthUser(result) });
                }
            }
            catch (error) {
                throw error;
            }
        }
    },
    reset: () => {
        set({ currentUser: null })
    },
    checkAuth: () => {
        const loginInfoCookie = getCookie('RG.Admin.LoginInfo');
        return loginInfoCookie ? true : false;
    }
}));