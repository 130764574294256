import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Upload, PageHeader, Form, Row, Col, notification } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined, FileOutlined } from '@ant-design/icons';
import { CForm } from '../../CForm';
import { CSelect } from '../../CSelect';
import { Wrapper, WrapperState } from '../../Wrapper';
import { ErrorsList } from '../../ErrorsList';
import { useAuth } from '../../../Auth';
import {
  Error,
  fromReportGatewayError,
  convertFormError
} from '../../../Types/Error';
import { emptyGuid } from '../../../Types/EmptyGuid';
import {
  IProductRequestPackageProject,
  useProductRequestPackagesApi
} from '../../../services/reportGateway/api/productRequestPackagesApi';
import {
  IProductSelectViewModel,
  useProductsApi
} from '../../../services/reportGateway/api/productsApi';
import PackageCreatingResultType from '../../../Types/PackageCreatingResultType';

interface SubmitValues {
  ProjectId: string;
}

const initialValues: SubmitValues = {
  ProjectId: emptyGuid,
};

export const ProductRequestPackageCreateCard = () => {
  const unknownProductName = 'Неизвестно';
  const unmounted = useRef(false);
  const location = useLocation();
  const history = useHistory();
  const productRequestPackagesApi = useProductRequestPackagesApi();
  const productsApi = useProductsApi();
  const { currentUser } = useAuth();
  const [projects, setProjects] = useState<IProductRequestPackageProject[]>([]);
  const [productId, setProductId] = useState<string | null>(null);
  const [productName, setProductName] = useState(unknownProductName);
  const [isLoading, setIsLoading] = useState(false);
  const [initErrors, setInitErrors] = useState<Error[]>([]);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [initial, setInitial] = useState(initialValues);
  const [file, setFile] = useState<UploadFile<any> | null>(null);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    }
  }, [])

  useEffect(() => {
    async function fetchData() {
      setInitErrors([]);
      setIsLoading(true);
      setProductName(unknownProductName);
      setProjects([{ id: emptyGuid, name: '-' }]);
      try {

        if (productId) {
          const receivedProjects = await productRequestPackagesApi.getProjects();
          const receivedProduct = await productsApi.getProduct(productId);

          if (!unmounted.current) {
            setProjects([...projects, ...receivedProjects]);
            setProductName(receivedProduct ? receivedProduct.name : unknownProductName);
          }  
        }

      } catch (error: any) {
        if (!unmounted.current) {
          setInitErrors(fromReportGatewayError(error));
        }
      } finally {
        if (!unmounted.current) {
          setIsLoading(false);
        }
      }
    }

    if (currentUser?.canEditProductRequestPackages()) {
      fetchData();
    }
  }, [currentUser, productId])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchProductId = searchParams.get('productId');
    if (searchProductId) {
      setProductId(searchProductId);
    }
    else {
      setInitErrors([...initErrors, { fieldName: null, message: 'Не указан продукт' }])
    }
  }, [location.search])

  useEffect(() => {
  }, [productId])

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    }
    else if (!currentUser.canEditProductRequestPackages()) {
      setWrapperState('accessDenied');
    } else if (isLoading) {
      setWrapperState('loading');
    } else if (initErrors && initErrors.length > 0) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, isLoading, initErrors]);

  async function createPackage(productId: string, projectId: string, file: Blob) {
    try {
      const response = await productRequestPackagesApi.createPackage(
        productId,
        projectId,
        file);

      notify(response.type);
    }
    catch (error: any) {
      if (!unmounted.current) {
        const errors = fromReportGatewayError(error);
        return convertFormError(errors);
      }
    }
    history.push('/productRequestPackages');
  }

  function notify(resultType: PackageCreatingResultType) {
    switch (resultType) {
      case PackageCreatingResultType.AnotherPackageProcessed:
        notification['warning']({
          message: 'Невозможно создать пакет, другой пакет уже находится в обработке'
        });
        break;
    }
  }

  async function onSubmit(values: SubmitValues) {
    if (file?.originFileObj && productId) {
      const errors = await createPackage(
        productId,
        values.ProjectId,
        file.originFileObj);
      return errors;
    }
  }

  function handleChange(info: UploadChangeParam<UploadFile<any>>) {
    const fileList = [...info.fileList];

    let file = fileList[fileList.length - 1];

    if (file) {
      setFile(file);
    }
    else {
      setFile(null);
    }
  }

  function handleBeforeUpload() {
    return false;
  }

  return <Wrapper state={wrapperState} errors={initErrors}>
    <PageHeader title="Создание пакетного запроса"
      subTitle={productName}
      onBack={() => { history.push('/productRequestPackages') }}
    />
    <div className="container left">
      <CForm<SubmitValues>
        onSubmit={onSubmit}
        initialValues={initial}
        autoComplete="off"
        validate={(values) => {
          const errors: Partial<SubmitValues> = {};
          return errors;
        }}
      >
        {({ values, submitting, submitErrors }) => (
          <Row className="u-mt-4">
            <Col span={24}>
              <CSelect name="ProjectId"
                label="Выберите проект"
                options={projects.map(value => ({ label: value.name, value: value.id }))}
              />
              <Upload
                beforeUpload={handleBeforeUpload}
                onChange={handleChange}
                accept=".csv"
                multiple={false}
                fileList={file ? [file] : []}
                listType="picture"
                iconRender={() => <FileOutlined />}
              >
                <Button icon={<UploadOutlined />}>
                  Выберите файл
                </Button>
              </Upload>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  disabled={file === null}
                >
                  Создать
                </Button>
              </Form.Item>
              {submitErrors && <ErrorsList errors={submitErrors} />}
            </Col>
          </Row>
        )}
      </CForm>
    </div>
  </Wrapper>
}