import { Alert, Button, Col, Empty, Modal, Row, Tooltip, Typography, notification } from "antd";
import { EllipsisOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { CredentialsSelectList } from "./CredentialsSelectList";
import { CredentialsInfo } from "../Info/CredentialsInfo";

const { Text } = Typography;

interface ICredentialsSelectProps {
  providerId: string;
  value?: string;
  onChange?(value: string): void;
}

export function CredentialsSelect(props: ICredentialsSelectProps) {
  const { providerId, onChange } = props;

  const [selected, setSelected] = useState('');
  const [value, setValue] = useState(props.value ?? '');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue('');
  }, [providerId])

  useEffect(() => {
    console.log(`select - ${value}`);
    if (onChange) onChange(value);
  }, [value])

  function handleOnOpen() {
    setOpen(true);
  }

  function handleOnClear() {
    setValue('');
  }

  function handleOnOk() {
    setValue(selected);
    setOpen(false);
  }

  function handleOnCancel() {
    setOpen(false);
  }

  return (
    <>
      <Row gutter={8} align={value ? 'top' : 'middle'}>
        <Col span={21}>
          {value ? (
            <CredentialsInfo id={value} hideId />
          ) : (
            <Alert message='Учетные данные не выбраны' />
          )}
        </Col>
        <Col span={3}>
          <Button.Group>
            <Tooltip title='Выбрать'>
              <Button
                disabled={providerId === ''}
                icon={<EllipsisOutlined />}
                onClick={handleOnOpen}
              />
            </Tooltip>
            <Tooltip title='Очистить'>
              <Button
                disabled={value === ''}
                icon={<CloseOutlined />}
                onClick={handleOnClear}
              />
            </Tooltip>
          </Button.Group>
        </Col>
      </Row>
      <Modal
        title='Выбор учетных данных'
        width={700}
        open={open}
        closable={false}
        maskClosable={false}
        onOk={handleOnOk}
        okText='Выбрать'
        onCancel={handleOnCancel}
        cancelText='Отмена'
      >
        <CredentialsSelectList
          providerId={providerId}
          value={value}
          onChange={setSelected}
        />
      </Modal>
    </>
  )
}