import React from "react";
import { ColumnProps } from "antd/lib/table";
import { ICertificateCardEndpointsListItem } from "../../../../Types/backend/certificates/CertificateCard";
import { Badge, BadgeProps, Space } from "antd";

const disabledMap: Record<string, BadgeProps> = {
  [true.toString()]: { text: 'Неактивен', status: 'error' },
  [false.toString()]: { text: 'Активне', status: 'processing' }
}

export function getColumns(): ColumnProps<ICertificateCardEndpointsListItem>[] {
  return [
    {
      title: '#',
      key: 'index',
      width: 70,
      render(value, record, index) {
        return index + 1;
      },
    },
    {
      title: 'Описание',
      key: 'description',
      render(value, record, index) {
        return record.description
      },
    },
    {
      title: 'Назначение',
      key: 'purpose',
      width: 120,
      render(value, record, index) {
        return record.purpose
      },
    },
    {
      title: 'Url',
      key: 'url',
      render(value, record, index) {
        return record.url
      },
    },
    {
      title: 'Хост',
      key: 'hostHeader',
      render(value, record, index) {
        return record.hostHeader ?? '-'
      },
    },
    {
      title: 'Статус',
      key: 'status',
      width: 170,
      render(value, record, index) {
        console.log(record);
        return (
          <Badge {...disabledMap[record.disabled.toString()]} />
        )
      },
    },

  ]
}