import { CloseCircleOutlined } from '@ant-design/icons';
import { Result, Typography } from 'antd';
import React, { PropsWithChildren } from 'react';
import { Error } from '../../Types/Error';
import { PageLoading } from '../PageLoading';
import { WrapperState } from './';
import './Wrapper.scss';

const { Paragraph, Title } = Typography;
interface WrapperProps {
  state: WrapperState | null;
  errors: Error[];
  noLoader?: boolean;
}

export function Wrapper({
  errors,
  state,
  children,
  noLoader,
}: PropsWithChildren<WrapperProps>) {
  function renderContent() {
    switch (state) {
      case 'accessDenied': {
        return <Result status="error" title="Доступ запрещен"></Result>;
      }
      case 'hasErrors': {
        return (
          <Result status="error" title="При обработке запроса произошли ошибки">
            <div className="desc">
              <Paragraph>
                <Title level={5}>Ошибки:</Title>
              </Paragraph>
              {errors.map((error, index) => (
                <Paragraph key={`error_${index}`}>
                  <CloseCircleOutlined className="page-wrapper__error-icon u-mr-1" />
                  { error.fieldName ? `${error.fieldName} - ${error.message}` : error.message }
                </Paragraph>
              ))}
            </div>
          </Result>
        );
      }
      case 'loading': {
        return noLoader ? null : <PageLoading />;
      }
      default: {
        return children;
      }
    }
  }

  return <div className="page-wrapper">{renderContent()}</div>;
}
