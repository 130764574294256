import { useMakeReportGatewayRequest } from '../makeReportGatewayRequest';

interface IRolesListItemResponse {
  id: string;
  name: string;
}

export interface IRolesListItem {
  id: string;
  name: string;
}

interface IRolesApi {
  getRoles(): Promise<IRolesListItem[]>;
}

export const useRolesApi = (): IRolesApi => {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  const getRoles = (): Promise<IRolesListItem[]> => {
    return makeReportGatewayRequest<IRolesListItemResponse[], IRolesListItem[]>(
      '/api/roles',
      'get',
      response => response.map<IRolesListItem>(value => ({ ...value })),
      undefined
    );
  }

  return {
    getRoles
  }
}