import React, { useEffect, useState } from 'react';
import { Collapse, Space, Row, Col, Button } from 'antd';
import { CForm } from '../CForm';
import { CSelect } from '../CSelect';
import { CRadio } from '../CRadio';
import './FilterRow.scss';
import { SearchOutlined } from '@ant-design/icons';
import { CDatePicker } from '../CDatePicker';
import { CInput } from '../CInput';
import {
  IClientListItem,
} from '../../services/reportGateway/api/clientsApi';
import {
  IProductSelectViewModel
} from '../../services/reportGateway/api/productsApi';
import {
  IRequestStateViewModel
} from '../../services/reportGateway/api/requestsApi';
import { InfoPopover } from '../InfoPopover';
import {
  FilterValues,
  SubjectFilterType,
  useRequestListConfig
} from './RequestListConfig';

const { Panel } = Collapse;

interface FilterRowProps {
  productsOptions: IProductSelectViewModel[];
  clientsOptions: IClientListItem[];
  statesOptions: IRequestStateViewModel[];
  onSearchRequest: (values: FilterValues) => void;
  loading?: boolean;
}

export function FilterRow({
  clientsOptions,
  productsOptions,
  statesOptions,
  onSearchRequest,
  loading,
}: FilterRowProps) {
  const { isFilterOpen, openFilter, closeFilter, requestData } = useRequestListConfig();
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const panelKey = '1';

  useEffect(() => {
    setActivePanels(isFilterOpen ? [panelKey] : [])
  }, [isFilterOpen])

  function handleOnChange(key: string | string[]) {
    if (Array.isArray(key) && key.length > 0) {
      openFilter();
    }
    else {
      closeFilter();
    }
  }

  return (
    <Collapse
      ghost
      className="filter-row__collapse"
      activeKey={activePanels}
      onChange={handleOnChange}
    >
      <Panel header="Фильтры" key={panelKey}>
        <CForm<FilterValues>
          onSubmit={onSearchRequest}
          initialValues={requestData.filter}
        >
          {({ values }) => (
            <div className="filter-row">
              <Row gutter={16}>
                <Col span={8}>
                  <CSelect
                    size="middle"
                    placeholder="Клиент"
                    name="client"
                    allowClear
                    options={clientsOptions?.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                  />
                </Col>
                <Col span={16}>
                  <CSelect
                    size="middle"
                    placeholder="Продукты"
                    mode="multiple"
                    allowClear
                    showSearch
                    name="products"
                    maxTagCount={2}
                    options={productsOptions?.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                    filterOption={(input, option) => {
                      if (typeof option?.label === 'string') {
                        return option.label
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }
                      return false;
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <CSelect
                    size="middle"
                    mode="multiple"
                    allowClear
                    placeholder="Состояние запросов"
                    name="states"
                    options={statesOptions?.map((option) => ({
                      value: option.value,
                      label: option.name,
                    }))}
                    filterOption={(input, option) => {
                      if (typeof option?.label === 'string') {
                        return option.label
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }
                      return false;
                    }}
                  />
                </Col>
              </Row>
              <div className="u-mb-2">
                <Space wrap={true}>
                  <div>Дата - время создания запрос с</div>
                  <CDatePicker name="dateFrom" showTime className="u-mb-0" />
                  <div>по</div>
                  <CDatePicker name="dateTo" showTime className="u-mb-0" />
                  <InfoPopover info="Поиск производится по времени, что отображается в интерфейсе" />
                </Space>
              </div>

              <Row wrap={false}>
                <Col flex="60px">
                  <CRadio name="subjectFilterType" label="ФЛ" value="FL" />
                  <CRadio name="subjectFilterType" label="ЮР" value="JR" />
                  <CRadio name="subjectFilterType" label="Авто" value="cars" />
                </Col>
                <Col flex="auto">{renderFilter(values.subjectFilterType)}</Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Button
                    type="primary"
                    block
                    loading={loading}
                    htmlType="submit"
                    icon={<SearchOutlined />}
                  >
                    Искать
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </CForm>
      </Panel>
    </Collapse>
  );
}

interface WithPopoverWrapperProps {
  info?: string | JSX.Element;
  popoverWidth?: number;
  children: React.ReactNode;
}
function WithPopoverWrapper({
  info,
  popoverWidth,
  children,
}: WithPopoverWrapperProps) {
  return (
    <Space align="start" className="with-popover">
      <div className="with-popover__content">{children}</div>
      {info && (
        <InfoPopover
          info={info}
          popoverWidth={popoverWidth}
          style={{ paddingTop: 7 }}
        />
      )}
    </Space>
  );
}

function FizFilter() {
  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <WithPopoverWrapper info="Будут найдены записи которые начинаются или соответствуют данной строке">
            <CInput
              name="lastName"
              size="middle"
              placeholder="Фамилия"
            />
          </WithPopoverWrapper>
        </Col>
        <Col span={8}>
          <WithPopoverWrapper info="Будут найдены записи которые начинаются или соответствуют данной строке">
            <CInput
              name="firstName"
              size="middle"
              placeholder="Имя"
            />
          </WithPopoverWrapper>
        </Col>
        <Col span={8}>
          <WithPopoverWrapper info="Будут найдены записи которые начинаются или соответствуют данной строке">
            <CInput
              name="middleName"
              size="middle"
              placeholder="Отчество"
            />
          </WithPopoverWrapper>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <WithPopoverWrapper info="Будут найдены записи которые начинаются или соответствуют данной строке">
            <CInput
              name="passportSeries"
              size="middle"
              placeholder="Серия паспорта"
            />
          </WithPopoverWrapper>
        </Col>
        <Col span={15}>
          <WithPopoverWrapper info="Будут найдены записи которые начинаются или соответствуют данной строке">
            <CInput
              name="passportNumber"
              size="middle"
              placeholder="Номер паспорта"
            />
          </WithPopoverWrapper>
        </Col>
      </Row>
    </div>
  );
}

function JurFilter() {
  return (
    <div>
      <WithPopoverWrapper info="Будут найдены записи которые начинаются или соответствуют данной строке">
        <CInput
          name="companyName"
          size="middle"
          placeholder="Название компании"
        />
      </WithPopoverWrapper>
      <Row gutter={16}>
        <Col span={9}>
          <WithPopoverWrapper info="Будут найдены записи которые начинаются или соответствуют данной строке">
            <CInput
              name="inn"
              size="middle"
              placeholder="ИНН"
            />
          </WithPopoverWrapper>
        </Col>
        <Col span={15}>
          <WithPopoverWrapper info="Будут найдены записи которые начинаются или соответствуют данной строке">
            <CInput
              name="ogrn"
              size="middle"
              placeholder="ОГРН"
            />
          </WithPopoverWrapper>
        </Col>
      </Row>
    </div>
  );
}

function CarsFilter() {
  return (
    <div>
      <WithPopoverWrapper info="Будут найдены записи которые начинаются или соответствуют данной строке">
        <CInput name="vin" size="middle" placeholder="VIN" />
      </WithPopoverWrapper>
    </div>
  );
}

function renderFilter(filterType: SubjectFilterType) {
  switch (filterType) {
    case 'FL':
      return <FizFilter />;
    case 'JR':
      return <JurFilter />;
    case 'cars':
      return <CarsFilter />;

    default:
      return <div />;
  }
}