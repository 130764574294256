import moment, { Moment } from 'moment';
import { useMakeReportGatewayRequest } from '../makeReportGatewayRequest';
import { IPagedList } from '../../../Types/PagedList';
import { SubjectFilterType } from '../../../components/RequestList/RequestListConfig';

interface IRequestListItemResponse {
  id: string;
  productName: string;
  providerName: string;
  createdTimestamp: string;
  subject: string;
  clientName: string;
  state: string;
}

export interface IRequestListItem {
  id: string;
  productName: string;
  createdTimestamp: string;
  createdTimestampFormatted: string;
  subject: string;
  clientName: string;
  state: string;
}

interface IIncomingRequestResponse {
  requestBody: string;
  requestDateTime: Moment | undefined;
  responseCode: string;
  responseDateTime: Moment | undefined;
  requestUrl: string;
  cleanDateTime: Moment | undefined;
}

interface IRequestResponse {
  id: string;
  productName: string;
  providerName: string;
  createdTimestamp: Moment;
  subject: string;
  clientName: string;
  state: string;
  events: IRequestEventResponse[];
  activities: IRequestActivityResponse[];
  providerRequests: IProviderRequestResponse[];
  reportType: string | undefined;
  reportFileId: string | undefined;
  hasPdfReport: boolean;

  providerPayType: string
  providerPayDetails: string

  incomingRequest: IIncomingRequestResponse;
}
interface IProviderRequestCredentialsResponse {
  id: string | null;
  value: string;
  organizationName: string;
  startDate: Moment | null;
  endDate: Moment | null;
  comment: string | null;
  signCertificateThumbprint: string | null;
  encryptCertificateThumbprint: string | null;
}

interface IProviderRequestResponse {
  id: string;
  credentials: IProviderRequestCredentialsResponse | null;
  providerRequestBody: string | undefined;
  hasProviderResponseBody: boolean;
  createTimestamp: string;
  apiInteractionEvents: IApiInteractionEventResponse[];
  cleanDateTime: Moment | undefined;
}

interface IRequestEventResponse {
  eventLogId: string;
  eventDetails: string;
  createdTimestamp: Moment;
  eventType: string;
  details: string;
  level: number;
}

interface IRequestActivityResponse {
  createdTimestamp: Moment;
  state: string;
}

interface IApiInteractionEventResponse {
  id: string;
  url: string;
  responseCode: string;
  errorResponse: string;
  requestTimestamp: string;
  responseTimestamp: string;
  cleanDateTime: Moment | undefined;
}

export interface IIncomingRequestViewModel {
  requestBody: string;
  requestDateTime: Moment | undefined;
  responseCode: string;
  responseDateTime: Moment | undefined;
  requestUrl: string;
  cleanDateTime: Moment | undefined;
}

export interface IRequestViewModel {
  id: string;
  productName: string;
  providerName: string;
  createdTimestamp: Moment;
  createdTimestampFormatted: string;
  subject: string;
  clientName: string;
  state: string;
  events: IRequestEventViewModel[];
  activities: IRequestActivityViewModel[];
  providerRequests: IProviderRequestViewModel[];
  reportType: string | undefined;
  reportFileId: string | undefined;
  hasPdfReport: boolean;

  providerPayType: string;
  providerPayDetails: string;

  incomingRequest: IIncomingRequestViewModel;
}

interface IProviderRequestCredentialsViewModel {
  id: string | null;
  value: string;
  organizationName: string;
  startDate: Moment | null;
  endDate: Moment | null;
  comment: string | null;
  signCertificateThumbprint: string | null;
  encryptCertificateThumbprint: string | null;
}

interface IProviderRequestViewModel {
  id: string;
  credentials: IProviderRequestCredentialsViewModel | null;
  providerRequestBody: string | undefined;
  hasProviderResponseBody: boolean;
  createTimestamp: Moment;
  createdTimestampFormatted: string;
  apiInteractionEvents: IApiInteractionEventViewModel[];
  cleanDateTime: Moment | undefined;
}

interface IRequestEventViewModel {
  eventLogId: string;
  eventDetails: string;
  createdTimestamp: Moment;
  createdTimestampFormatted: string;
  eventType: string;
  details: string;
  level: number;
}

interface IRequestActivityViewModel {
  createdTimestamp: Moment;
  createdTimestampFormatted: string;
  state: string;
}

interface IRequestStateResponse {
  value: number;
  name: string;
}

export interface IRequestStateViewModel {
  value: number;
  name: string;
}

interface IApiInteractionEventViewModel {
  id: string;
  url: string;
  responseCode: string;
  errorResponse: string;
  requestTimestamp: Moment;
  responseTimestamp: Moment;
  requestTimestampFormated: string;
  responseTimestampFormated: string;
  cleanDateTime: Moment | undefined;
}

interface IRequestsApi {
  getRequests(
    page: number,
    pageSize: number,
    products: string[],
    clientId: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    middleName: string | undefined,
    documentNumber: string | undefined,
    documentSeries: string | undefined,
    vin: string | undefined,
    companyName: string | undefined,
    ogrn: string | undefined,
    inn: string | undefined,
    subjectFilterType: SubjectFilterType,
    createdFrom: string | undefined,
    createdTill: string | undefined,
    requestStates: number[]): Promise<IPagedList<IRequestListItem>>;
  getRequest(requestId: string): Promise<IRequestViewModel>;
  getProviderResponse(reportRequestId: string): Promise<string>;
  getRequestStates(): Promise<IRequestStateViewModel[]>;
  getApiInteractionRequest(apiInteractionEventId: string): Promise<string>;
  getApiInteractionResponse(apiInteractionEventId: string): Promise<string>;
}

export const useRequestsApi = (): IRequestsApi => {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  const getRequests =
    (page: number,
      pageSize: number,
      products: string[],
      clientId: string | undefined,
      firstName: string | undefined,
      lastName: string | undefined,
      middleName: string | undefined,
      documentNumber: string | undefined,
      documentSeries: string | undefined,
      vin: string | undefined,
      companyName: string | undefined,
      ogrn: string | undefined,
      inn: string | undefined,
      subjectFilterType: SubjectFilterType,
      createdFrom: string | undefined,
      createdTill: string | undefined,
      requestStates: number[]): Promise<IPagedList<IRequestListItem>> => {
      var url = `/api/requests?page=${page}&pageSize=${pageSize}`;

      if (clientId != null) {
        url += `&clientId=${clientId}`;
      }
      if (products.length > 0) {
        url += `&products=${products.join(';')}`;
      }
      if (requestStates?.length > 0) {
        requestStates.forEach(item => url += `&reportRequestStates=${item}`)
      }

      if (subjectFilterType == 'FL') {
        if (firstName) url += `&firstName=${firstName}`;
        if (lastName) url += `&lastName=${lastName}`;
        if (middleName) url += `&middleName=${middleName}`;
        if (documentNumber) url += `&documentNumber=${documentNumber}`;
        if (documentSeries) url += `&documentSeries=${documentSeries}`;
      }

      if (subjectFilterType == 'cars') {
        if (vin) url += `&vin=${vin}`;
      }

      if (subjectFilterType == 'JR') {
        if (companyName) url += `&companyName=${companyName}`;
        if (ogrn) url += `&ogrn=${ogrn}`;
        if (inn) url += `&inn=${inn}`;
      }

      if (createdFrom) {
        url += `&createdFrom=${moment(createdFrom).toISOString()}`;
      }
      if (createdTill) {
        url += `&createdTill=${moment(createdTill).toISOString()}`;
      }

      return makeReportGatewayRequest<IPagedList<IRequestListItemResponse>, IPagedList<IRequestListItem>>(
        url,
        'get',
        response => {
          var list = response.list.map<IRequestListItem>(value => {
            var date = moment(value.createdTimestamp)
            return {
              id: value.id,
              productName: value.productName,
              providerName: value.providerName,
              createdTimestamp: value.createdTimestamp,
              createdTimestampFormatted: date.format('DD.MM.YYYY HH:mm:ss Z'),
              subject: value.subject,
              clientName: value.clientName,
              state: value.state,
            }
          });
          return {
            list: list,
            page: response.page,
            pageSize: response.pageSize,
            totalRowCount: response.totalRowCount
          }
        },
        undefined
      );
    }

  const getRequest = (requestId: string): Promise<IRequestViewModel> => {
    return makeReportGatewayRequest<IRequestResponse, IRequestViewModel>(
      `/api/requests/${requestId}`,
      'get',
      response => {
        var events = response.events.map<IRequestEventViewModel>(value => {
          var date = moment(value.createdTimestamp)
          return {
            eventLogId: value.eventLogId,
            eventType: value.eventType,
            details: value.details,
            createdTimestamp: date,
            createdTimestampFormatted: date.format('DD.MM.YYYY HH:mm:ss:SSS Z'),
            eventDetails: value.eventDetails,
            level: value.level
          }
        });

        var activities = response.activities.map<IRequestActivityViewModel>(value => {
          var date = moment(value.createdTimestamp)
          return {
            state: value.state,
            createdTimestamp: date,
            createdTimestampFormatted: date.format('DD.MM.YYYY HH:mm:ss:SSS Z'),
          }
        });

        var providerRequests = response.providerRequests.map<IProviderRequestViewModel>(providerRequest => {
          const credentials = providerRequest.credentials ? {
            ...providerRequest.credentials,
            startDate: providerRequest.credentials?.startDate ? moment(providerRequest.credentials?.startDate) : null,
            endDate: providerRequest.credentials?.endDate ? moment(providerRequest.credentials?.endDate) : null,
          } : null
          var createDate = moment(providerRequest.createTimestamp);
          var apiInteractionEvents = providerRequest.apiInteractionEvents?.map<IApiInteractionEventViewModel>(value => {
            var requestDate = moment(value.requestTimestamp);
            var resposneDate = moment(value.responseTimestamp);
            return {
              ...value,
              requestTimestamp: requestDate,
              requestTimestampFormated: requestDate.format('DD.MM.YYYY HH:mm:ss:SSS Z'),
              responseTimestamp: resposneDate,
              responseTimestampFormated: resposneDate.format('DD.MM.YYYY HH:mm:ss:SSS Z'),
              cleanDateTime: value.cleanDateTime ? moment(value.cleanDateTime) : undefined
            }
          });
          return {
            ...providerRequest,
            credentials: credentials,
            createTimestamp: createDate,
            createdTimestampFormatted: createDate.format('DD.MM.YYYY HH:mm:ss:SSS Z'),
            apiInteractionEvents: apiInteractionEvents,
            cleanDateTime: providerRequest.cleanDateTime ? moment(providerRequest.cleanDateTime) : undefined
          }
        });

        var date = moment(response.createdTimestamp);

        const incomingRequest = {
          ...response.incomingRequest,
          requestDateTime: response.incomingRequest.requestDateTime
            ? moment(response.incomingRequest.requestDateTime)
            : undefined,
          responseDateTime: response.incomingRequest.responseDateTime
            ? moment(response.incomingRequest.responseDateTime)
            : undefined,
          cleanDateTime: response.incomingRequest.cleanDateTime
            ? moment(response.incomingRequest.cleanDateTime)
            : undefined
        }

        return {
          events: events,
          activities: activities,
          providerRequests: providerRequests,
          id: response.id,
          clientName: response.clientName,
          productName: response.productName,
          providerName: response.providerName,
          subject: response.subject,
          state: response.state,
          createdTimestamp: date,
          createdTimestampFormatted: date.format('DD.MM.YYYY HH:mm:ss:SSS Z'),
          reportFileId: response.reportFileId,
          reportType: response.reportType,
          hasPdfReport: response.hasPdfReport,
          providerPayDetails: response.providerPayDetails,
          providerPayType: response.providerPayType,
          incomingRequest: incomingRequest
        }
      },
      undefined
    );
  }

  const getProviderResponse = (reportRequestId: string): Promise<string> => {
    return makeReportGatewayRequest<string, string>(
      `/api/requests/providerResponse/${reportRequestId}`,
      'get',
      response => { return response; },
      undefined
    );
  }


  const getRequestStates = (): Promise<IRequestStateViewModel[]> => {
    return makeReportGatewayRequest<IRequestStateResponse[], IRequestStateViewModel[]>('api/requests/states',
      "get",
      response => {
        return response.map<IRequestStateViewModel>(item => item);
      },
      undefined
    );
  }
  const getApiInteractionRequest = (apiInteractionEventId: string): Promise<string> => {
    return makeReportGatewayRequest<string, string>(
      `/api/requests/apiInteractionRequest/${apiInteractionEventId}`,
      'get',
      response => { return response; },
      undefined
    );
  }

  const getApiInteractionResponse = (apiInteractionEventId: string): Promise<string> => {
    return makeReportGatewayRequest<string, string>(
      `/api/requests/apiInteractionResponse/${apiInteractionEventId}`,
      'get',
      response => { return response; },
      undefined
    );
  }

  return {
    getProviderResponse,
    getApiInteractionRequest,
    getApiInteractionResponse,
    getRequest,
    getRequests,
    getRequestStates
  }
}