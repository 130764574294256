import { Alert, Col, Empty, Radio, RadioChangeEvent, Row, Tree, Typography } from "antd";
import { DataNode } from "antd/es/tree";
import { BaseType } from "antd/es/typography/Base";
import React, { useCallback, useMemo, useState } from "react";

const { Text, Paragraph } = Typography;

function getTitle(key: string, value: string, type?: BaseType) {
  return (
    <Row gutter={8}>
      <Col><Text strong>{key}</Text></Col>
      <Col><Text type={type}>{value}</Text></Col>
    </Row>
  )
}

function mapObject(obj: object, level: number): DataNode[] {
  const result: DataNode[] = [];
  let index = 0;

  for (const key of Object.keys(obj)) {
    const current: DataNode = { key: `${level}-${index}-${key}` };
    result.push(current);

    const val: any = obj[key as keyof object];

    if (Array.isArray(val)) {
      current.title = getTitle(key, '[]', 'secondary');
      //current.icon = '[]';
      current.children = mapObject(val, level + 1);
    } else if (typeof (val) === 'object') {
      current.title = getTitle(key, '{}', 'secondary');
      //current.icon = '{}';
      current.children = mapObject(val, level + 1);
    } else {
      current.title = getTitle(`${key}:`, val);
    }

    index++;
  }

  return result;
}

const treeValue = 'tree';
const textValue = 'text';

interface IObjectVisualizerProps {
  objStr: string;
}

export function ObjectVisualizer(props: IObjectVisualizerProps) {
  const { objStr } = props;

  const obj = useMemo<object | null>(() => {
    if (objStr === '') return null;

    try {
      return JSON.parse(objStr);
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [objStr])

  const [mode, setMode] = useState(treeValue);

  const handleOnChangeMode = useCallback((e: RadioChangeEvent) => {
    setMode(e.target.value);
  }, [setMode])

  function renderAsTree() {
    return obj === null || Object.keys(obj).length === 0 ? (
      <Alert type='info' description='Пустой объект' />
    ) : (
      <Tree
        showLine
        blockNode
        selectable={false}
        treeData={mapObject(obj, 0)}
      />
    )
  }

  function renderAsText() {
    return (
      <Text>
        <pre style={{ margin: 0 }}>
          {objStr}
        </pre>
      </Text>
    )
  }

  return (
    <>
      <Radio.Group
        style={{ paddingBottom: '1rem' }}
        buttonStyle='solid'
        size='small'
        value={mode}
        onChange={handleOnChangeMode}
      >
        <Radio.Button value={treeValue}>Дерево</Radio.Button>
        <Radio.Button value={textValue}>Текст</Radio.Button>
      </Radio.Group>
      {mode === treeValue ? renderAsTree() : renderAsText()}
    </>
  )
}