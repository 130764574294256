import IProviderReferencesItem from "../../../Types/backend/providers/ProviderReferencesItem";
import { useMakeReportGatewayRequest } from "../makeReportGatewayRequest";

interface IProviderReferencesItemResponse {
  id: string;
  name: string;
}

interface IProvidersApi {
  references(): Promise<IProviderReferencesItem[]>;
}

export default function useProvidersApi(): IProvidersApi {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  function references(): Promise<IProviderReferencesItem[]> {
    return makeReportGatewayRequest<IProviderReferencesItemResponse[], IProviderReferencesItem[]>(
      '/api/providers/references',
      'get',
      response =>  response,
      null,
      'json'
    );
  }
  
  return {
    references
  }
}