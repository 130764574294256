import moment, { Moment, utc } from "moment";
import { IPagedList } from "../../../Types/PagedList";
import ICredentialsInfo from "../../../Types/backend/credentials/CredentialsInfo";
import ICredentialsListItem from "../../../Types/backend/credentials/CredentialsListItem";
import ICredentialsReferencesItem from "../../../Types/backend/credentials/CredentialsReferencesItem";
import { useMakeReportGatewayRequest } from "../makeReportGatewayRequest"

interface ICredentialsInfoResponse {
  id: string;
  description: string;
  organizationId: string;
  organizationName: string;
  disabled: boolean;
  value: string;
  signCertificateId: string | null;
  signCertificateThumbprint: string | null;
  encryptCertificateId: string | null;
  encryptCertificateThumbprint: string | null;
}

interface ICredentialsReferencesItemResponse {
  id: string;
  name: string;
  disabled: boolean;
}

interface ICredentialsListItemResponse {
  id: string;
  organizationId: string;
  organizationName: string;
  description: string;
  disabled: boolean;
  endDate: Date | null;
  reason: string | null;
  signCertificateId: string | null;
  signCertificateThumbprint: string | null;
  signCertificateEndDate: Date | null;
  encryptCertificateId: string | null;
  encryptCertificateThumbprint: string | null;
  encryptCertificateEndDate: Date | null;
}

interface ICredentialsApi {
  list(page: number, pageSize: number): Promise<IPagedList<ICredentialsListItem>>;
  info(id: string): Promise<ICredentialsInfo>;
  references(providerId: string): Promise<ICredentialsReferencesItem[]>;
  create(description: string, organizationId: string, signCertificateId: string | null,
    encryptCertificateId: string | null, value: string): Promise<void>;
  limit(id: string, from: Moment, reason: string): Promise<void>;
}

export default function useCredentialsApi(): ICredentialsApi {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  function limit(id: string, from: Moment, reason: string): Promise<void> {
    return makeReportGatewayRequest(
      `/api/credentials/${id}/limit`,
      'patch',
      () => {},
      {
        from: from,
        reason: reason
      },
      'json'
    )
  }

  function list(page: number, pageSize: number): Promise<IPagedList<ICredentialsListItem>> {
    const params = new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString()
    })
    return makeReportGatewayRequest<IPagedList<ICredentialsListItemResponse>, IPagedList<ICredentialsListItem>>(
      `/api/credentials?${params.toString()}`,
      'get',
      response => ({
        ...response,
        list: response.list.map(value => ({
          ...value,
          endDate: value.endDate === null ? null : utc(value.endDate),
          signCertificateEndDate: value.signCertificateEndDate === null ? null : moment(value.signCertificateEndDate),
          encryptCertificateEndDate: value.encryptCertificateEndDate === null ? null : moment(value.encryptCertificateEndDate)
        }))
      }),
      null,
      'json'
    )
  }

  function info(id: string): Promise<ICredentialsInfo> {
    return makeReportGatewayRequest<ICredentialsInfoResponse, ICredentialsInfo>(
      `/api/credentials/${id}/info`,
      'get',
      response => response,
      null,
      'json'
    )
  }

  function references(providerId: string): Promise<ICredentialsReferencesItem[]> {
    const params = new URLSearchParams();
    if (providerId) params.append('providerId', providerId);

    return makeReportGatewayRequest<ICredentialsReferencesItemResponse[], ICredentialsReferencesItem[]>(
      `/api/credentials/references`,
      'get',
      response => response,
      null,
      'json'
    )
  }

  function create(description: string, organizationId: string, signCertificateId: string | null,
    encryptCertificateId: string | null, value: string): Promise<void> {
      return makeReportGatewayRequest<void, void>(
        '/api/credentials',
        'post',
        () => {},
        {
          description: description,
          organizationId: organizationId,
          signCertificateId: signCertificateId,
          encryptCertificateId: encryptCertificateId,
          value: value
        },
        'json'
      );
  }

  return {
    list,
    info,
    references,
    create,
    limit
  }
}