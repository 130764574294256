import { Modal, PageHeader, Row, Col, Button, Empty } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { EndpointInfo } from "../../../components/Endpoints";

interface IPermissionEndpointInfoProps {
  title: string;
  id: string | null;
  onClose(): void;
}

export function PermissionEndpointInfo(props: IPermissionEndpointInfoProps) {
  const { title, id, onClose } = props;

  return (
    <Modal
      width={750}
      open={id != null}
      title={title}
      closable={false}
      maskClosable={false}
      footer={(
        <Row>
          {id && (
            <Col>
              {/* <Link to={`/endpoints/${id}`}>Перейти</Link> */}
            </Col>
          )}
          <Col flex={1} />
          <Col>
            <Button type='primary' onClick={onClose}>Закрыть</Button>
          </Col>
        </Row>
      )}
    >
      {id === null ? (
        <Empty />
      ) : (
        <EndpointInfo id={id} />
      )}
    </Modal>
  )
}