import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ContentWithMenu } from '../ContentWithMenu';
import { Dashboard } from '../Dashboard';
import { Login } from '../Login';
import { PrivateRoute } from '../PrivateRoute';
import { PublicRoute } from '../PublicRoute';
import { UserCard } from '../UserCard';
import { UserList } from '../UserList';
import { UserLocking } from '../UserLocking';
import { RequestList } from '../RequestList';
import { RequestCard } from '../RequestCard';
import {
  ProductRequestPackageList,
  ProductRequestPackageCreateCard } from '../ProductRequestPackage';
import { PermissionsList } from '../../views/Permissions';
import { CertificateCard, CertificatesList } from '../../views/Certificates';
import { CredentialsList } from '../../views/Credentials';
  
const Routes = () => {
  return (
    <Switch>
      <PublicRoute path="/login" component={Login} />

      <Route>
        <ContentWithMenu>
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute path="/users/creation" component={UserCard} />
            <PrivateRoute path="/users/:id/locking" component={UserLocking} />
            <PrivateRoute path="/users/:id" component={UserCard} />
            <PrivateRoute path="/users" component={UserList} />
            <PrivateRoute path="/requests/:id" component={RequestCard} />
            <PrivateRoute path="/requests" component={RequestList} />
            <PrivateRoute path="/productRequestPackages/creation" component={ProductRequestPackageCreateCard} />
            <PrivateRoute path="/productRequestPackages" component={ProductRequestPackageList} />
            <PrivateRoute path="/permissions" component={PermissionsList} />
            <PrivateRoute path="/certificates/:id" component={CertificateCard} />
            <PrivateRoute path="/certificates" component={CertificatesList} />
            <PrivateRoute path="/credentials" component={CredentialsList} />
          </Switch>
        </ContentWithMenu>
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;