import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { IAuthUser } from '../../../Auth';
import {
  IProductRequestPackageListItem
} from '../../../services/reportGateway/api/productRequestPackagesApi';

type TColumnProps = ColumnProps<IProductRequestPackageListItem>;

export const packagesListColumns = (
  handleDownloadRequest: (packageId: string) => void,
  handleDownloadResponse: (packageId: string) => void,
  currentUser: IAuthUser
): TColumnProps[] => [
    {
      title: '№',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
      width: 50,
    },
    {
      title: 'Дата создания',
      dataIndex: 'created',
      key: 'created',
      render: (text, record) => record.created.format('DD.MM.yyyy HH:mm:ss')
    },
    {
      title: 'Продукт',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Проект',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'Автор',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: 'Состояние',
      dataIndex: 'stateDescription',
      key: 'stateDescription',
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 220,
      render: (text, record) => (
        <Button
          onClick={() => handleDownloadRequest(record.id)}
          disabled={!currentUser?.canEditProductRequestPackages()}
          block
          icon={<DownloadOutlined />}
        >
          Скачать запрос
        </Button>
      ),
    },
    {
      width: 220,
      render: (text, record) => (
        <Button
          onClick={() => handleDownloadResponse(record.id)}
          disabled={!currentUser?.canEditProductRequestPackages() || !record.hasResult}
          block
          icon={<DownloadOutlined />}
        >
          Скачать ответ
        </Button>
      ),
    },
  ];