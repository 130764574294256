import IEndpointInfo from "../../../Types/backend/endpoints/EndpointInfo";
import IEndpointPurposeReferencesItem from "../../../Types/backend/endpoints/EndpointPurposeReferencesItem";
import IEndpointReferencesItem from "../../../Types/backend/endpoints/EndpointReferencesItem";
import { useMakeReportGatewayRequest } from "../makeReportGatewayRequest";

interface IEndpointReferencesItemResponse {
  id: string;
  name: string;
  purpose: string;
  url: string;
  hostHeader: string | null;
  disabled: boolean;
}

interface IEndpointInfoResponse {
  id: string;
  description: string;
  purpose: string;
  url: string;
  hostHeader: string | null;
  disabled: boolean;
  certificateId: string | null;
  certificateThumbprint: string | null;
}

interface IEndpointPurposeReferencesItemResponse {
  value: number;
  name: string;
}

interface IEndpointsApi {
  purposeReferences(): Promise<IEndpointPurposeReferencesItem[]>;
  references(providerId: string): Promise<IEndpointReferencesItem[]>;
  info(id: string): Promise<IEndpointInfo>;
  create(description: string, purpose: number, url: string, hostHeader: string | null, certificateId: string | null): Promise<void>;
}

export default function useEndpointsApi(): IEndpointsApi {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  function purposeReferences(): Promise<IEndpointPurposeReferencesItem[]> {
    return makeReportGatewayRequest<IEndpointPurposeReferencesItemResponse[], IEndpointPurposeReferencesItem[]>(
      '/api/endpoints/purposes/references',
      'get',
      response => response,
      null,
      'json'
    )
  }
  function references(providerId: string): Promise<IEndpointReferencesItem[]> {
    const params = new URLSearchParams()
    if (providerId) params.append('providerId', providerId);

    return makeReportGatewayRequest<IEndpointReferencesItemResponse[], IEndpointReferencesItem[]>(
      '/api/endpoints/references',
      'get',
      response => response,
      null,
      'json'
    )
  }

  function info(id: string): Promise<IEndpointInfo> {
    return makeReportGatewayRequest<IEndpointInfoResponse, IEndpointInfo>(
      `/api/endpoints/${id}/info`,
      'get',
      response => response,
      null,
      'json'
    )
  }

  function create(description: string, purpose: number, url: string, hostHeader: string | null, certificateId: string | null): Promise<void> {
    return makeReportGatewayRequest<void, void>(
      '/api/endpoints',
      'post',
      () => {},
      {
        description: description,
        purpose: purpose,
        url: url,
        hostHeader: hostHeader,
        certificateId: certificateId
      }
    )
  }

  return {
    purposeReferences,
    references,
    info,
    create
  }
}