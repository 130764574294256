import { Button, Col, Empty, Row, Tree, notification } from "antd";
import { ExclamationOutlined } from '@ant-design/icons';
import { DataNode } from "antd/lib/tree";
import React, { useEffect, useState } from "react";
import IEndpointReferencesItem from "../../../Types/backend/endpoints/EndpointReferencesItem";
import { EndpointAddition } from "../Addition/EndpointAddition";
import { EndpointInfo } from "../Info/EndpointInfo";

interface IEndpointSelectListProps {
  providerId: string;
  dataSource: IEndpointReferencesItem[];
  values?: string[];
  onChange?(values: string[]): void;
  refetch(): Promise<void>;
}

export function EndpointSelectList(props: IEndpointSelectListProps) {
  const { providerId, dataSource, onChange, refetch } = props;

  const [nodes, setNodes] = useState<DataNode[]>([]);
  const [values, setValues] = useState<string[]>(props.values ?? []);
  const [selected, setSelected] = useState<string[]>([]);
  const [additionOpen, setAdditionOpen] = useState(false);

  useEffect(() => {
    const endpointsMap: Record<string, IEndpointReferencesItem[]> = {};

    for (const item of dataSource) {
      const arr = endpointsMap[item.purpose] ?? [];
      endpointsMap[item.purpose] = [...arr, item];
    }

    setNodes(Object.keys(endpointsMap).map<DataNode>(purpose => ({
      title: purpose,
      key: purpose,
      checkable: false,
      selectable: false,
      children: endpointsMap[purpose].map(value => ({
        title: `${value.name} - ${value.url}`,
        icon: value.disabled ? <ExclamationOutlined /> : undefined,
        key: value.id,
      }))
    })));
  }, [dataSource])

  useEffect(() => {
    if (onChange) onChange(values);
  }, [values])

  function handleOnCheck(keys: any) {
    setValues(keys);
  }

  function handleOnSelect(keys: any) {
    setSelected(keys);
  }

  function handleOnAdditioOpen() {
    setAdditionOpen(true);
  }

  function handleOnAdditionCancel() {
    setAdditionOpen(false);
  }

  function handleOnAdditionOk() {
    setAdditionOpen(false);
    refetch();
    notification.success({
      message: 'Эндпоинт добавлен'
    });
  }

  return (
    <>
      <Row>
        <Col flex={1} />
        <Col>
          <Button
            onClick={handleOnAdditioOpen}
          >
            Добавить
          </Button>
        </Col>
      </Row>
      <Tree
        style={{ marginTop: '1rem', marginBottom: '1rem', maxHeight: '250px', overflow: 'auto' }}
        showLine
        selectable
        selectedKeys={selected}
        multiple={false}
        checkable
        checkedKeys={values}
        defaultExpandAll
        treeData={nodes}
        showIcon={true}
        onCheck={handleOnCheck}
        onSelect={handleOnSelect}
      />
      {selected.length > 0 ? (
        <>
          <EndpointInfo
            id={selected[0]}
            hideId
          />
          <Row style={{ marginTop: '1rem' }}>
            <Col>
              <Button>Редактировать</Button>
            </Col>
          </Row>
        </>
      ) : (
        <Empty />
      )}
      <EndpointAddition
        open={additionOpen}
        providerId={providerId}
        onOk={handleOnAdditionOk}
        onCancel={handleOnAdditionCancel}
      />
    </>
  )
}