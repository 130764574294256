import React, { createElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../../Auth';

const PublicRoute = (props: RouteProps) => {
  const { checkAuth } = useAuth();
  const { render, children, component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(renderProps) => {
        if (!checkAuth()) {
          if (render) {
            return render(renderProps);
          }

          if (component) {
            return createElement(component);
          }

          if (children) {
            return children;
          }
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

export default PublicRoute;
