import Axios, { Method as AxiosMethod, AxiosError, ResponseType } from "axios";

export interface HttpError {
    statusCode: number | undefined;
    data: any;
}

export type HttpMethod = "get" | "post" | "patch";
export type ContentType = "json" | "zip" | "csv" | "txt" | "form";

interface IContentTypeConfig {
    contentTypeHeader: string;
    acceptHeader: string;
    responseType?: ResponseType;
}

const contentTypeConfigMap: Record<ContentType, IContentTypeConfig> = {
    "json": {
        contentTypeHeader: 'application/json',
        acceptHeader: 'application/json',
    },
    "zip": {
        contentTypeHeader: 'application/zip',
        acceptHeader: 'application/zip',
        responseType: 'arraybuffer'
    },
    "csv": {
        contentTypeHeader: 'text/csv',
        acceptHeader: 'text/csv',
        responseType: 'arraybuffer'
    },
    "txt": {
        contentTypeHeader: 'text/plain',
        acceptHeader: 'text/plain',
        responseType: 'arraybuffer'
    },
    'form': {
        contentTypeHeader: 'multipart/form-data',
        acceptHeader: 'application/json',
    }
}

const httpClient = {
    makeRequest<TResponse, TResult>(
        url: string,
        method: HttpMethod,
        transformResponse: (response: TResponse) => TResult,
        requestData: any,
        contentType: ContentType
    ): Promise<TResult> {
        const contentConfig = contentTypeConfigMap[contentType];
        const axiosMethod = method as AxiosMethod;
        const headers = {
            ContentType: contentConfig.contentTypeHeader,
            Accept: contentConfig.acceptHeader
        };
        return new Promise((resolve, reject) => {
            Axios.request<TResponse>({
                method: axiosMethod,
                url: url,
                data: requestData,
                headers: { ...headers },
                responseType: contentConfig.responseType
            }).then(response => {
                resolve(transformResponse(response.data));
            }).catch((error: AxiosError) => {
                reject({
                    statusCode: error?.response?.status,
                    data: error?.response?.data
                });
            });
        });
    }
};

export default httpClient;