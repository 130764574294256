import { Radio } from 'antd';
import { RadioProps as AntdRadioProps } from 'antd/lib/radio/interface';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldLabel } from '../FieldLabel';
import { RadioProps, RawRadioProps } from './types';
import cn from 'classnames';
import './CRadio.scss';
import { ICFieldLabelProps } from '../FieldLabel/types';

export function RadioComponent({
  label,
  labelInfo,
  popoverWidth,
  className,
  ellipsis,
  ...props
}: AntdRadioProps & ICFieldLabelProps) {
  return (
    <Radio {...props} className={cn('cradio', className, { ellipsis })}>
      <FieldLabel
        ellipsis={ellipsis}
        label={label}
        labelInfo={labelInfo}
        popoverWidth={popoverWidth}
        required={false}
      />
    </Radio>
  );
}

function RawRadio({
  input,
  label,
  labelInfo,
  popoverWidth,
  disabled,
}: RawRadioProps) {
  return (
    <RadioComponent
      {...input}
      label={label}
      labelInfo={labelInfo}
      popoverWidth={popoverWidth}
      disabled={disabled}
    />
  );
}

export function CRadio(inputParams: RadioProps) {
  return (
    <Field {...inputParams} type="radio">
      {(renderProps) => {
        return <RawRadio {...renderProps} />;
      }}
    </Field>
  );
}
