import { Select, Skeleton } from "antd";
import React, { useEffect } from "react";
import useOrganizationsApi from "../../../services/reportGateway/api/organizationsApi";
import { useQuery } from "react-query";
import { DefaultOptionType, SelectProps } from "antd/lib/select";
import { Moment } from "moment";

interface OrganizationSelectProps extends Omit<SelectProps, 'options | loading | status'> {
  updated?: Moment;
}

export function OrganizationSelect(props: OrganizationSelectProps) {
  const {updated, ...otherProps} = props;

  const organizationsApi = useOrganizationsApi();

  const { data: organizations, error, isLoading, isFetching, refetch } = useQuery(
    ['organizations', 'select'],
    fetchData,
    { retry: 3 }
  )

  async function fetchData(): Promise<DefaultOptionType[]> {
    const response = await organizationsApi.references();

    return response.map<DefaultOptionType>(value => ({
      label: value.name,
      value: value.id
    }))
  }

  useEffect(() => {
    refetch();
  }, [updated])

  return isLoading ? (
    <Skeleton.Input
      style={{ width: '100%' }}
      active
    />
  ): (
    <Select
      allowClear
      {...otherProps}
      options={organizations}
      loading={isLoading || isFetching}
      status={error ? 'error' : undefined}
    />
  )
}