import moment, { Moment } from 'moment';
import { useMakeReportGatewayRequest } from '../makeReportGatewayRequest';
import { IPagedList } from '../../../Types/PagedList';
import PackageCreatingResultType from '../../../Types/PackageCreatingResultType';

interface IProductRequestPackageListItemResponse {
  id: string;
  created: Date;
  productName: string;
  providerName: string;
  projectName: string;
  author: string;
  stateDescription: string;
  isCompleted: boolean;
  hasResult: boolean;
}

export interface IProductRequestPackageListItem {
  id: string;
  created: Moment;
  author: string;
  productName: string;
  projectName: string;
  stateDescription: string;
  isCompleted: boolean;
  hasResult: boolean;
}

interface IProductRequestPackageStateResponse {
  id: string;
  stateDescription: string;
  isCompleted: boolean;
  hasResult: boolean;
}

export interface IProductRequestPackageState {
  id: string;
  stateDescription: string;
  isCompleted: boolean;
  hasResult: boolean;
}

interface IProductRequestPackageAvailableProductResponse {
  id: string;
  name: string;
  providerName: string;
}

export interface IProductRequestPackageAvailableProduct {
  id: string;
  name: string;
}

interface IProductRequestPackageProjectResponse {
  id: string;
  name: string;
}

export interface IProductRequestPackageProject {
  id: string;
  name: string;
}

interface IProductRequestPackageCreatingResultResponse {
  type: PackageCreatingResultType;
}

export interface IProductRequestPackageCreatingResult {
  type: PackageCreatingResultType;
}

interface IProductRequestPackagesApi {
  createPackage(productId: string, projectId: string, file: Blob): Promise<IProductRequestPackageCreatingResult>;
  getPackages(page: number, pageSize: number): Promise<IPagedList<IProductRequestPackageListItem>>;
  getAvailableProducts(): Promise<IProductRequestPackageAvailableProduct[]>;
  getProjects(): Promise<IProductRequestPackageProject[]>;
  getRequestData(packageId: string): Promise<Blob>;
  getResponseData(packageId: string): Promise<Blob>;
  getStates(ids: string[]): Promise<IProductRequestPackageState[]>;
}

export const useProductRequestPackagesApi = (): IProductRequestPackagesApi => {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  const createPackage = (productId: string, projectId: string, file: Blob) => {
    const data = new FormData();
    data.append('productId', productId);
    data.append('projectId', projectId);
    data.append('file', file);
    
    return makeReportGatewayRequest<IProductRequestPackageCreatingResultResponse, IProductRequestPackageCreatingResult>(
      '/api/productRequestPackages/create',
      'post',
      response => response,
      data
    );
  }

  const getPackages = (page: number, pageSize: number) => {
    return makeReportGatewayRequest<IPagedList<IProductRequestPackageListItemResponse>, IPagedList<IProductRequestPackageListItem>>(
      `/api/productRequestPackages?page=${page}&pageSize=${pageSize}`,
      'get',
      response => {
        const list = response.list.map(value => ({
          ...value,
          productName: `${value.productName} ${value.providerName}`,
          created: moment.utc(value.created).local(),
        }));

        return { ...response, list: list };
      },
      undefined
    );
  }

  const getStates = (ids: string[]) => {
    const packagesId = ids.map(value => `packagesId=${value}`).join('&');
    const encodedPackagesId = encodeURI(packagesId);
    return makeReportGatewayRequest<IProductRequestPackageStateResponse[], IProductRequestPackageState[]>(
      `/api/productRequestPackages/states?${encodedPackagesId}`,
      'get',
      response => response,
      undefined
    );
  }

  const getAvailableProducts = () => {
    return makeReportGatewayRequest<IProductRequestPackageAvailableProductResponse[], IProductRequestPackageAvailableProduct[]>(
      '/api/productRequestPackages/availableProducts',
      'get',
      response => response
        .sort((a, b) => a.providerName
        .localeCompare(b.providerName))
        .map(value => ({
          id: value.id,
          name: `${value.name} ${value.providerName}`
        })),
      undefined
    );
  }

  const getProjects = () => {
    return makeReportGatewayRequest<IProductRequestPackageProjectResponse[], IProductRequestPackageProject[]>(
      '/api/productRequestPackages/projects',
      'get',
      response => response.map(value => ({...value})),
      undefined
    );
  }

  const getRequestData = (packageId: string) => {
    return makeReportGatewayRequest<Blob, Blob>(
      `/api/productRequestPackages/${packageId}/requestData`,
      'get',
      response => response,
      undefined,
      "csv"
    );
  }

  const getResponseData = (packageId: string) => {
    return makeReportGatewayRequest<Blob, Blob>(
      `/api/productRequestPackages/${packageId}/responseData`,
      'get',
      response => response,
      undefined,
      "csv"
    );
  }

  return {
    createPackage,
    getPackages,
    getAvailableProducts,
    getProjects,
    getRequestData,
    getResponseData,
    getStates
  }
}