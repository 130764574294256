import moment, { Moment } from 'moment';
import { useMakeReportGatewayRequest } from '../makeReportGatewayRequest';

interface IUserResponse {
  id: string;
  userName: string;
  roleId: string;
  isLockedOut: boolean;
}

export interface IUser {
  id: string;
  userName: string;
  roleId: string;
  isLockedOut: boolean;
}

interface IUsersListItemResponse {
  id: string;
  userName: string;
  roleName: string;
  isLockedOut: boolean;
}

export interface IUsersListItem {
  id: string;
  userName: string;
  roleName: string;
  isLockedOut: boolean;
}

interface IUserLockingResponse {
  userName: string;
  lockoutEnd: Date;
}

export interface IUserLocking {
  userName: string;
  lockoutEnd: Moment | null;
}

interface IUsersApi {
  createUser(userName: string, password: string, confirmPassword: string, roleName: string): Promise<void>;
  editUser(userId: string, userName: string, roleName: string): Promise<void>;
  getUserLocking(userId: string): Promise<IUserLocking>;
  setUserLocking(userId: string, lockoutEnd: Moment | null): Promise<void>;
  getUser(userId: string): Promise<IUser>;
  getUsers(): Promise<IUsersListItem[]>;
}

export const useUsersApi = (): IUsersApi => {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  const createUser = (userName: string, password: string, confirmPassword: string, roleId: string): Promise<void> => {
    return makeReportGatewayRequest<string, void>(
      '/api/users/create',
      'post',
      () => { },
      {
        userName: userName,
        password: password,
        confirmPassword: confirmPassword,
        roleId: roleId
      }
    );
  }

  const editUser = (userId: string, userName: string, roleId: string): Promise<void> => {
    return makeReportGatewayRequest<void, void>(
      `/api/users/${userId}/edit`,
      'post',
      () => { },
      {
        userName: userName,
        roleId: roleId
      }
    );
  }

  const getUserLocking = (userId: string): Promise<IUserLocking> => {
    return makeReportGatewayRequest<IUserLockingResponse, IUserLocking>(
      `/api/users/${userId}/lock`,
      'get',
      response => ({
        ...response,
        lockoutEnd: response.lockoutEnd ? moment.utc(response.lockoutEnd).local() : null
      }),
      undefined
    );

  }

  const setUserLocking = (userId: string, lockoutEnd: Moment | null): Promise<void> => {
    return makeReportGatewayRequest<void, void>(
      `/api/users/${userId}/lock`,
      'post',
      () => { },
      {
        lockoutEnd: lockoutEnd?.utc()
      }
    );
  }

  const getUser = (userId: string): Promise<IUser> => {
    return makeReportGatewayRequest<IUserResponse, IUser>(
      `/api/users/${userId}`,
      'get',
      response => ({ ...response }),
      undefined
    );
  }

  const getUsers = (): Promise<IUsersListItem[]> => {
    return makeReportGatewayRequest<IUsersListItemResponse[], IUsersListItem[]>(
      '/api/users',
      'get',
      response => {
        return response.map<IUsersListItem>(value => ({ ...value }));
      },
      undefined
    );
  }

  return {
    createUser,
    editUser,
    getUserLocking,
    setUserLocking,
    getUser,
    getUsers,
  }
}