import create from 'zustand';

export interface FilterValues {
}

export const emptyFilter: FilterValues = {}

export const defaultPage = 1;
export const defaultPageSize = 20;

interface Paging {
  page: number;
  pageSize: number;
}

const defaultPaging: Paging = {
  page: defaultPage,
  pageSize: defaultPageSize
}

interface RequestData {
  filter: FilterValues;
  paging: Paging;
}

type Config = {
  isFilterOpen: boolean;
  openFilter: () => void;
  closeFilter: () => void;
  requestData: RequestData;
  setFilter: (value: FilterValues) => void;
  resetFilter: () => void;
  setPaging: (page: number, pageSize: number) => void;
}

export const useCertificatesTableConfig = create<Config>((set, get) => ({
  isFilterOpen: false,
  openFilter: () => {
    set({ isFilterOpen: true });
  },
  closeFilter: () => {
    set({ isFilterOpen: false });
  },
  requestData: {
    filter: emptyFilter,
    paging: defaultPaging
  },
  setFilter: (value: FilterValues) => {
    set({
      requestData: {
        filter: value,
        paging: defaultPaging
      }
    });
  },
  resetFilter: () => {
    set({
      requestData: {
        filter: emptyFilter,
        paging: defaultPaging
      }
    });
  },
  setPaging: (page: number, pageSize: number) => {
    const currentConfig = get();
    set({
      requestData: {
        ...currentConfig.requestData,
        paging: {
          page: page,
          pageSize: pageSize
        }
      }
    });
  }
}));