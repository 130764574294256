import moment, { Moment, utc } from "moment";
import { IPagedList } from "../../../Types/PagedList"
import IPermissionsListItem from "../../../Types/backend/permissions/PermissionsListItem"
import { useMakeReportGatewayRequest } from "../makeReportGatewayRequest";
import { IClientPermissionHierarchyReferencesItem } from "../../../Types/backend/permissions/PermissionHierarchyReferencesItem";

interface IPermissionsEndpointListItemResponse {
  id: string;
  url: string;
  hostHeader: string | null;
  purpose: string;
  disabled: boolean;
}

interface IPermissionsListItemResponse {
  id: string;
  clientId: string;
  clientName: string;
  clientDisabled: boolean;
  providerId: string;
  providerName: string;
  providerDisabled: boolean;
  productId: string | null;
  productName: string | null;
  productDisabled: boolean;
  credentialsId: string;
  credentialsComment: string | null;
  credentialsDisabled: boolean;
  startDate: Date;
  endDate: Date | null;
  endpoints: IPermissionsEndpointListItemResponse[];
}

interface IClientPermissionHierarchyReferencesItemResponse {
  id: string;
  name: string;
  children: IProviderPermissionHierarchyReferencesItemResponse[];
}

interface IProviderPermissionHierarchyReferencesItemResponse {
  id: string;
  name: string;
  children: IPermissionHierarchyReferencesItemResponse[];
}

interface IPermissionHierarchyReferencesItemResponse {
  id: string;
  productName: string;
  providerName: string;
  credentialsDescription: string;
  endDate: Date | null;
}

interface IPermissionsApi {
  list(page: number, pageSize: number): Promise<IPagedList<IPermissionsListItem>>;
  create(clientId: string, providerId: string | null, productId: string | null, credentialsId: string,
    startDate: Moment, endDate: Moment | null, endpoints: string[]): Promise<void>;
  export(items: string[]): Promise<string>;
  limit(id: string, from: Moment): Promise<void>;
  hierarchyReferences(): Promise<IClientPermissionHierarchyReferencesItem[]>;
}

export default function usePermissionsApi(): IPermissionsApi {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  function list(page: number, pageSize: number): Promise<IPagedList<IPermissionsListItem>> {
    const params = new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
    });

    return makeReportGatewayRequest<IPagedList<IPermissionsListItemResponse>, IPagedList<IPermissionsListItem>>(
      `/api/permissions?${params.toString()}`,
      'get',
      response => ({
        ...response,
        list: response.list.map(value => ({
          ...value,
          startDate: utc(value.startDate,),
          endDate: value.endDate === null ? null : utc(value.endDate)
        }))
      }),
      null,
      'json'
    )
  }

  function create(clientId: string, providerId: string | null, productId: string | null, credentialsId: string,
    startDate: Moment, endDate: Moment | null, endpoints: string[]): Promise<void> {
    return makeReportGatewayRequest<void, void>(
      '/api/permissions',
      'post',
      () => { },
      {
        clientId: clientId,
        providerId: providerId,
        productId: productId,
        credentialsId: credentialsId,
        startDate: startDate.utc().format('YYYY-MM-DDTHH:mm'),
        endDate: endDate?.utc().format('YYYY-MM-DDTHH:mm'),
        endpoints: endpoints
      },
      'json'
    )
  }

  function exportItems(items: string[]): Promise<string> {
    const params = new URLSearchParams();
    for (const item of items) params.append('ids', item)
    return makeReportGatewayRequest<string, string>(
      `/api/permissions/export?${params.toString()}`,
      'get',
      response => response,
      null,
      'txt'
    )
  }

  function limit(id: string, from: Moment): Promise<void> {
    return makeReportGatewayRequest<void, void>(
      `/api/permissions/${id}/limit`,
      'post',
      () => {},
      {
        from: from.format('yyyy-MM-DDTHH:mm:ss')
      },
      'json'
    )
  }

  function hierarchyReferences(): Promise<IClientPermissionHierarchyReferencesItem[]> {
    return makeReportGatewayRequest<IClientPermissionHierarchyReferencesItemResponse[], IClientPermissionHierarchyReferencesItem[]>(
      '/api/permissions/references/hierarchy',
      'get',
      response => response.map(a => ({
        ...a,
        children: a.children.map(b => ({
          ...b,
          children: b.children.map(c => ({
            ...c,
            endDate: c.endDate === null ? null : utc(c.endDate)
          }))
        }))
      })),
      null,
      'json'
    )
  }

  return {
    list,
    create,
    export: exportItems,
    limit,
    hierarchyReferences
  }
}