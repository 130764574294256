import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import ICertificatesListItem from '../../../Types/backend/certificates/CertificatesListItem';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import certificateFieldParser from '../../../services/certificateFieldParser';

export function getColumns(): ColumnProps<ICertificatesListItem>[] {
  return [
    {
      title: '#',
      key: 'index',
      width: 70,
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Описание',
      key: 'description',
      render(value, record, index) {
        return (
          <Link to={`/certificates/${record.id}`}>
            {record.description}
          </Link>
        )
      },
    },
    {
      title: 'Организация',
      key: 'organizationName',
      render(value, record, index) {
        return record.organizationName ?? '-'
      },
    },
    {
      title: 'Владелец',
      key: 'subject',
      render(value, record, index) {
        return record.subject === null
          ? '-'
          : certificateFieldParser(record.subject, 'CN', 'O');
      },
    },
    {
      title: 'Отпечаток',
      key: 'thumbprint',
      render(value, record, index) {
        return record.thumbprint ?? '-'
      },
    },
    {
      title: 'Период',
      key: 'period',
      render(value, record, index) {
        return (
          `${record.startDate.format('DD.MM.YYYY')} - ${record.endDate.format('DD.MM.YYYY')}`
        )
      },
    },
    {
      key: 'actions',
      render(value, record, index) {
        return null
      },
    }
  ]
}