import { Form, Input } from 'antd';
import React from 'react';
import { useField } from 'react-final-form';
import { CInputProps, RowInputProps } from './types';

function RawInput({
  label,
  input,
  meta,
  hint,
  className,
  handleChange,
  autoComplete,
  placeholder,
  disabled,
  size = 'large',
  ...rest
}: RowInputProps) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    input.onChange(event);
    if (handleChange) {
      handleChange(event.target.value);
    }
  };

  return (
    <Form.Item
      label={label}
      validateStatus={
        meta.touched &&
        (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit))
          ? 'error'
          : ''
      }
      help={
        (meta.touched && meta.error) ||
        (meta.submitError && !meta.dirtySinceLastSubmit)
          ? meta.error || meta.submitError
          : '' || hint
      }
      className={className}
    >
      <Input
        {...rest}
        {...input}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        size={size}
        autoComplete={autoComplete || 'off'}
      />
    </Form.Item>
  );
}

export function CInput({ name, ...rest }: CInputProps) {
  const renderProps = useField<string>(name, {
    parse: rest.parse ? rest.parse : (v) => v,
  });
  return <RawInput {...renderProps} {...rest} />;
}
