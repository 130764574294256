import { Button, Col, Form, Row, Typography } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../Auth';
import { convertFormError, fromReportGatewayError } from '../../Types/Error';
import { CForm } from '../CForm';
import { CInput } from '../CInput';
import { ErrorsList } from '../ErrorsList';
const { Title } = Typography;

const initialValues = {
  UserName: '',
  Password: '',
};

type SubmitValues = typeof initialValues;

function Login() {
  const unmounted = useRef(false);
  const { signin } = useAuth((state) => state);
  const location = useLocation<{ from: { pathname: string } }>();
  const { from } = location.state || { from: { pathname: '/' } };
  const history = useHistory();

  useEffect(() => {
    return function cleanup() {
      unmounted.current = true;
    };
  }, []);

  async function onSubmit(values: SubmitValues) {
    try {
      await signin(values.UserName, values.Password, () => {
        history.replace(from);
      });
    } catch (error: any) {
      if (!unmounted.current) {
        const errors = fromReportGatewayError(error);

        return convertFormError(errors);
      }
    }
  }

  return (
    <div className="container">
      <Row justify="center" className="u-mt-9">
        <Col span={23} xs={20} sm={16} md={12} lg={12} xl={7}>
          <Title className="u-mb-3" level={2}>
            Вход
          </Title>

          <CForm<SubmitValues>
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={(values) => {
              const errors: Partial<SubmitValues> = {};
              if (!values.UserName) {
                errors.UserName = 'Заполните поле';
              }
              if (!values.Password) {
                errors.Password = 'Заполните поле';
              }
              return errors;
            }}
          >
            {({ values, submitting, submitErrors }) => (
              <>
                <CInput name="UserName" label="Логин" />
                <CInput
                  name="Password"
                  label="Пароль"
                  type="password"
                  autoComplete="current-password"
                />

                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                    icon={<ImportOutlined />}
                  >
                    Войти
                  </Button>
                </Form.Item>
                {submitErrors && <ErrorsList errors={submitErrors} />}
              </>
            )}
          </CForm>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
