import IOrganizationReferencesItem from "../../../Types/backend/organization/OrganizationReferencesItem";
import { useMakeReportGatewayRequest } from "../makeReportGatewayRequest";

interface IOrganizationReferencesItemResponse {
  id: string;
  name: string;
}

interface IOrganizationsApi {
  create(name: string, ogrn: string, inn: string): Promise<void>;
  references(): Promise<IOrganizationReferencesItem[]>;
}

export default function useOrganizationsApi(): IOrganizationsApi {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  function create(name: string, ogrn: string, inn: string): Promise<void> {
    return makeReportGatewayRequest<void, void>(
      '/api/organizations',
      'post',
      () => {},
      {
        name: name,
        ogrn: ogrn,
        inn: inn
      }
    )
  }

  function references(): Promise<IOrganizationReferencesItem[]> {
    return makeReportGatewayRequest<IOrganizationReferencesItemResponse[], IOrganizationReferencesItem[]>(
      '/api/organizations/references',
      'get',
      response => response,
      null,
      'json'
    );
  }
  return {
    create,
    references
  }
}