import React, { useContext, useMemo } from "react";
import { CertificateCardContext } from "./../CertificateCard";
import { useAuth } from "../../../../Auth";
import useCertificatesApi from "../../../../services/reportGateway/api/certificatesApi";
import { defaultPageSize, useTableConfig } from "./CertificateCardCredentialsTableConfig";
import { useQuery } from "react-query";
import { ICertificateCardCredentialsListItem } from "../../../../Types/backend/certificates/CertificateCard";
import { IPagedList } from "../../../../Types/PagedList";
import { Table, TablePaginationConfig } from "antd";
import { getColumns } from "./CertificateCardCredentialsTableColumns";
import { Wrapper } from "../../../../components/Wrapper";
import { fromReportGatewayError } from "../../../../Types/Error";


export function CertificateCardCredentialsTable() {
  const { id, updated } = useContext(CertificateCardContext);
  const { currentUser } = useAuth();
  const canView = useMemo(() => currentUser?.canViewPermissions() ?? false, [currentUser])

  const api = useCertificatesApi();
  const { requestData, setPaging } = useTableConfig();
  const { filter, paging } = requestData;

  const { data, error, isLoading } = useQuery(
    ['certificates', 'card', id, 'credentials', canView, filter, paging, updated],
    fetchData,
    { enabled: canView }
  )

  async function fetchData(): Promise<IPagedList<ICertificateCardCredentialsListItem> | undefined> {
    if (!canView) return undefined;

    const response = await api.cardCredentials(id, requestData.paging.page, requestData.paging.pageSize);

    return {
      ...response,
      list: response.list.map(value => ({
        ...value,
        key: `credentials_${value.id}`
      }))
    }
  }

  const initErrors = useMemo(() => error ? fromReportGatewayError(error as any) : [], [error])

  function handleOnChange(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPageSize, pagination.pageSize ?? defaultPageSize);
  }

  return (
    <Wrapper
      state={error ? 'hasErrors' : null}
      errors={initErrors}
    >
      <Table
        style={{ marginTop: '1rem' }}
        scroll={{ x: 900 }}
        loading={isLoading}
        onChange={handleOnChange}
        columns={getColumns()}
        dataSource={data?.list}
        pagination={{
          current: paging.page,
          position: ['bottomRight'],
          pageSize: paging.pageSize,
          total: data?.totalRowCount,
          showSizeChanger: false
        }}
      />
    </Wrapper>
  )
}