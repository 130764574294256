import React from 'react';
import { InfoPopover } from '../InfoPopover';
import './';
import cn from 'classnames';
import './FieldLabel.scss';
import { ICFieldLabelProps } from './types';

export function FieldLabel({
  label,
  labelInfo,
  required = true,
  popoverWidth,
  ellipsis,
}: ICFieldLabelProps) {
  return (
    <div
      className={cn('clabel', { ellipsis })}
      title={(ellipsis && typeof label === 'string' && label) || undefined}
    >
      <div className="clabel__content">
        <div className="clabel__text">{label}</div>
        {labelInfo && (
          <InfoPopover popoverWidth={popoverWidth} info={labelInfo} />
        )}
      </div>
      {required && <div className="clabel__required">Required</div>}
    </div>
  );
}
