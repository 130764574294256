import { Form as FForm, FormProps } from 'react-final-form';
import React from 'react';
import { Form } from 'antd';
import { Debugger } from '../Debugger';

export function CForm<Values>({
  children,
  showDebug,
  autoComplete = 'false',
  ...props
}: FormProps<Values> & { autoComplete?: string; showDebug?: boolean }) {
  return (
    <FForm {...props}>
      {(renderProps) => {
        return (
          <Form
            layout="vertical"
            onFinish={renderProps.handleSubmit}
            autoComplete={autoComplete}
          >
            {typeof children === 'function' ? children(renderProps) : children}
            {showDebug && <Debugger values={renderProps.values} />}
          </Form>
        );
      }}
    </FForm>
  );
}
