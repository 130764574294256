import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { Button, Dropdown, Menu, PageHeader, Table, notification } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Wrapper, WrapperState } from '../../Wrapper';
import { useAuth } from '../../../Auth';
import {
  Error,
  fromReportGatewayError
} from '../../../Types/Error';
import {
  IProductRequestPackageListItem,
  IProductRequestPackageAvailableProduct,
  useProductRequestPackagesApi
} from '../../../services/reportGateway/api/productRequestPackagesApi';
import { packagesListColumns } from './ProductRequestPackageListColumns';
import { saveFile } from '../../../services/files';

interface IPackageTableItem extends IProductRequestPackageListItem {
  key: string;
}

export const ProductRequestPackageList = () => {
  const defaultPageSize = 20;
  const defaultPageNumber = 1;
  const unmounted = useRef(false);
  const intervalId = useRef<number | undefined>(undefined);
  const history = useHistory();
  const productRequestPackagesApi = useProductRequestPackagesApi();
  const { currentUser } = useAuth();
  const [packages, setPackages] = useState<IPackageTableItem[]>([]);
  const [availableProducts, setAvailableProducts] = useState<IProductRequestPackageAvailableProduct[]>([]);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [isPackagesLoading, setIsPackagesLoading] = useState(false);
  const [initErrors, setInitErrors] = useState<Error[]>([]);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [pagingInfo, setPagingInfo] = useState<TablePaginationConfig>({ current: defaultPageNumber, pageSize: defaultPageSize });
  const [totalCount, setTotalCount] = useState(0);
  const [updated, setUpdated] = useState<Moment | null>();

  useEffect(() => {
    return () => {
      unmounted.current = true;

      if (intervalId.current) {
        window.clearInterval(intervalId.current);
      }
    }
  }, [])

  async function getProducts() {
    setIsProductsLoading(true);
    try {
      const receivedAvailableProducts = await productRequestPackagesApi.getAvailableProducts();
      if (!unmounted.current) {
        setAvailableProducts(receivedAvailableProducts);
      }
    } catch (error) {
      throw error;
    } finally {
      if (!unmounted.current) {
        setIsProductsLoading(false);
      }
    }
  }

  async function getPackages(page: number, pageSize: number) {
    setIsPackagesLoading(true);
    try {
      const receivedPackages = await productRequestPackagesApi.getPackages(page, pageSize);
      if (!unmounted.current) {
        setPackages(receivedPackages.list.map((value, index) => ({
          key: `package_${index}`,
          ...value
        })));
        setTotalCount(receivedPackages.totalRowCount);
      }
    } catch (error) {
      throw error;
    } finally {
      if (!unmounted.current) {
        setIsPackagesLoading(false);
      }
    }
  }

  useEffect(() => {
    async function fetchData() {
      setInitErrors([]);
      try {
        await getProducts();
        await getPackages(pagingInfo.current ?? defaultPageNumber, pagingInfo.pageSize ?? defaultPageSize);
      } catch (error: any) {
        if (!unmounted.current) {
          setInitErrors(fromReportGatewayError(error));
        }
      }
    }

    if (currentUser?.canViewProductRequestPackages()) {
      fetchData();
    }
  }, [currentUser])

  useEffect(() => {
    if (intervalId.current) {
      window.clearInterval(intervalId.current);
      intervalId.current = undefined;
    }
    if (!unmounted.current && packages.length > 0) {
      intervalId.current = window.setInterval(async () => {
        const items = packages.filter(value => !value.isCompleted);
        const states = await productRequestPackagesApi.getStates(items.map(value => value.id));
        states.forEach(value => {
          const findedPackage = packages.find(findPackage => findPackage.id === value.id);
          if (!unmounted.current && findedPackage) {
            findedPackage.stateDescription = value.stateDescription;
            findedPackage.isCompleted = value.isCompleted;
            findedPackage.hasResult = value.hasResult;
          }
        });
        setUpdated(moment());
      }, 10000);
    }
  }, [packages])

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    }
    else if (!currentUser.canViewProductRequestPackages()) {
      setWrapperState('accessDenied');
    } else if (initErrors && initErrors.length > 0) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, initErrors]);

  function handleMenuClick(productId: string) {
    history.push(`/productRequestPackages/creation?productId=${productId}`);
  }

  async function handleDownloadRequest(packageId: string) {
    try {
      const data = await productRequestPackagesApi.getRequestData(packageId);
      if (data) {
        saveFile(data, 'text/csv', `PackageRequestData_${packageId}`, 'csv');
      }
      else {
        notification.warning({
          message: 'Файл запроса отсутствует',
          placement: 'bottomRight'
        });  
      }
    }
    catch {
      notification.error({
        message: 'Не удалось загрузить файл запроса',
        placement: 'bottomRight'
      });
    }
  }

  async function handleDownloadResponse(packageId: string) {
    try {
      const data = await productRequestPackagesApi.getResponseData(packageId);
      if (data) {
        saveFile(data, 'text/csv', `PackageResponseData_${packageId}`, 'csv');
      }
      else {
        notification.warning({
          message: 'Файл ответа отсутствует',
          placement: 'bottomRight'
        });  
      }
    }
    catch {
      notification.error({
        message: 'Не удалось загрузить файл ответа',
        placement: 'bottomRight'
      });
    }
  }

  const menu = (
    <Menu>
      {
        availableProducts.map((value, index) => <Menu.Item
            key={`product_${index}`}
            onClick={() => handleMenuClick(value.id)}
          >
            {value.name}
          </Menu.Item>)
      }     
    </Menu>
  );

  async function handleOnChange(pagination: TablePaginationConfig) {
    setPagingInfo(pagination);
    getPackages(pagination.current ?? defaultPageNumber, pagination.pageSize ?? defaultPageSize);
  }

  const renderTable = () => {
    if (!currentUser) {
      return null;
    }
    return (
      <Table
        className="u-mt-4"
        columns={packagesListColumns(
          handleDownloadRequest,
          handleDownloadResponse,
          currentUser
        )}
        loading={isPackagesLoading}
        dataSource={packages}
        scroll={{ x: 900 }}
        onChange={handleOnChange}
        pagination={{
          position: ['bottomCenter'],
          total: totalCount,
          pageSize: defaultPageSize,
          showSizeChanger: false
        }}
      />
    );
  };

  return <Wrapper state={wrapperState} errors={initErrors}>
    <PageHeader
      title="Пакетные запросы"
      extra={
        currentUser?.canEditProductRequestPackages() && (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type="primary"
              size="large"
              icon={<PlusOutlined />}
              loading={isProductsLoading}
            >
              Создать пакет <DownOutlined />
            </Button>
          </Dropdown>  
        )
      }
    />

    { renderTable() }
  </Wrapper>
}