import { Modal, Badge, PageHeader, Row, Col, Tooltip, Button, Empty, Descriptions, Typography } from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import { BadgeProps } from "antd/lib/badge";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Auth";
import { Wrapper, WrapperState } from "../../../components/Wrapper";
import { useQuery } from "react-query";
import { fromReportGatewayError, Error } from "../../../Types/Error";
import { Link } from "react-router-dom";
import useEndpointsApi from "../../../services/reportGateway/api/endpointApi";
import IEndpointInfo from "../../../Types/backend/endpoints/EndpointInfo";

const { Text, Title, Link: TypographyLink } = Typography;

interface IUserPermissions {
  canView: boolean;
}

type Status = 'active' | 'inactive';

const map: Record<Status, BadgeProps> = {
  active: { text: 'Активен', status: 'processing' },
  inactive: { text: 'Не активен', status: 'error' }
}

interface IEndpointInfoProps {
  id: string;
  hideId?: boolean;
  hideDescription?: boolean;
  hideStatus?: boolean;
  hidePurpose?: boolean;
}

export function EndpointInfo(props: IEndpointInfoProps) {
  const { id, hideId, hideDescription, hideStatus, hidePurpose } = props;

  const { currentUser } = useAuth();
  const endpointsApi = useEndpointsApi();

  const [userPermissions, setUserPermissions] = useState<IUserPermissions | null>(null);
  const [initErrors, setInitErrors] = useState<Error[]>([]);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);

  useEffect(() => {
    setUserPermissions(currentUser === null
      ? null :
      { canView: currentUser.canViewPermissions() }
    )
  }, [currentUser])

  const { refetch, data: endpointInfo, error, isLoading, isFetching } = useQuery(
    ['endpoints', 'info', id, userPermissions],
    fetchData,
    { enabled: id !== null && userPermissions?.canView === true }
  )

  async function fetchData(): Promise<IEndpointInfo | undefined> {
    if (id === null || userPermissions?.canView !== true) return undefined;

    return await endpointsApi.info(id);
  }

  useEffect(() => {
    console.log(error);
    setInitErrors(error ? fromReportGatewayError(error as any) : [])
  }, [error])

  useEffect(() => {
    if (userPermissions === null) {
      setWrapperState('loading');
    }
    else if (!userPermissions.canView) {
      setWrapperState('accessDenied');
    } else if (isLoading) {
      setWrapperState('loading');
    } else if (initErrors && initErrors.length > 0) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [userPermissions, isLoading, initErrors]);

  function renderStatus() {
    if (endpointInfo === undefined) return '-';

    const status: Status = endpointInfo.disabled ? 'inactive' : 'active';

    return (
      <Badge {...map[status]} />
    )
  }

  return (
    <Wrapper state={wrapperState} errors={initErrors}>
      {endpointInfo === undefined ? (
        <Empty />
      ) : (
        <Descriptions bordered column={1} size='small'>
          {!hideId && (
            <Descriptions.Item label='Id'>
              <Text copyable>{id}</Text>
            </Descriptions.Item>
          )}
          {!hideDescription && (
            <Descriptions.Item label='Описание'>
              {endpointInfo?.description}
            </Descriptions.Item>
          )}
          {!hideStatus && (
            <Descriptions.Item label='Статус'>
              {renderStatus()}
            </Descriptions.Item>
          )}
          {!hidePurpose && (
            <Descriptions.Item label='Назначение'>
              {endpointInfo.purpose}
            </Descriptions.Item>
          )}
          <Descriptions.Item label='Url'>
            <Text copyable>{endpointInfo?.url}</Text>
          </Descriptions.Item>
          {endpointInfo.hostHeader && (
            <Descriptions.Item label='Host'>
              <Text copyable>{endpointInfo.hostHeader}</Text>
            </Descriptions.Item>
          )}
          {endpointInfo.certificateId && (
            <Descriptions.Item label='Сертификат'>
              <Link to={`/certificates/${endpointInfo.certificateId}`}>
                <Text copyable>
                  {endpointInfo?.certificateThumbprint}
                </Text>
              </Link>
            </Descriptions.Item>
          )}
        </Descriptions>
      )}
    </Wrapper>
  )
}