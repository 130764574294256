import { Button, Col, Empty, Radio, RadioChangeEvent, Row, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useCertificatesApi from "../../../services/reportGateway/api/certificatesApi";
import ICertificateReferencesItem from "../../../Types/backend/certificates/CertificateReferencesItem";
import { CertificateInfo } from "../Info/CertificateInfo";
import { CertificateAddition } from "../Addition/CertificateAddition";

interface ICertificateSelectListProps {
  value: string;
  providerId?: string;
  onChange?(value: string): void;
}

export function CertificateSelectList(props: ICertificateSelectListProps) {
  const { providerId, onChange } = props;

  const certificatesApi = useCertificatesApi();

  const [value, setValue] = useState<string>(props.value);
  const [additionOpen, setAdditionOpen] = useState(false);

  useEffect(() => {
    console.log(`select list - ${value}`);
    if (onChange) onChange(value);
  }, [value])

  const { refetch, data: certificates, error, isLoading, isFetching } = useQuery(
    ['certificates', 'select', providerId],
    fetchData,
    { retry: 3 }
  )

  function fetchData(): Promise<ICertificateReferencesItem[]> {
    return certificatesApi.references();
  }

  function handleOnChange(e: RadioChangeEvent) {
    setValue(e.target.value);
  }

  function handleOnAdditioOpen() {
    setAdditionOpen(true);
  }

  function handleOnAdditionCancel() {
    setAdditionOpen(false);
  }

  function handleOnAdditionOk() {
    setAdditionOpen(false);
    refetch();
    notification.success({
      message: 'Сертификат добавлен'
    });
  }

  return (
    <>
      <Row gutter={8}>
        <Col flex={1} />
        <Col>
          <Button
            onClick={handleOnAdditioOpen}
          >
            Добавить
          </Button>
        </Col>
      </Row>
      <Radio.Group
        style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem', maxHeight: '200px', overflow: 'auto' }}
        value={value}
        onChange={handleOnChange}
      >
        <Space direction='vertical'>
          {certificates?.map(certificate => (
            <Radio key={certificate.id} value={certificate.id} checked={certificate.id === value}>
              {`${certificate.name} (${certificate.thumbprint})`}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      {value ? (
        <CertificateInfo id={value} hideId />
      ) : (
        <Empty />
      )}
      <CertificateAddition
        providerId={providerId}
        open={additionOpen}
        onSuccess={handleOnAdditionOk}
        onCancel={handleOnAdditionCancel}
      />
    </>
  )
}