import { makeReportGatewayRequest } from "./makeReportGatewayRequest";

interface IUserPermissionsResponse {
    canViewUsers: boolean;
    canEditUsers: boolean;
    canViewRequests: boolean;
    canViewProductRequestPackages: boolean;
    canEditProductRequestPackages: boolean;
    canViewPermissions: boolean;
    canEditPermissions: boolean;
}

interface ICurrentUserResponse {
    userName: string;
    permissions: IUserPermissionsResponse;
}

export interface IUserPermissions {
    canViewUsers: boolean;
    canEditUsers: boolean;
    canViewRequests: boolean;
    canViewProductRequestPackages: boolean;
    canEditProductRequestPackages: boolean;
    canViewPermissions: boolean;
    canEditPermissions: boolean;
}

export interface ICurrentUser {
    userName: string;
    permissions: IUserPermissions;
}

const reportGatewayAccount = {
    login(userName: string, password: string): Promise<ICurrentUser> {
        return makeReportGatewayRequest<ICurrentUserResponse, ICurrentUser>('/api/account/login',
            "post",
            response => ({ ...response }),
            { userName: userName, password: password },
            () => {}
        );
    },

    logout(): Promise<void> {
        return makeReportGatewayRequest<void, void>('/api/account/logout',
            "post",
            () => {},
            undefined,
            () => {}
        );
    },

    getCurrentUser(): Promise<ICurrentUser | null> {
        return makeReportGatewayRequest<ICurrentUserResponse | null, ICurrentUser | null>('/api/account/getCurrentUser',
            "get",
            response => {
                if (response) {
                    return { ...response };
                }
                else {
                    return null
                }
            },
            undefined,
            () => {}
        );
    },
}

export default reportGatewayAccount;