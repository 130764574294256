import { useMakeReportGatewayRequest } from '../makeReportGatewayRequest';

interface IReportsApi {
  getReport(reportId: string): Promise<Blob>;
}

export const useReportsApi = (): IReportsApi => {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  const getReport = (reportRequestId: string): Promise<Blob> => {
    return makeReportGatewayRequest<Blob, Blob>(
      `/api/reports/${reportRequestId}`,
      'get',
      response => { return response; },
      undefined
    );
  }

  return {
    getReport
  }
}