import { ReportGatewayError } from './ReportGatewayError';

export type Error = {
  fieldName: string | null;
  message: string;
};

export type Errors = {
  [key: string]: string[]
};

export const noField = 'no-field';

export const fromReportGatewayError = (error: ReportGatewayError): Error[] => {
  if (error.values.length > 0) {
    return error.values.map<Error>((value) => ({
      fieldName: value.fieldName,
      message: value.message,
    }));
  } else if (error.statusCode) {
    return [
      {
        fieldName: null,
        message: `Http error with code ${error.statusCode}`,
      },
    ];
  } else {
    return [
      {
        fieldName: null,
        message: 'Unknown error',
      },
    ];
  }
};
export function convertFormError(errors: Error[]): Errors {
  return errors.reduce(
    (acc: Errors, cur) => {
      const fieldName = cur.fieldName || noField;

      if (!acc[fieldName]) {
        acc[fieldName] = [];
      }

      acc[fieldName].push(cur.message);

      return {
        ...acc,
        [fieldName]: acc[fieldName]
      }
    },
    {}
  );
}
