import { UserAddOutlined } from '@ant-design/icons';
import { Button, PageHeader, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableCurrentDataSource, Key } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../Auth';
import {
  useUsersApi,
  IUsersListItem,
} from '../../services/reportGateway/api/usersApi';
import {
  useRolesApi,
  IRolesListItem
} from '../../services/reportGateway/api/rolesApi';
import { Error, fromReportGatewayError } from '../../Types/Error';
import { Wrapper, WrapperState } from '../Wrapper';
import { SortDirection } from '../../Types/SortDirection';

import { userListColumns } from './userListColumns';

interface IUsersTableItem extends IUsersListItem {
  key: string;
}

interface IUserListSort {
  userName: SortDirection | null;
  roleName: SortDirection | null;
}

interface IUserListFilter {
  userName: string;
  roleName: string[];
}

export const UserList = () => {
  const unmounted = useRef(false);
  const usersApi = useUsersApi();
  const rolesApi = useRolesApi();
  const [isLoading, setIsLoading] = useState(false);
  const [initErrors, setInitErrors] = useState<Error[]>([]);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [users, setUsers] = useState<IUsersTableItem[]>([]);
  const { currentUser } = useAuth();
  const history = useHistory();
  const [rolesForFilter, setRolesForFilter] = useState<IRolesListItem[]>([]);
  const [filter, setFilter] = useState<IUserListFilter>({userName: '', roleName: []});
  const [sort, setSort] = useState<IUserListSort>({userName: null, roleName: null});

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setInitErrors([]);
        setIsLoading(true);
        const receivedUsers = await usersApi.getUsers();
        const receivedRoles = await rolesApi.getRoles();

        if (!unmounted.current) {
          const convertedUsers: IUsersTableItem[] = receivedUsers.map((r) => ({
            ...r,
            key: r.id,
          }));
          setUsers(convertedUsers);
          setRolesForFilter(receivedRoles);
        }
      } catch (error: any) {
        if (!unmounted.current) {
          setInitErrors(fromReportGatewayError(error));
        }
      } finally {
        if (!unmounted.current) {
          setIsLoading(false);
        }
      }
    }

    if (currentUser?.canViewUsers()) {
      fetchData();
    }
  }, [currentUser, filter, sort]);

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    }
    else if (!currentUser.canViewUsers()) {
      setWrapperState('accessDenied');
    // } else if (isLoading) {
    //   setWrapperState('loading');
    } else if (initErrors && initErrors.length > 0) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, isLoading, initErrors]);

  function redirectOnCreateUser() {
    history.push('/users/creation');
  }

  function redirectOnEditUser(id: string) {
    history.push(`/users/${id}`);
  }

  function redirectOnLockUser(id: string) {
    history.push(`/users/${id}/locking`);
  }

  function handleFilters(filters: Record<string, (Key | boolean)[] | null>) {
    const userNameFilterValue = filters['userName'];
    const roleFilterValue = filters['roleName'];
    setFilter({
      userName: userNameFilterValue ? userNameFilterValue.toString() : '',
      roleName: roleFilterValue ? roleFilterValue.map(value => value.toString()) : []
    });
  }

  function handleSorter(sorter: SorterResult<any> | SorterResult<any>[]) {
    let sorterArray = sorter instanceof(Array) ? sorter : [sorter];

    const sortValue = sorterArray.reduce<any>((acc, cur) => {
      const value: SortDirection | null = cur.order ? (cur.order === "ascend" ? "Asc" : "Desc") : null;

      if (cur.field) {
        acc[cur.field.toString()] = value;
      }

      return acc;
    }, { userName: null, roleName: null });

    setSort(sortValue as IUserListSort);
  }

  function handleOnChange(pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>) {
      handleFilters(filters);
      handleSorter(sorter);
  }

  const renderTable = () => {
    if (!currentUser) {
      return null;
    }
    return (
      <Table
        className="u-mt-4"
        columns={userListColumns(
          currentUser,
          redirectOnEditUser,
          redirectOnLockUser,
          {roles: rolesForFilter}
        )}
        loading={isLoading}
        dataSource={users}
        scroll={{ x: 900 }}
        onChange={handleOnChange}
      />
    );
  };

  return (
    <Wrapper state={wrapperState} errors={initErrors}>
      <PageHeader
        title="Пользователи"
        extra={
          currentUser?.canEditUsers() && (
            <Button
              type="primary"
              size="large"
              onClick={redirectOnCreateUser}
              icon={<UserAddOutlined />}
            >
              Создать пользователя
            </Button>
          )
        }
      />

      {renderTable()}
    </Wrapper>
  );
};