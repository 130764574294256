import { Button, Col, PageHeader, Row, notification } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { PermissionsTable } from "./PermissionsTable";
import { PermissionsExport } from "./PermissionsExport";
import { PermissionAddition } from "../../../components/Permissions";

export function PermissionsList() {

  const [additionOpen, setAdditionOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);

  function handleOnAdditionOpen() {
    setAdditionOpen(true);
  }

  function handleOnAdditionCancel() {
    setAdditionOpen(false);
  }

  function handleOnAdditionOk() {
    setAdditionOpen(false);
    notification.success({
      message: 'Разрешение добавлено'
    })
  }

  function handleOnExportOpen() {
    setExportOpen(true);
  }

  function handleOnExportCancel() {
    setExportOpen(false);
  }

  function handleOnExportOk() {
    setExportOpen(false);
    notification.success({
      message: 'Экспорт выполнен'
    })
  }

  return (
    <>
      <PageHeader
        title='Разрешения'
        extra={(
          <Row gutter={8} style={{ marginBottom: '1rem' }}>
            <Col>
              <Button onClick={handleOnExportOpen}>
                Экспорт
              </Button>
            </Col>
            <Col>
              <Button type='primary' icon={<PlusOutlined />} onClick={handleOnAdditionOpen}>
                Добавить
              </Button>
            </Col>
          </Row>
        )}
      />
      <PermissionsTable />
      <PermissionAddition
        open={additionOpen}
        onOk={handleOnAdditionOk}
        onCancel={handleOnAdditionCancel}
      />
      <PermissionsExport
        open={exportOpen}
        onOk={handleOnExportOk}
        onCancel={handleOnExportCancel}
      />
    </>
  )
}