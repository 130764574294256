import { TreeSelect, TreeSelectProps } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useProductsApi } from "../../../services/reportGateway/api/productsApi";

const LabelProp = 'label';

interface IProductNode {
  value: string;
  title: string;
  children?: IProductNode[];
  [LabelProp]: string;
}

export function ProductsHierarchySelect(props: TreeSelectProps) {
  const productsApi = useProductsApi();

  const { data: objects, isLoading, isFetching } = useQuery(
    ['products', 'hierarchy', 'select'],
    fetchObjects,
    { retry: 3 }
  )

  async function fetchObjects(): Promise<IProductNode[]> {
    const response = await productsApi.hierarchyReferences();

    return response.map<IProductNode>(provider => ({
      value: provider.id,
      title: provider.name,
      label: provider.name,
      children: provider.products.map(product => ({
        value: `${provider.id}.${product.id}`,
        title: product.name,
        label: `${provider.name} / ${product.name}`
      }))
    }))
  }

  return (
    <TreeSelect
      {...props}
      style={{ width: '100%' }}
      allowClear
      treeData={objects}
      loading={isLoading || isFetching}
      treeNodeLabelProp={LabelProp}
    />
  )
}