import { Button, Card, Empty, PageHeader, Skeleton, Space, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import React, { ReactNode, createContext, useCallback, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { CertificateCardMain } from './CertificateCardMain';
import { Wrapper, WrapperState } from '../../../components/Wrapper';
import useCertificatesApi from '../../../services/reportGateway/api/certificatesApi';
import { useQueries, useQuery } from 'react-query';
import { useAuth } from '../../../Auth';
import moment, { Moment } from 'moment';
import { Error, fromReportGatewayError } from '../../../Types/Error';
import { CertificateCardCredentialsTable } from './Credentials/CertificateCardCredentialsTable';
import { CertificateCardEndpointsTable } from './Endpoints/CertificateCardEndpointsTable';

interface ICertificateCardContext {
  id: string;
  updated: Moment;
}

export const CertificateCardContext = createContext<ICertificateCardContext>({} as ICertificateCardContext);

const mainTabKey = 'main';
const credentialsTabKey = 'credentials';
const endpointsTabKey = 'endpoints';

export function CertificateCard() {
  const params = useParams<{ id: string }>();
  const { id } = params;

  const tabs: Record<string, ReactNode> = {
    [mainTabKey]: <CertificateCardMain />,
    [credentialsTabKey]: <CertificateCardCredentialsTable />,
    [endpointsTabKey]: <CertificateCardEndpointsTable />
  }

  const { currentUser } = useAuth();
  const canView = useMemo(() => currentUser?.canViewPermissions() ?? false, [currentUser])

  const [activeTabKey, setActiveTabKey] = useState(mainTabKey);

  const api = useCertificatesApi();

  const [updated, setUpdated] = useState(moment());

  const { data, error, isLoading, isFetching } = useQuery(
    ['certificates', 'card', 'title', id, canView, updated],
    () => api.cardTitle(id),
    { enabled: canView, retry: 3 }
  )

  const wrapperState = useMemo<WrapperState | null>(() => {
    if (isLoading) return 'loading';
    else if (error) return 'hasErrors'
    else return null;
  }, [isLoading, error])

  const initErrors = useMemo(() => error ? fromReportGatewayError(error as any) : [], [error])

  const handleOnRefresh = useCallback(() => {
    setUpdated(moment());
  }, [setUpdated])

  const { push } = useHistory()

  return id === undefined ? <Empty /> : (
    <CertificateCardContext.Provider
      value={{
        id: id,
        updated: updated
      }}
    >
      <Card
        style={{ height: '100%' }}
        title={(
          <PageHeader
            title='Сертификат'
            subTitle={data === undefined ? null : (
              <Space>
                {data.description}
                {data.thumbprint}
              </Space>
            )}
            extra={(
              <Tooltip title='Обновить'>
                <Button
                  shape='circle'
                  loading={isLoading || isFetching}
                  icon={<ReloadOutlined />}
                  onClick={handleOnRefresh}
                />
              </Tooltip>
            )}
            onBack={() => push('/certificates')}
          />
        )}
        activeTabKey={activeTabKey}
        tabList={[
          { key: mainTabKey, tab: 'Основное', disabled: data === undefined },
          { key: credentialsTabKey, tab: 'Учетные данные', disabled: data === undefined },
          { key: endpointsTabKey, tab: 'Эндпоинты', disabled: data === undefined }
        ]}
        onTabChange={setActiveTabKey}
      >
        <Wrapper state={wrapperState} errors={initErrors}>
          {tabs[activeTabKey]}
        </Wrapper>
      </Card>
    </CertificateCardContext.Provider>
  )
}