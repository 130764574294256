import { Divider, List } from 'antd';
import React, { useContext } from 'react';
import { CertificateCardContext } from './CertificateCard';

export function CertificateCardFilesList() {
  const { } = useContext(CertificateCardContext)

  return (
    <>
      <Divider orientation='center'>Файлы</Divider>
      <List />
    </>
  )
}