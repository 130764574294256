import { Form, Input, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import useOrganizationsApi from '../../../services/reportGateway/api/organizationsApi';

const { useForm } = Form;

interface IBackendForm {
  Name: string;
  Ogrn: string;
  Inn: string;
}

const initialValues: IBackendForm = {
  Name: '',
  Inn: '',
  Ogrn: ''
}

interface IOrganizationAdditionProps {
  open: boolean;
  onCancel(): void;
  onOk(): void;
}

export function OrganizationAddition(props: IOrganizationAdditionProps) {
  const { open, onOk, onCancel } = props;

  const api = useOrganizationsApi();

  const [form] = useForm<IBackendForm>();

  const [isProcessing, setIsProcessing] = useState(false);

  function handleOnCancel() {
    onCancel();
    form.resetFields();
  }

  async function handleOnFinish(values: IBackendForm) {
    const { Name, Ogrn, Inn } = values;
    setIsProcessing(true);

    let successful = true;

    try {
      await api.create(Name, Ogrn, Inn);
    } catch (error) {
      console.error(error);
      successful = false;
    } finally {
      setIsProcessing(false);
    }

    if (successful) {
      onOk();
      form.resetFields();
    } else {
      notification.error({
        message: 'Не удалось добавить организацию'
      })
    }
  }

  return (
    <Modal
      width={600}
      title='Добавление организации'
      open={open}
      closable={false}
      maskClosable={false}
      onOk={form.submit}
      okButtonProps={{ loading: isProcessing }}
      okText='Добавить'
      onCancel={handleOnCancel}
      cancelButtonProps={{ disabled: isProcessing }}
      cancelText='Отмена'
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleOnFinish}
        labelCol={{ span: 6 }}
      >
        <Form.Item name='Name' label='Наименование'>
          <Input />
        </Form.Item>
        <Form.Item
          name='Ogrn'
          label='ОГРН'
          rules={[
            { pattern: new RegExp('^[0-9]{13}$'), message: 'Допускаются только цифры, длина строки - 13 символов' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='Inn'
          label='ИНН'
          rules={[
            { pattern: new RegExp('^[0-9]{10}$'), message: 'Допускаются только цифры, длина строки - 10 символов' }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}