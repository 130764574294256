import { DatePicker, Form, Input, Modal, Typography, notification } from "antd";
import { Moment } from "moment";
import React, { useCallback } from "react";
import { useMutation } from "react-query";
import useCredentialsApi from "../../../services/reportGateway/api/credentialsApi";

const { useForm } = Form;

const { Title } = Typography;

interface IForm {
  From: Moment | undefined;
  Reason: string;
}

const initialForm: IForm = {
  From: undefined,
  Reason: ''
}

interface ICredentials {
  id: string;
  description: string;
}

interface ICredentialsLimitationProps {
  value: ICredentials | null;
  onCancel(): void;
  onSuccessful(): void;
}

export function CredentialsLimitation(props: ICredentialsLimitationProps) {
  const { value, onCancel, onSuccessful } = props;

  const [form] = useForm<IForm>();

  const mutation = useMutation(mutationFn, {
    onSuccess(data, variables, context) {
      notification.success({
        message: 'Ограничение успешно установлено'
      })

      onSuccessful();
      form.resetFields();
    },
    onError(error, variables, context) {
      notification.error({
        message: 'Не удалось установить ограничение'
      })
    },
  })

  const api = useCredentialsApi();

  function mutationFn(values: IForm) {
    if (value === null) return Promise.reject('Invalid id');
    if (values.From === undefined) return Promise.reject('Invalid date');

    return api.limit(value.id, values.From, values.Reason);
  }

  const handleOnCancel = useCallback(() => {
    onCancel();
    form.resetFields();
  }, [form, onCancel])

  return (
    <Modal
      title='Ограничение учетных данных'
      closable={false}
      maskClosable={false}
      width={600}
      open={value !== null}
      okText='Применить'
      okButtonProps={{ loading: mutation.isLoading }}
      onOk={form.submit}
      cancelText='Отмена'
      cancelButtonProps={{ disabled: mutation.isLoading }}
      onCancel={handleOnCancel}
    >
      <Title style={{ marginTop: 0 }} level={5}>
        {value?.description ?? '-'}
      </Title>
      <Form
        style={{ paddingTop: '1rem' }}
        form={form}
        initialValues={initialForm}
        onFinish={mutation.mutate}
        labelCol={{ span: 5 }}
      >
        <Form.Item
          label='Дата'
          name='From'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          label='Причина'
          name='Reason'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}